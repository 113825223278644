import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RecordsFound from "../Images/recordsFound.png";
import MissingData from "../Images/missingData.png";
import DuplicateIcon from "../Images/DuplicateIcon.png";
import UpdateIcon from "../Images/update-icon.png";
import "./bulkUploadResult.css";

const BulkLeadsResult = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // ✅ Extract the file name and API response data
    const fileName = location.state?.fileName || "No File Uploaded";
    const apiData = location.state?.data?.bulkUploadUsers?.data || {
        number_of_records: "",
        number_of_missing_data: "",
        number_of_duplicate_data: "",
        excel: null,
    };
    console.log(fileName);
    console.log(apiData);

    // const [bulkUploadData, setBulkUploadData] = useState({
    //     number_of_records: "",
    //     number_of_missing_data: "",
    //     number_of_duplicate_data: "",
    //     excel: null,
    // });
    // console.log(bulkUploadData);

    // useEffect(() => {
    //     if (apiData) {
    //         setBulkUploadData({
    //             number_of_records: apiData.number_of_records || "",
    //             number_of_missing_data: apiData.number_of_missing_data || "",
    //             number_of_duplicate_data: apiData.number_of_duplicate_data || "",
    //             excel: apiData.excel || null,
    //         });
    //     }
    // }, [apiData]);

    const handleSubmit = () => {
        navigate("/admin");
    };

    return (
        <div className="bulk-leads-container">
            <h2 className="bulk-leads-header">Bulk Leads Result</h2>

            <p style={{ textAlign: "center", fontSize: "14px", marginBottom: "20px" }}>
                <strong>Uploaded File:</strong> {fileName}
            </p>

            <div className="bulk-leads-cards">
                <div className="bulk-leads-card">
                    <img src={RecordsFound} alt="Records Found" />
                    <p className="bulk-leads-card-text">Records Found</p>
                    <strong className="bulk-leads-card-value" style={{ color: "blue" }}>
                        {apiData.number_of_records}
                    </strong>
                </div>

                <div className="bulk-leads-card">
                    <img src={MissingData} alt="Missing Data" />
                    <p className="bulk-leads-card-text">Missing Data</p>
                    <strong className="bulk-leads-card-value" style={{ color: "red" }}>
                        {apiData.number_of_missing_data}
                    </strong>
                </div>

                <div className="bulk-leads-card">
                    <img src={DuplicateIcon} alt="Duplicate Data" />
                    <p className="bulk-leads-card-text">Duplicate Data</p>
                    <strong className="bulk-leads-card-value" style={{ color: "orange" }}>
                        {apiData.number_of_duplicate_data}
                    </strong>
                </div>
            </div>

            {apiData.excel && (
                <div className="bulk-leads-info">
                    <div className="bulk-leads-info-left">
                        <img src={DuplicateIcon} alt="Duplicate Data" />
                        <p>Download Data</p>
                    </div>
                    <a href={apiData.excel} className="bulkUploadDownload" download>
                        Download
                    </a>
                </div>
            )}


            <div className="bulk-leads-submit">
                <button className="bulkUploadSubmit" onClick={handleSubmit}>Back</button>
            </div>
        </div>
    );
};

export default BulkLeadsResult;
