import React, { useEffect, useState } from "react";
import { Button, Layout, Table, Spin, Alert, Modal, Form, Input, Select, DatePicker, Row, Col, message } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { Add_JobRole_Query, GET_User_For_Account, Role_Management } from "../services/userAPI"; // Ensure correct path
import "./viewRole.css";
import Clan from "../Images/clan-logo.png";
import Darts from "../Images/Darts-Logo 1.png";
import Lense from "../Images/Lense-Logo 1.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Option } from "lucide-react";
import LeftArrow from "../Images/view-role-arrow-left.png";
import HomeIcon from "../Images/view-role-home-icon.png";

const ViewRole = () => {
    const [activeTab, setActiveTab] = useState("product-configuration");
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showAccessModal, setShowAccessModal] = useState(false);

    const navigate = useNavigate();
    const { Content } = Layout;
    const location = useLocation();
    const roleId = location.state?.roleId;
    const name = location.state?.name;
    const dartsNeeded = location.state?.dartsNeeded;
    const accountId = location.state?.accountId;
    const clanNeeded = location.state?.clanNeeded;
    const lenseNeeded = location.state?.lenseNeeded;
    const dartsFormNeeded = location.state?.dartsFormNeeded;
    const pocName = location.state?.pocName;
    const pocEmail = location.state?.pocEmail;
    const pocNumber = location.state?.pocNumber;
    const numberLogin = location.state?.numberLogin;
    const emailLogin = location.state?.emailLogin;
    const mandatoryTimeDistribution = location.state?.mandatoryTimeDistribution;
    const targetEditDays = location.state?.targetEditDays;
    const inputEditDays = location.state?.inputEditDays;
    const [search, setSearch] = useState("");
    const [cursor, setCursor] = useState(null); // Pagination cursor
    const limit = 5;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [isUpdating, setIsUpdating] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const [productAccess, setProductAccess] = useState({
        DARTS: dartsNeeded,
        CLAN: clanNeeded,
        LENSE: lenseNeeded,
    });

    const [addJobrole, { loading: addRoleLoading }] = useMutation(Role_Management)

    console.log(roleId);
    console.log(accountId);

    const handleUpdateAccess = () => {
        // Create a new state object with the updated product access
        const updatedProductAccess = {
            ...productAccess,
            [selectedProduct]: !productAccess[selectedProduct]
        };

        // Update local state first
        setProductAccess(updatedProductAccess);

        // Prepare variables for API call with all required fields
        const variables = {
            accountId: accountId,
            jobroleId: roleId,
            name: location.state?.name,
            clanNeeded: selectedProduct === "CLAN" ? !productAccess.CLAN : productAccess.CLAN,
            dartsNeeded: selectedProduct === "DARTS" ? !productAccess.DARTS : productAccess.DARTS,
            lenseNeeded: selectedProduct === "LENSE" ? !productAccess.LENSE : productAccess.LENSE,
            dartsFormNeeded: location.state?.dartsFormNeeded,
            pocName: location.state?.pocName,
            pocEmail: location.state?.pocEmail,
            pocNumber: location.state?.pocNumber,
            numberLogin: location.state?.numberLogin,
            emailLogin: location.state?.emailLogin,
            mandatoryTimeDistribution: location.state?.mandatoryTimeDistribution,
            targetEditDays: location.state?.targetEditDays,
            inputEditDays: location.state?.inputEditDays,
        };

        // Add loading state

        // Call mutation to update role with error handling
        setIsUpdating(true);
        addJobrole({ variables })
            .then((response) => {
                setShowAccessModal(false);
                // Show success message
                message.success(`${selectedProduct} access updated successfully`);
            })
            .catch((error) => {
                console.error("Error updating account:", error);
                // Show error message
                message.error(`Failed to update ${selectedProduct} access: ${error.message}`);
            })
            .finally(() => {
                setIsUpdating(false);
            });
    };


    // Close Modal
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                setIsModalOpen(false);
                form.resetFields();
            })
            .catch((errorInfo) => {
                console.log("Validation Failed:", errorInfo);
            });
    };

    const products = [
        { name: "LENSE", tagline: "DISCOVER THE UNSEEN", logo: Lense, needed: lenseNeeded },
        { name: "DARTS", tagline: "MAKE EVERYDAY COUNT", logo: Darts, needed: dartsNeeded },
        { name: "CLAN", tagline: "WIN AT WORK", logo: Clan, needed: clanNeeded, route: "/user-management" },
    ];

    const handleProductClick = (product) => {
        if (productAccess[product.name]) {
            // If the product is active, navigate
            navigate('/user-management', { state: { roleId: roleId, accountId: accountId, dartsFormNeeded: dartsFormNeeded, pocName: pocName, pocEmail: pocEmail, pocNumber: pocNumber, numberLogin: numberLogin, emailLogin: emailLogin, mandatoryTimeDistribution: mandatoryTimeDistribution, targetEditDays: targetEditDays, inputEditDays: inputEditDays, name: name, clanNeeded: clanNeeded, dartsNeeded: dartsNeeded, } });
        } else {
            // If the product is inactive, show the modal
            setSelectedProduct(product.name);
            setShowAccessModal(true);
        }
    };


    // Fetch data from GraphQL
    const { loading, error, data, refetch } = useQuery(GET_User_For_Account, {
        variables: {
            roleId: roleId,
            cursor: cursor,
            filterByName: search, // Default empty search
            pagingCriteria: { limit, cursor: null, sortByField: "created_at", sortOrder: "desc" },
        },
        fetchPolicy: "network-only",
    });

    const handleDiscardClick = () => {
        navigate("/admin");
    };

    return (
        <Content>
            <div className="product-selection-container">
                <div className="product-selection-breadcrumb">
                    <span style={{ fontFamily: "Inter" }}>Client</span> <span>&gt; </span>
                    <span className="bold-text">Select Product</span>
                </div>
                <div style={{ display: "flex", justifyContent: "end", alignItems: "end", margin: "14px" }}>
                    <button className="back-button" onClick={handleDiscardClick}>
                        <img src={LeftArrow} alt="Less Than" style={{ marginRight: "5px", height: "14px", width: "14px" }} />
                        Back
                        <img src={HomeIcon} alt="Less Than" style={{ marginLeft: "5px", height: "13px", width: "13px" }} />

                    </button>
                </div>

                {activeTab === "product-configuration" ?
                    <>
                        <h2 className="title">Select The Product To Configure</h2>
                        <div className="product-grid">
                            {products.map((product, index) => (
                                <div
                                    key={index}
                                    className={`product-card ${productAccess[product.name] ? "active" : "disabled"} ${selectedProduct === product.name ? "selected" : ""
                                        }`}
                                    onClick={() => handleProductClick(product)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <img src={product.logo} alt={product.name} className="product-logo" />
                                </div>

                            ))}
                        </div>
                        <br />

                    </>
                    : (
                        <div className="tab-content user-management">
                        </div>
                    )}

                <Modal
                    title="User Registration Form"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={700} // Adjust width for better layout
                    footer={[
                        <Button key="cancel" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={handleOk}>
                            Save
                        </Button>,
                    ]}
                >
                    {/* User Registration Form */}
                    <Form form={form} layout="vertical">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="employmentType"
                                    label="Full-Time/Consultant"
                                    rules={[{ required: true, message: "Please enter employment type" }]}
                                >
                                    <Input placeholder="Enter Full-Time/Consultant" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="dateOfJoining"
                                    label="Date of Joining"
                                    rules={[{ required: true, message: "Please select date of joining" }]}
                                >
                                    <DatePicker style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="designation"
                                    label="Current Designation"
                                    rules={[{ required: true, message: "Please select designation" }]}
                                >
                                    <Select placeholder="Select Designation">
                                        <Option value="Manager">Manager</Option>
                                        <Option value="Engineer">Engineer</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="branch"
                                    label="Current Branch"
                                    rules={[{ required: true, message: "Please enter current branch" }]}
                                >
                                    <Input placeholder="Enter Branch" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="state"
                                    label="State"
                                    rules={[{ required: true, message: "Please select state" }]}
                                >
                                    <Select placeholder="Select State">
                                        <Option value="California">California</Option>
                                        <Option value="New York">New York</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="city"
                                    label="City"
                                    rules={[{ required: true, message: "Please select city" }]}
                                >
                                    <Select placeholder="Select City">
                                        <Option value="Los Angeles">Los Angeles</Option>
                                        <Option value="New York City">New York City</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="zone"
                                    label="Zone"
                                    rules={[{ required: true, message: "Please select zone" }]}
                                >
                                    <Select placeholder="Select Zone">
                                        <Option value="North">North</Option>
                                        <Option value="South">South</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="managerID" label="Employee ID of Manager">
                                    <Input placeholder="Enter Employee ID of Manager" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="dateOfReporting" label="Date of Reporting (Current Manager)">
                                    <DatePicker style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="managerName" label="Name of Manager">
                                    <Input placeholder="Enter Manager Name" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    title="Access Required"
                    open={showAccessModal}
                    onCancel={() => setShowAccessModal(false)}
                    footer={[
                        <button key="no" onClick={() => setShowAccessModal(false)} className="cancel-button" style={{ marginRight: "10px" }}>
                            No
                        </button>,
                        <button
                            className="create-button"
                            key="yes"
                            type="primary"
                            onClick={handleUpdateAccess} disabled={addRoleLoading}
                        >
                            {addRoleLoading ? " Updating..." : "Yes"}
                        </button>,
                    ]}
                >
                    <p>Do you need to give access to {selectedProduct}?</p>
                </Modal>

            </div >
        </Content >
    );
};

export default ViewRole;
