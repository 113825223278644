import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Table, Button, Input, Popconfirm, Card, Modal, message } from "antd";
import { EditOutlined, DeleteOutlined, CheckOutlined, SearchOutlined } from "@ant-design/icons";
import EditIcon from "../Images/user-management-edit-icon.png";
import DeleteIcon from "../Images/user-management-delete-icon.png";
import { Add_Update_Designation, Current_Designation } from "../services/userAPI"; // Adjust path
import { useLocation, useNavigate } from "react-router-dom";
import GenderConfiguration from "./genderConfigurations";
import EmployementTypeConfiguration from "./employementType";
import "./mastersConfiguration.css"
const MastersConfiguration = () => {
    const [searchTerm, setSearchTerm] = useState(""); // For search
    const [currentPage, setCurrentPage] = useState(1); // Pagination state
    const [pageSize, setPageSize] = useState(5); // Set page size to 5
    const [editingKey, setEditingKey] = useState(null); // For handling row edit
    const [editedName, setEditedName] = useState(""); // For storing edited name
    const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility
    const [newDesignation, setNewDesignation] = useState("");
    const [deleteId, setDeleteId] = useState(null);
    const location = useLocation();
    const inputRef = useRef(null);

    const roleId = location.state?.roleId;
    const accountId = location.state?.accountId;
    // Fetch data using GraphQL query
    const { loading, error, data, refetch } = useQuery(Current_Designation, {
        variables: {
            jobroleId: roleId, // Pass roleId dynamically
            accountId: accountId, // Pass accountId dynamically
        },
    });

    // Local state to store the fetched data
    const [fetchedData, setFetchedData] = useState([]);

    // UseEffect to store the fetched data in local state
    useEffect(() => {
        if (data) {
            setFetchedData(data?.getDesignationsJobroleid || []);
        }
    }, [data]);

    useEffect(() => {
        if (isModalVisible && inputRef.current) {
            // Add a small delay to ensure the modal is fully rendered
            setTimeout(() => {
                inputRef.current.focus();
            }, 100);
        }
    }, [isModalVisible]);

    // useEffect(() => {
    //     if (data) {
    //         const reversedData = [...data?.getDesignationsJobroleid].reverse(); // Reverse the fetched data
    //         setFetchedData(reversedData);
    //     }
    // }, [data]);

    // Filter data based on search term
    const filteredData = fetchedData.filter(item =>
        item.designation.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Paginate the filtered data
    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    // Pagination Text
    const paginationText = filteredData.length > 0
        ? `${(currentPage - 1) * pageSize + 1} - ${Math.min(currentPage * pageSize, filteredData.length)} of ${filteredData.length}`
        : "No Data";

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page when search term changes
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prev) => prev - 1);
    };

    const handleEdit = (record) => {
        setEditingKey(record.key);
        setEditedName(record.designation);
    };

    const handleSave = (key) => {
        const updatedData = paginatedData.map((item) =>
            item.key === key ? { ...item, designation: editedName } : item
        );
        console.log("Updated designation:", editedName);
        setEditingKey(null); // Reset the editing state
    };


    const showModal = () => {
        setIsModalVisible(true); // Show the modal
    };


    const handleCancel = () => {
        setIsModalVisible(false); // Close the modal
        setNewDesignation(""); // Reset the new designation input
    };

    const [addUpdateDesignation, { loading: addLoading }] = useMutation(Add_Update_Designation);

    const handleAddDesignation = async () => {
        if (!newDesignation.trim()) {
            alert("Please enter a designation.");
            return;
        }

        try {
            const response = await addUpdateDesignation({
                variables: {
                    accountId: Number(accountId), // Ensure correct type
                    jobroleId: Number(roleId),
                    designationName: newDesignation,
                    designationId: null,
                },
                refetchQueries: [{ query: Current_Designation }]
            });

            if (response.data.addUpdateDesignations.success) {
                message.success("Designation added successfully!");
                setIsModalVisible(false);
                setNewDesignation("");
                refetch(); // Refresh the data

            } else {
                message.error("Error: " + response.data.addUpdateDesignations.message);
            }
        } catch (error) {
            console.error("Error adding designation:", error);
            alert("Failed to add designation.");
        }
    };



    const handleDelete = async (designationId, designationName) => {
        try {
            const response = await addUpdateDesignation({
                variables: {
                    accountId: Number(accountId), // Ensure correct type
                    jobroleId: Number(roleId),
                    designationName: designationName, // You don't need the designation name for deletion
                    designationId: designationId, // Pass the correct id
                    status: 0, // Status 0 means deleting (inactive)
                },
            });

            if (response.data.addUpdateDesignations.success) {
                message.success("Designation deleted successfully!");
                refetch(); // Refresh the data
            } else {
                message.error("Error: " + response.data.addUpdateDesignations.message);
            }
        } catch (error) {
            message.error("Failed to delete designation");
        } finally {
            setDeleteId(null); // Reset the delete id after operation
        }
    };


    const columns = [
        {
            title: "Designation",
            dataIndex: "designation",
            key: "designation",
            render: (text, record) => (
                editingKey === record.key ? (
                    <Input
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        onPressEnter={() => handleSave(record.key)}
                    />
                ) : (
                    text
                )
            ),
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    {editingKey === record.key ? (
                        <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={() => handleSave(record.key)}
                            style={{ marginRight: 8 }}
                        />
                    ) : (
                        <>
                            {/* <button className="user-management-edit-button" onClick={() => handleEdit(record)}>
                                <img src={EditIcon} alt="Edit" />
                                Edit
                            </button> */}
                            <Popconfirm
                                title="Are you sure you want to delete this?"
                                onConfirm={() => handleDelete(record.id, record.designation)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <img src={DeleteIcon} alt="Delete" style={{ cursor: "pointer" }} />
                            </Popconfirm>
                        </>
                    )}
                </div>
            ),
        },
    ];

    return (
        <div>
            <Card className="search-card">
                <label style={{ fontFamily: "Inter", fontSize: "20px" }}>Designations</label>

                <div className="search-container">
                    <div className="search-group">
                        <label>Search</label>
                        <div className="search-input-wrapper">
                            <Input
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search designation"
                                suffix={<SearchOutlined />}
                            />
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="button-group" style={{ marginTop: "20px" }}>
                        <button className="add-user-btn" onClick={() => setIsModalVisible(true)}>
                            Add Designation
                        </button>
                    </div>
                </div>
                <br />
                <Table
                    columns={columns}
                    dataSource={paginatedData}
                    pagination={false}
                    loading={loading}
                />

                <div className="pagination-controls">
                    <span className="pagination-text">{paginationText}</span>
                    <button
                        className="pagination-button"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        &lt;
                    </button>
                    <button
                        className="pagination-button"
                        onClick={handleNextPage}
                        disabled={currentPage * pageSize >= filteredData.length}
                    >
                        &gt;
                    </button>
                </div>
            </Card>

            <Modal
                title="Add New Designation"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <div>
                    <Input
                        ref={inputRef}
                        value={newDesignation}
                        onChange={(e) => setNewDesignation(e.target.value)}
                        placeholder="Enter designation"
                        autoFocus={true}
                    />
                    <div style={{ marginTop: "20px", textAlign: "right" }}>
                        <button className="masters-configuration-cancel-button" onClick={handleCancel} style={{ marginRight: 8 }}>
                            Cancel
                        </button>
                        <button className="masters-configuration-add-button" onClick={handleAddDesignation} loading={addLoading}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
            <br />
            <GenderConfiguration />
            <br />
            <EmployementTypeConfiguration />
        </div>
    );
};

export default MastersConfiguration;
