import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Select, DatePicker, Input, Table, Form, Modal, Radio, message, Upload, Steps, Row, Col } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import "./userManagement.css";
import "antd/dist/reset.css";
import SelectuserIcon from "../Images/select-user-icon.png"
import AdduserIcon from "../Images/add-user-icon.png";
import BackArrow from "../Images/back-arrow-icon.png";
import { SearchOutlined, UserAddOutlined } from "@ant-design/icons";
import { Accounts_Query, Add_Multiple_Users, Current_Designation, Get_City, Get_States, GET_User_Details_User_Management, Role_Account_Query, Remove_User, Update_User, Bulk_Upload, Bulk_Upload_Sheet, Verify_Employee_Id, List_Gender_From_Role, List_Employement_Type_From_Role } from "../services/userAPI";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import EditIcon from "../Images/user-management-edit-icon.png";
import DeleteIcon from "../Images/user-management-delete-icon.png";
import BulkUpload from "../Images/bulk-upload-icon.png";
import BulkUploadArrow from "../Images/bulk-upload-arrow-icon.png";
import BulkUploadFiles from "../Images/bulk-upload-files -added-icon.png";
import BulkUploadIcon from "../Images/upload-file-icon.png";
import { Option } from "lucide-react";
import TextInputField from "../reusableField/TextInputField2";
// import Select from "react-select";
import { validationRegexAlphaNumeric, validationRegexAmount, validationRegexMobileNo, validationRegexOnlyAlpha } from "../regex/regex";
import SearchableDropdown from "../reusableField/searchableDropdownField";
import { Label } from "@mui/icons-material";
import { Breadcrumb } from 'antd';
import SelectDropdown from "react-select"
import moment from 'moment';
import dayjs from 'dayjs';
import { Tabs } from "antd";
import MastersConfiguration from "./mastersConfiguration";
import ProductConfiguration from "./productConfiguration";
const { Step } = Steps;
const { TabPane } = Tabs;

const UserManagement = ({ index }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("masters-configuration");
    const location = useLocation();
    const roleId = location.state?.roleId;
    const accountId = location.state?.accountId;
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [paginationToken, setPaginationToken] = useState(null);
    const [page, setPage] = useState(1);
    const [nextPageCursor, setNextPageCursor] = useState(null);
    const [previousPageCursor, setPreviousPageCursor] = useState(null);
    const [touched, setTouched] = useState({})
    const [errors, setErrors] = useState({})
    const { Dragger } = Upload;
    const [removeUser] = useMutation(Remove_User);

    // const [activeTab, setActiveTab] = useState("1");

    const [roleIds, setRoleIds] = useState(() => {
        return localStorage.getItem("roleId") || location.state?.roleId || null;
    });

    const [accountIds, setAccountIds] = useState(() => {
        return localStorage.getItem("accountId") || location.state?.accountId || null;
    });



    const handleDeleteUser = async (UserId) => {
        if (!UserId) {
            message.error("User ID is missing.");
            return;
        }

        Modal.confirm({
            title: "Are you sure you want to delete this user?",
            // content: "This action cannot be undone.",
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk: async () => {
                try {
                    const { data } = await removeUser({ variables: { userId: UserId } });

                    if (data?.removeUser?.success) {
                        message.success("User deleted successfully!");
                        await refetch(); // Refresh the user list
                    } else {
                        message.error(data?.removeUser?.message || "Failed to delete user.");
                    }
                } catch (error) {
                    console.error("Error deleting user:", error);
                    message.error("An error occurred while deleting the user.");
                }
            },

            style: {
                // backgroundColor: '#f9f9f9', // Set the background color of the modal
                // color: '#333', // Set the text color
            },
            // Customizing button colors
            okButtonProps: {
                style: {
                    backgroundColor: '#dc2626', // Red background for the delete button
                    color: 'white', // White text color
                    borderColor: '#dc2626', // Border color same as the background
                },
            },
            cancelButtonProps: {
                style: {
                    backgroundColor: '#d9d9d9', // Light grey background for cancel button
                    color: '#595959', // Darker grey text for cancel button
                    borderColor: '#d9d9d9', // Border color same as background
                },
            },
        });
    };



    // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const [selectionMode, setSelectionMode] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [loadingg, setLoading] = useState(false);

    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [roleOptions, setRoleOptions] = useState([])

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [cityOptions, setCityOptions] = useState([])
    const [genderOptionss, setGenderOptions] = useState(null)

    // const [designationOptions, setDesignationOptions] = useState([]);
    const [selectedDesignation, setSelectedDesignation] = useState(null);

    const [step, setStep] = useState(0);
    const [step1, setStep1] = useState(0);

    const handleNext = (e) => {
        // Prevent default form submission behavior
        e.preventDefault();

        // Create a copy of the touched state
        const newTouched = { ...touched };

        const firstStepFields = [

            // 'selectCompany', 
            'selectRole', 'employeeid', 'employeeMailId',
            'firstName', 'lastName', 'dateOfReporting', 'highestEducationQualification',
            'previousEmployer', 'previousExperience', 'phoneNumber', 'gender', 'dateOfBirth',
        ];

        // Mark all fields as touched to trigger validation
        firstStepFields.forEach(field => {
            newTouched[field] = true;
        });

        setTouched(newTouched);

        // Validate form data
        const stepErrors = validateFirstStep(formData);
        const hasErrors = Object.keys(stepErrors).length > 0;

        // Set errors
        setErrors(prevErrors => ({ ...prevErrors, ...stepErrors }));

        if (!hasErrors) {
            setStep(1);
        }
    };

    const [basic_data, setBasic_data] = useState({})
    const EdithandleNext = async () => {
        try {
            if (step1 === 0) {
                await formEdit.validateFields([
                    // 'accountName', 'selectRole', 
                    'employeeid']); // Validate fields for step 1
            }
            if (step1 === 1) {
                await formEdit.validateFields(['employeeType', 'joiningDate', 'designation']); // Validate fields for step 2
            }
            setStep1(1);
            // Proceed to next step if validation passes
            setBasic_data(formEdit.getFieldsValue())
        } catch (errorInfo) {
            console.log("Validation failed:", errorInfo);
        }
    };


    const validateFirstStep = (data) => {
        const errors = {};

        // Check required fields
        // if (!selectedCompany?.value) errors.selectCompany = "Company is required";
        // if (!selectedRole?.value) errors.selectRole = "Role is required";
        if (!data.employeeid?.trim()) errors.employeeid = "Employee ID is required";
        if (!data.employeeMailId?.trim()) {
            errors.employeeMailId = "Email is required";
        } else if (!emailRegex.test(data.employeeMailId)) {
            errors.employeeMailId = "Invalid email format";
        }
        if (!data.firstName?.trim()) errors.firstName = "First name is required";
        if (!data.lastName?.trim()) errors.lastName = "Last name is required";
        if (!data.phoneNumber?.trim()) errors.phoneNumber = "Phone Number is required";
        if (!data.dateOfBirth) errors.dateOfBirth = "Date of birth is required";
        if (!data.gender) errors.gender = "Gender is required";

        return errors;
    };

    const handleSelectAll = () => {
        if (selectedUsers.length === tableData.length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(tableData.map(user => user.key));
        }
    };

    const toggleSelectionMode = () => {
        setSelectionMode(!selectionMode);
        if (selectionMode) {
            setSelectedUsers([]);
        }
    };

    const [formData, setFormData] = useState({
        fullTimeConsultant: "",
        currentBranch: "",
        employeeIdOfManager: "",
        nameOfManager: "",
        clientImage: "",
        imageUrl: "",
        mainLogoUrl: "",
        id: "",
        userId: null,
        dateOfJoining: null,
        currentDesignation: "",
        state: "",
        city: "",
        zone: "",
        dateOfReporting: null,
        employeeMailId: "",
        firstName: "",
        lastName: "",
        gender: "",
        dateOfBirth: null,
        highestEducationQualification: "",
        previousEmployer: "",
        previousExperience: "",
        phoneNumber: "",
    });


    const [editFormData, setEditFormData] = useState({
        employeeid: "",
        employeeMailId: '',
        firstName: '',
        lastName: '',
        gender: { value: '', label: '' },
        dateOfBirth: null,
        phoneNumber: '',
        previousExperience: '',
        highestEducationQualification: '',
        previousEmployer: '',
        employeeType: { value: '', label: '' },
        designation: { value: '', label: '' },
        joiningDate: null,
        branch: '',
        stateName: '',
        cityName: '',
        dateOfReporting: null,
    })


    const [addUsers, { addUsersLoading, addUsersError, data: addUsersData }] = useMutation(Add_Multiple_Users);
    const [addEditUsers, { addUsersEditLoading, addUsersEditError, data: addUsersEditData }] = useMutation(Update_User);

    const [formDataList, setFormDataList] = useState([
        {
            fullTimeConsultant: null,
            dateOfJoining: null,
            currentDesignation: "",
            currentBranch: "",
            state: "",
            city: "",
            zone: "",
            employeeIdOfManager: "",
            dateOfReporting: null,
            nameOfManager: "",
        }
    ])

    // console.log(formDataList);

    // const addUserFields = () => {
    //     setFormDataList([
    //         ...formDataList,
    //         {
    //             fullTimeConsultant: "",
    //             dateOfJoining: null,
    //             currentDesignation: "",
    //             currentBranch: "",
    //             state: "",
    //             city: "",
    //             zone: "",
    //             employeeIdOfManager: "",
    //             dateOfReporting: null,
    //             nameOfManager: ""
    //         }
    //     ]);
    // };
    const [selectedDate, setSelectedDate] = useState(null);

    const { loading, error, data, refetch } = useQuery(GET_User_Details_User_Management, {
        variables: {
            roleId: roleId || "",
            filterByName: searchTerm,
            joiningDateFilter: selectedDate ? selectedDate.format("YYYY-MM-DD") : null,
            pagingCriteria: {
                cursor: paginationToken,
                limit: pageSize,
            }
        },
        skip: !roleId,
        fetchPolicy: "network-only", // Ensures we always get fresh data
    });

    const [isBulkUploadModalVisible, setIsBulkUploadModalVisible] = useState(false);

    const showBulkUploadModal = () => {
        setIsBulkUploadModalVisible(true);
    };

    const handleNextPage = () => {
        if (data?.getUsersForAccount?.pageMeta?.hasNextPage) {
            setPaginationToken(data.getUsersForAccount.pageMeta.nextPage);
            setCurrentPage((prev) => prev + 1);
        }
    };

    const handlePreviousPage = () => {
        if (data?.getUsersForAccount?.pageMeta?.hasPreviousPage) {
            setPaginationToken(data.getUsersForAccount.pageMeta.previousPage);
            setCurrentPage((prev) => prev - 1);
        }
    };


    // Add this useEffect to refetch data when pagination token changes
    useEffect(() => {
        if (roleId) {
            refetch({
                roleId,
                filterByName: searchTerm,
                joiningDateFilter: selectedDate ? selectedDate.format("YYYY-MM-DD") : null,
                pagingCriteria: {
                    cursor: paginationToken,
                    limit: pageSize,
                },
            });
        }
    }, [paginationToken, pageSize]);

    useEffect(() => {
        if (data?.getUsersForAccount?.pageMeta) {
            // Update to use the correct property names from your API response
            setNextPageCursor(data.getUsersForAccount.pageMeta.nextPage || null);
            setPreviousPageCursor(data.getUsersForAccount.pageMeta.previousPage || null);
        }
    }, [data]);

    // Handle search input change with debouncing
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setCurrentPage(1);
        setPaginationToken(null);

        refetch({
            roleId,
            filterByName: value || null,
            joiningDateFilter: selectedDate ? selectedDate.format("YYYY-MM-DD") : null,
            pagingCriteria: {
                cursor: null,
                limit: pageSize,
            },
        });
    };

    const handleDateChangeSearch = (date) => {
        setSelectedDate(date); // Update selected date
        setCurrentPage(1); // Reset to the first page when the date changes
        setPaginationToken(null); // Reset pagination token
    };


    useEffect(() => {
        if (searchTerm !== "") {
            refetch({
                roleId: roleId,
                filterByName: searchTerm,
                joiningDateFilter: selectedDate ? selectedDate.format("YYYY-MM-DD") : null,
                pagingCriteria: {
                    cursor: null,
                    limit: pageSize
                }
            });
        }
    }, [searchTerm]);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [userDataa, setUserData] = useState(null);
    const [form] = Form.useForm();
    const [formEdit] = Form.useForm()

    console.log("User Data:", data?.getUsersForAccount?.data);

    // Transform API response to match Ant Design Table structure
    const tableData = data?.getUsersForAccount?.data?.map((user, index) => ({
        key: index,
        UserId: user.id,
        id: user.employeeId,
        name: user.name,
        employeeMailId: user.email,
        gender: user.gender,
        genderId: user.genderId,
        joiningDate: user.joiningDate || "N/A",
        designation: user.designation,
        designationId: user.designationId || null,
        surname: user.surname,
        dateOfBirth: user.dateOfBirth,
        city: user.city || "N/A",
        branch: user.branch || "N/A",
        role: user.role,
        phoneNumber: user.phoneNumber,
        address: user.address,
        state: user.state,
        zone: user.zone,
        highestEducationQualification: user.higestEducationQualification,
        previousEmployer: user.previousEmployer,
        previousExperience: user.previousExperience ? Number(user.previousExperience) : 0,
        profileActivated: user.profileActivated,
        roleId: user.roleId,
        roleStartDate: user.roleStartDate,
        accountId: user.accountId,
        zone: user.zone,
        accountName: user.accountName,
        reportingDate: user.reportingDate,
        employeeId: user.employeeId,
        employeeIds: user.reporting?.employeeId,
        employeeName: user.reporting?.name,
        stateName: user.stateName,
        cityName: user.cityName,
        employeeType: user.employeeType,
        employeeTypeId: user.employeeTypeId,

    })) || [];

    console.log("Table Data:", tableData);

    useEffect(() => {
        if (isEditModalVisible && userDataa) {
            formEdit.resetFields();
            // companyName

            const selectedCompany = companyOptions.find(company => company.value === userDataa?.accountId);
            setSelectedCompany(selectedCompany)

            // Role 
            const selectRoledata = { value: userDataa.roleId, label: userDataa.role }
            setSelectedRole(selectRoledata)

            const selectDesignation = { value: userDataa.designationId, label: userDataa.designation }
            setSelectedDesignation(selectDesignation)

            const selectedState = { value: userDataa.state, label: userDataa.stateName }
            setSelectedState(selectedState)

            const selectedCity = { value: userDataa.city, label: userDataa.cityName }
            setSelectedCity(selectedCity)

            const selectedGender = { value: userDataa.genderId, label: userDataa.gender }
            setGenderOptions(selectedGender)

            formEdit.setFieldsValue({
                employeeid: userDataa.id,
                firstName: userDataa.name,
                lastName: userDataa.surname,
                gender: { value: userDataa.genderId, label: userDataa.gender },
                branch: userDataa.branch,
                city: userDataa.city,
                phoneNumber: userDataa.phoneNumber,
                dateOfBirth: userDataa.dateOfBirth ? moment(userDataa.dateOfBirth, "YYYY-MM-DD") : null, // Ensure valid date
                employeeMailId: userDataa.employeeMailId,
                selectRole: selectRoledata?.label || '',
                highestEducationQualification: userDataa.highestEducationQualification,
                previousExperience: userDataa.previousExperience,
                previousEmployer: userDataa.previousEmployer,
                employeeType: { value: userDataa.employeeTypeId || '', label: userDataa.employeeType || '' },
                designation: { value: userDataa.designationId || '', label: userDataa.designation || '' },
                joiningDate: userDataa.joiningDate !== 'N/A' ? moment(userDataa.joiningDate, "YYYY-MM-DD") : null,
                stateName: { value: userDataa.state, label: userDataa.stateName },
                cityName: { value: userDataa.city || '', label: userDataa.cityName || '' },
                zone: userDataa.zone || undefined,
                employeeIds: userDataa.employeeIds || '',
                reportingDate: userDataa.reportingDate ? moment(userDataa.reportingDate, "YYYY-MM-DD") : null,
                userId: userDataa.userId,
                // employeeType: selectedCompany?.value || null

            });
            setManagerName(userDataa.employeeName || "No Manager");

        }
    }, [isEditModalVisible, userDataa, formEdit]);
    // useEffect(() => {
    //     if (isEditModalVisible && userDataa) {
    //         console.log('User Data Reporting Date:', {
    //             reportingDate: userDataa.reportingDate,
    //             type: typeof userDataa.reportingDate
    //         });
    //     }
    // }, [isEditModalVisible, userDataa]);

    const initialZoneValue = useMemo(() => {
        return userDataa?.zone || undefined;
    }, [userDataa]);

    // Define Table Columns
    const columns = [
        ...(selectionMode ? [{
            title: "",
            dataIndex: "checkbox",
            key: "checkbox",
            render: (_, record) => (
                <input
                    type="checkbox"
                    checked={selectedUsers.includes(record.key)}
                    onChange={() => {
                        if (selectedUsers.includes(record.key)) {
                            setSelectedUsers(selectedUsers.filter(key => key !== record.key));
                        } else {
                            setSelectedUsers([...selectedUsers, record.key]);
                        }
                    }}
                />
            ),
        }] : []),
        { title: "Employee ID", dataIndex: "id", key: "id" },
        { title: "Name", dataIndex: "name", key: "name" },
        { title: "Designation", dataIndex: "designation", key: "designation" },
        // { title: "User Type", dataIndex: "userType", key: "userType" },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <div className="search-input-wrapper">
                    <button className="user-management-edit-button" onClick={() => showEditModal(record)}>
                        <img src={EditIcon} />
                        Edit
                    </button>|
                    <img src={DeleteIcon} style={{ cursor: "pointer" }} onClick={() => handleDeleteUser(record.UserId)} />
                </div>
            ),
        },
    ];

    const handleTableChange = (pagination) => {
        const { current, pageSize: newPageSize } = pagination;

        if (newPageSize !== pageSize) {
            setPageSize(newPageSize);
            setCurrentPage(1);
            setPaginationToken(null);

            refetch({
                pagingCriteria: {
                    cursor: null,
                    limit: newPageSize
                }
            });
            return;
        }

        if (current > currentPage && data?.getUsersForAccount?.pageMeta?.hasNextPage) {
            setCurrentPage(current);

            refetch({
                pagingCriteria: {
                    cursor: data?.getUsersForAccount?.pageMeta?.nextPageCursor,
                    limit: pageSize
                }
            });
        }
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };
    const showEditModal = (user) => {
        console.log("USER", user)
        setUserData(user);
        setIsEditModalVisible(true);
    };


    const handleCancel = (e) => {
        if (e) e.preventDefault();

        setStep(0);
        setStep1(0);
        setIsModalVisible(false);
        setIsEditModalVisible(false)
        // Reset Ant Design form
        form.resetFields();

        // Reset form data
        setFormData({
            fullTimeConsultant: "",
            currentBranch: "",
            employeeIdOfManager: "",
            nameOfManager: "",
            clientImage: "",
            imageUrl: "",
            mainLogoUrl: "",
            id: "",
            userId: null,
            dateOfJoining: null,
            currentDesignation: "",
            state: "",
            city: "",
            zone: "",
            dateOfReporting: null,
            // Add any other fields you're tracking
            employeeid: "",
            employeeMailId: "",
            firstName: "",
            lastName: "",
            gender: "",
            dateOfBirth: null,
            highestEducationQualification: "",
            previousEmployer: "",
            previousExperience: "",
            phoneNumber: "",
        });

        // Reset select dropdowns state variables
        setSelectedCompany(null);
        setSelectedRole(null);
        setSelectedDesignation(null);
        setSelectedState(null);
        setSelectedCity(null);
        setManagerName("No Manager");
        // If you have options arrays you want to clear
        setRoleOptions([]);
        // setDesignationOptions([]);

        // Reset form data list if using it
        setFormDataList([
            {
                fullTimeConsultant: "",
                dateOfJoining: null,
                currentDesignation: "",
                currentBranch: "",
                state: "",
                city: "",
                zone: "",
                employeeIdOfManager: "",
                dateOfReporting: null,
                nameOfManager: "No Manager",
            }
        ]);

        // Reset validation state
        setTouched({});
        setErrors({});
    };

    const handleEditCancel = (e) => {
        if (e) e.preventDefault();

        setStep(0);
        setStep1(0);
        setIsModalVisible(false);
        setIsEditModalVisible(false)
        // Reset Ant Design form
        form.resetFields();
        formEdit.resetFields()
        // Reset form data
        setFormData({
            fullTimeConsultant: "",
            currentBranch: "",
            employeeIdOfManager: "",
            nameOfManager: "",
            clientImage: "",
            imageUrl: "",
            mainLogoUrl: "",
            id: "",
            userId: null,
            dateOfJoining: null,
            currentDesignation: "",
            state: "",
            city: "",
            zone: "",
            dateOfReporting: null,
            // Add any other fields you're tracking
            employeeid: "",
            employeeMailId: "",
            firstName: "",
            lastName: "",
            gender: "",
            dateOfBirth: null,
            highestEducationQualification: "",
            previousEmployer: "",
            previousExperience: "",
            phoneNumber: "",
        });

        // Reset select dropdowns state variables
        setSelectedCompany(null);
        setSelectedRole(null);
        setSelectedDesignation(null);
        setSelectedState(null);
        setSelectedCity(null);
        // If you have options arrays you want to clear
        setRoleOptions([]);
        setManagerName("No Manager");
        // setDesignationOptions([]);

        // Reset form data list if using it
        setFormDataList([
            {
                fullTimeConsultant: "",
                dateOfJoining: null,
                currentDesignation: "",
                currentBranch: "",
                state: "",
                city: "",
                zone: "",
                employeeIdOfManager: "",
                dateOfReporting: null,
                nameOfManager: "No Manager",
            }
        ]);

        // Reset validation state
        setTouched({});
        setErrors({});
    };

    const handleInputChange = (e, field, index) => {
        const { name, value } = e.target || e;
        const fieldName = field || name;
        // Ensure correct data type for previousExperience
        const updatedValue = fieldName === "previousExperience" && value !== ""
            ? parseInt(value, 10)
            : value;

        // Update form data
        if (index !== undefined) {
            setFormDataList(prevList =>
                prevList.map((item, idx) =>
                    idx === index ? { ...item, [fieldName]: updatedValue } : item
                )
            );
        } else {
            setFormData(prev => ({
                ...prev,
                [fieldName]: updatedValue
            }));
        }

        setTouched(prev => ({
            ...prev,
            [index !== undefined ? `${index}-${fieldName}` : fieldName]: true
        }));

        // Email validation
        if (fieldName === "employeeMailId") {
            if (!value.trim()) {
                setErrors(prev => ({
                    ...prev,
                    [fieldName]: "Email is required"
                }));
            } else if (!emailRegex.test(value)) {
                setErrors(prev => ({
                    ...prev,
                    [fieldName]: "Invalid email format"
                }));
            } else {
                setErrors(prev => ({
                    ...prev,
                    [fieldName]: undefined
                }));
            }
        } else {
            // Clear errors for other fields
            if (index !== undefined) {
                setErrors(prev => ({
                    ...prev,
                    [`${index}-${fieldName}`]: undefined
                }));
            } else {
                setErrors(prev => ({
                    ...prev,
                    [fieldName]: undefined
                }));
            }
        }

        // Mark as touched
        setTouched(prev => ({
            ...prev,
            [index !== undefined ? `${index}-${fieldName}` : fieldName]: true
        }));
    };


    ////////////////////////

    const handleEditInputChange = (e, field, index) => {
        const { name, value } = e.target || e;
        const fieldName = field || name;
        // Ensure correct data type for previousExperience
        const updatedValue = fieldName === "previousExperience" && value !== ""
            ? parseInt(value, 10)
            : value;

        // Update form data
        if (index !== undefined) {
            setEditFormData(prevList =>
                prevList.map((item, idx) =>
                    idx === index ? { ...item, [fieldName]: updatedValue } : item
                )
            );
        } else {
            setEditFormData(prev => ({
                ...prev,
                [fieldName]: updatedValue
            }));
        }

        setTouched(prev => ({
            ...prev,
            [index !== undefined ? `${index}-${fieldName}` : fieldName]: true
        }));

        // Email validation
        if (fieldName === "employeeMailId") {
            if (!value.trim()) {
                setErrors(prev => ({
                    ...prev,
                    [fieldName]: "Email is required"
                }));
            } else if (!emailRegex.test(value)) {
                setErrors(prev => ({
                    ...prev,
                    [fieldName]: "Invalid email format"
                }));
            } else {
                setErrors(prev => ({
                    ...prev,
                    [fieldName]: undefined
                }));
            }
        } else {
            // Clear errors for other fields
            if (index !== undefined) {
                setErrors(prev => ({
                    ...prev,
                    [`${index}-${fieldName}`]: undefined
                }));
            } else {
                setErrors(prev => ({
                    ...prev,
                    [fieldName]: undefined
                }));
            }
        }

        // Mark as touched
        setTouched(prev => ({
            ...prev,
            [index !== undefined ? `${index}-${fieldName}` : fieldName]: true
        }));
    };

    const handleEditDateOfBirthDatePickerClick = () => {
        formEdit.setFieldsValue({
            dateOfBirth: dayjs()
        });
    };

    const handleEditDateChange = (name, date) => {
        // Convert the date to a normal date string (YYYY-MM-DD format)
        const normalDate = date ? date.format('YYYY-MM-DD') : null;
        setEditFormData((prevData) => ({
            ...prevData,
            [name]: normalDate, // Use the normalized date string
        }));

        setTouched((prevTouched) => ({
            ...prevTouched,
            [name]: true,
        }));

        // Clear the error for this field if it has a value
        if (normalDate) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: undefined
            }));
        }
    };



    const handleEditJoiningDatePickerClick = () => {
        formEdit.setFieldsValue({
            joiningDate: dayjs(),
        });
    };


    const handleEditListDateChange = (index, field, date) => {
        setEditFormData(prevList =>
            prevList.map((item, idx) =>
                idx === index ? { ...item, [field]: date } : item // Keep as Date object
            )
        );
    };

    const handleEditStateChange = async (selectedValue) => {
        const selectedOption = stateOptions.find(state => state.value === selectedValue);

        if (!selectedOption) {
            console.error("Selected state not found in options:", selectedValue);
            return;
        }
        // setSelectedState(selectedOption);  // Set the selected state
        setSelectedCity(null);  // Clear selected city (reset city when state is changed)
        setCityOptions([]);  // Clear the city options

        formEdit.setFieldsValue({ stateName: selectedOption });  // Clear the selected city in the form
        formEdit.setFieldsValue({ cityName: undefined });  // Clear the selected city in the form

        // Fetch cities based on the selected state
        if (selectedValue) {
            try {
                const response = await fetchCities({ variables: { stateId: selectedValue } });  // API call for cities
                if (response?.data?.getCity?.length) {
                    // Map API response to match the Select format
                    const formattedCities = response.data.getCity.map(city => ({
                        value: city.id,
                        label: city.cityName
                    }));

                    setCityOptions(formattedCities);    // Debugging
                } else {
                    setCityOptions([]);  // Clear city options if no cities are returned
                }
            } catch (error) {
                setCityOptions([]);  // Clear city options in case of error
            }
        }
    };

    const handleEditCityChange = (selectedValue) => {
        // Find the selected city from the cityOptions state
        const selectedOption = cityOptions.find(option => option.value === selectedValue);

        if (selectedOption) {
            // setSelectedCity(selectedOption);
            formEdit.setFieldsValue({ cityName: selectedOption });

            // setSelectedCity(selectedOption);
            // Set the selected city in the form
            // Dynamically clear the error for the city field
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors.city; // Remove the error for the city
                return newErrors;
            });

            setTouched(prevTouched => ({
                ...prevTouched,
                city: true, // Mark city field as touched
            }));
        } else {
            console.error("City not found:", selectedValue);
        }
    };

    const handleEditZoneChange = (selectedOption, index) => {
        // setEditFormData(prevList =>
        //     prevList.map((item, idx) =>
        //         idx === index ? { ...item, zone: selectedOption } : item
        //     )
        // );

        formEdit.setFieldsValue({ zone: selectedOption });

        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors[`${index}-zone`]; // ✅ Remove error dynamically
            return newErrors;
        });

        setTouched(prevTouched => ({
            ...prevTouched,
            [`${index}-zone`]: true, // ✅ Mark as touched per index
        }));
    }

    const handleEditEmployeeIdsChange = (e, index) => {
        const employeeId = e.target.value;
        handleEditInputChange(e, "employeeIds", index);

        if (employeeId.length >= 3) { // Fetch only after 3+ characters
            fetchManager({ variables: { employeeId } });
        }
    };

    const handleEditReportingDatePickerClick = () => {
        formEdit.setFieldsValue({
            reportingDate: dayjs(),
        });
    };

    /////////////////////



    const handleSelectChange = (name, index, field, value) => {
        const fieldName = field || name;
        console.log(`Setting ${fieldName} for index ${index} to value:`, value);

        if (index !== undefined) {
            // Updating an indexed field in formDataList
            setFormDataList(prevList =>
                prevList.map((item, idx) =>
                    idx === index ? { ...item, [fieldName]: value } : item
                )
            );
        } else {
            // Updating formData
            setFormData(prev => ({
                ...prev,
                [fieldName]: value
            }));
        }

        // Mark as touched
        setTouched(prev => ({
            ...prev,
            [fieldName]: true
        }));

        // Clear any previous errors
        setErrors(prev => {
            const updatedErrors = { ...prev };
            delete updatedErrors[fieldName];
            return updatedErrors;
        });
    };



    const removeUserFields = (index) => {
        setFormDataList(formDataList.filter((_, i) => i !== index));
    };

    const handleDateChange = (name, date) => {
        // Convert the date to a normal date string (YYYY-MM-DD format)
        const normalDate = date ? date.format('YYYY-MM-DD') : null;
        setFormData((prevData) => ({
            ...prevData,
            [name]: normalDate, // Use the normalized date string
        }));

        setTouched((prevTouched) => ({
            ...prevTouched,
            [name]: true,
        }));

        // Clear the error for this field if it has a value
        if (normalDate) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: undefined
            }));
        }
    };

    const handleListDateChange = (index, field, date) => {
        setFormDataList(prevList =>
            prevList.map((item, idx) =>
                idx === index ? { ...item, [field]: date } : item // Keep as Date object
            )
        );
    };

    const handleJoiningDatePickerClick = () => {
        form.setFieldsValue({
            joiningDate: dayjs(),
        });
    };

    const handleReportingDatePickerClick = () => {
        form.setFieldsValue({
            reportingDate: dayjs(),
        });
    };

    const handleDateOfBirthDatePickerClick = () => {
        form.setFieldsValue({
            dateOfBirth: dayjs()
        });
    };


    const handleSubmit = async (e) => {

        const formValues = form.getFieldsValue(true);
        console.log("::", formValues)

        const formatDate = (dateValue) => {
            if (!dateValue) return null;

            try {
                const date = new Date(dateValue);
                if (isNaN(date.getTime())) return null;

                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');

                return `${year}-${month}-${day}`;
            } catch (error) {
                console.error("Invalid date format:", dateValue);
                return null;
            }
        };

        localStorage.setItem("roleId", roleId);
        localStorage.setItem("accountId", accountId);

        // Map your form data to the expected API structure
        const userData = formDataList.map((item, index) => {
            // Find gender ID based on selected gender name
            const selectedGender = genderOptions.find(option => option.value === (formData.gender || item.gender));
            const selectedEmployementTypeId = employementOptions.find(option => option.value === (formData.fullTimeConsultant || item.fullTimeConsultant));
            const genderId = selectedGender ? selectedGender.id : null; // Ensure genderId is sent

            return {
                accountId: accountId || null,
                currentRoleId: roleId || null,
                employeeId: formData.employeeid || item.employeeid || "DEFAULT_EMPLOYEE_ID",
                firstName: formData.firstName || item.firstName || "Unknown",
                lastName: formData.lastName || item.lastName || "Unknown",
                email: formData.employeeMailId || item.employeeMailId || "default@example.com",
                phoneNumber: formData.phoneNumber || item.phoneNumber || "0000000000",

                // ✅ Send genderId instead of name
                genderId: selectedGender ? selectedGender.value : null,  // Send ID
                employeeTypeId: selectedEmployementTypeId ? selectedEmployementTypeId.value : null,  // Send ID
                // gender: selectedGender ? selectedGender.label : null, 

                dateOfBirth: (formData.dateOfBirth || item.dateOfBirth) ? formatDate(formData.dateOfBirth || item.dateOfBirth) : "2000-01-01",
                joiningDate: (formData.dateOfJoining || item.dateOfJoining) ? formatDate(formData.dateOfJoining || item.dateOfJoining) : "2024-01-01",
                validFrom: (formData.dateOfReporting || item.dateOfReporting) ? formatDate(formData.dateOfReporting || item.dateOfReporting) : "2024-01-01",

                higestEducationQualification: formData.highestEducationQualification || item.highestEducationQualification || "",
                previousEmployer: formData.previousEmployer || item.previousEmployer || "",
                // employementType: item.fullTimeConsultant || null,
                // employementType: formData.fullTimeConsultant || item.fullTimeConsultant || "",
                managerEmployeeId: formData.employeeIdOfManager || item.employeeIdOfManager || "",
                designation: selectedDesignation?.value ? (selectedDesignation.value) : "Unknown",
                state: selectedState?.value ? (selectedState.value) : "Unknown",
                city: selectedCity?.value ? (selectedCity.value) : "Unknown",

                zone: item.zone?.value || item.zone || "Unknown",
                location: item.location || "Unknown",
                userId: null,
                branch: item.currentBranch || "Unknown",
                previousExperience: (formData.previousExperience || item.previousExperience)
                    ? parseInt(formData.previousExperience || item.previousExperience, 10)
                    : 0,
            };
        });

        try {
            const response = await addUsers({ variables: { data: userData } });

            // Extract response data
            const result = response?.data?.addMultipleUsers;
            const success = result?.success;
            // const generalMessage = result?.message || "User addition failed.";
            const userErrors = result?.data || [];

            if (success) {
                message.success("Users added successfully!");
                console.log("Success:", response.data);
                handleCancel(false); // Assuming you have a state to control modal visibility

                // Reset form fields (optional)
                form.resetFields();
                await refetch();
                setRoleIds(localStorage.getItem("roleId"));
                setAccountIds(localStorage.getItem("accountId"));
                // fetchDesignations({ variables: { jobroleId: Number(localStorage.getItem("roleId")) } });
            } else {
                // Show general error message
                // message.error(generalMessage);

                // Show each specific error from the "data" array
                userErrors.forEach((error) => {
                    if (error?.message) {
                        message.error(error.message); // e.g., "employee id already exists"
                    }
                });

                console.error("User addition failed:", response.data);
            }
        } catch (error) {
            console.error("GraphQL Error:", error);
            message.error("Failed to add users due to a system error.");
        }
    }


    const handleEditSubmit = async (e, values) => {

        const second_data = formEdit.getFieldsValue()
        const formatDate = (dateValue) => {
            if (!dateValue) return null;
            try {
                const date = new Date(dateValue);
                if (isNaN(date.getTime())) return null;
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            } catch (error) {
                console.error("Invalid date format:", dateValue);
                return null;
            }
        };
        const userData = {
            accountId: accountId || null,
            // role: selectedRole?.value || '',
            currentRoleId: roleId || null,
            firstName: basic_data.firstName || '',
            lastName: basic_data.lastName || '',
            email: basic_data.employeeMailId || null,
            phoneNumber: basic_data.phoneNumber || null,
            genderId: basic_data.gender?.value || basic_data.gender,
            dateOfBirth: basic_data.dateOfBirth ? formatDate(basic_data.dateOfBirth) : "2000-01-01",
            previousExperience: Number(basic_data.previousExperience) || null,
            previousEmployer: basic_data.previousEmployer || null,
            higestEducationQualification: basic_data.highestEducationQualification || null,
            employeeId: basic_data.employeeid || null,
            // secondForm 
            // designation: second_data.designation?.value || null,
            designation: second_data.designation ? second_data.designation.value : null,
            // designationId: selectedDesignation?.value || '',
            employeeTypeId: second_data.employeeType?.value || second_data.employeeType,
            joiningDate: second_data.joiningDate ? formatDate(second_data.joiningDate) : null,
            validFrom: second_data.reportingDate ? formatDate(second_data.reportingDate) : null,
            branch: second_data.branch || '',
            managerEmployeeId: second_data.employeeIds || '',
            // city: selectedCity?.value || null,
            city: second_data.cityName ? second_data.cityName.value : null,
            state: selectedState?.value || null,
            zone: second_data.zone || '',
            userId: userDataa.UserId || null
        }
        // Log data before sending API request
        console.log("Submitting User Data:", userData);

        try {
            const response = await addEditUsers({ variables: { data: [userData] } });

            const result = response?.data?.updateMultipleUsers;
            const success = result?.success;
            const userErrors = result?.data || [];

            if (success) {
                message.success("User updated successfully!");
                handleEditCancel(false);
                setStep1(0);
                // Close the modal
                formEdit.resetFields();
                // Clear form fields
                await refetch();
                setRoleIds(localStorage.getItem("roleId"));
                setAccountIds(localStorage.getItem("accountId"));

                // 🔹 Fetch designations again to refresh dropdown
                // fetchDesignations({ variables: { jobroleId: Number(localStorage.getItem("roleId")) } });
            } else {
                userErrors.forEach((error) => {
                    if (error?.message) {
                        message.error(error.message);
                    }
                });
                console.error("User update failed:", response.data);
            }
        } catch (error) {
            console.error("GraphQL Error:", error);
            message.error("Failed to update user due to a system error.");
        }
    };



    const userTypeOptions = [
        { label: "North", value: "north" },
        { label: "South", value: "south" }, // userDataa.zone = "south" should match this
        { label: "East", value: "east" },
        { label: "West", value: "west" }
    ];

    const validateAndSubmitForm = async () => {
        try {
            // First validate all required fields
            await form.validateFields();

            // If validation passes, proceed with submission
            handleSubmit();
        } catch (errorInfo) {
            console.error("Validation Failed:", errorInfo);

            // Display which fields failed validation
            if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
                const fieldNames = errorInfo.errorFields.map(field => field.name[0]).join(', ');
                message.error(`Please complete these required fields: ${fieldNames}`);
            }
        }
    };

    const handleDesignationChange = (selectedOption) => {
        setSelectedDesignation(selectedOption);

        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors.currentDesignation; // ✅ Clears the error dynamically
            return newErrors;
        });

        setTouched(prevTouched => ({
            ...prevTouched,
            currentDesignation: true, // ✅ Mark as touched
        }));
    };


    const handleEditDesignationChange = (selectedValue) => {
        // Find the selected designation using the value from selectedValue
        const selectedOption = designationOptions.find(designation => designation.value === selectedValue);

        // setSelectedDesignation(selectedOption);
        formEdit.setFieldValue("designation", selectedOption)


        // setRoleIds(prevRoleId => prevRoleId || localStorage.getItem("roleId"));
        // setAccountIds(prevAccountId => prevAccountId || localStorage.getItem("accountId"));
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors.currentDesignation; // Clears the error dynamically
            return newErrors;
        });

        // Mark the designation field as touched
        setTouched(prevTouched => ({
            ...prevTouched,
            currentDesignation: true, // Mark the field as touched
        }));

        // You can also ensure the selected value (id) is sent to the API here
        // const designationId = selectedOption ? selectedOption.value : null;
        // if (designationId) {
        //     // Proceed with sending the designation id (to be passed to the API)
        //     console.log("Sending Designation ID to API:", designationId);
        // }
    };

    const handleZoneChange = (selectedOption, index) => {
        setFormDataList(prevList =>
            prevList.map((item, idx) =>
                idx === index ? { ...item, zone: selectedOption } : item
            )
        );

        form.setFieldsValue({
            [index]: {
                ...form.getFieldValue(index),
                zone: selectedOption || ""
            }
        });

        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors[`${index}-zone`]; // ✅ Remove error dynamically
            return newErrors;
        });

        setTouched(prevTouched => ({
            ...prevTouched,
            [`${index}-zone`]: true, // ✅ Mark as touched per index
        }));
    }



    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDeleteClick = () => {
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        setSelectedUsers([]);
        setIsModalOpen(false);
        setSelectionMode(false);
    };

    const handleCancelDelete = () => {
        setIsModalOpen(false);
    };

    const handleDone = () => {
        setSelectionMode(false);
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [isModalVisibleForUpload, setIsModalVisibleForUpload] = useState(false);
    const showModalForUpload = (e) => {
        e.preventDefault();

        setIsModalVisibleForUpload(true);
    }
    const handleCancelUpload = () => setIsModalVisibleForUpload(false);

    const [bulkUploadUsers, { bulkUploadloading, bulkUploaderror, data: bulkUploadData }] = useMutation(Bulk_Upload);
    const [file, setFile] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const fileInputRef = useRef(null);

    const handleBrowseClick = (event) => {
        event.preventDefault();
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset input field
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    // const handleUpload = () => {
    //     if (file) {
    //         navigate("/bulk-leads-result",);
    //         setFile(null); // Clear file after navigation
    //         handleCancelUpload(); // Close modal after upload
    //     }
    // };

    const handleUpload = async () => {
        // if (!file) {
        //     alert("Please select a file before uploading.");
        //     return;
        // }

        // if (!selectedCompany) {
        //     alert("Please select a company before uploading.");
        //     return;
        //   }

        console.log("Uploading file with the following details:");
        console.log("File:", file);
        console.log("Account ID:", accountId);
        console.log("Role ID:", roleId);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("accountId", accountId || ""); // Ensure company ID is sent
        formData.append("currentRoleId", roleId || "");

        try {
            const response = await bulkUploadUsers({
                variables: {
                    accountId: accountId,
                    roleId: roleId,
                    document: file,
                },
            });
            console.log("GraphQL Response:", response);
            if (response.data.bulkUploadUsers.success) {
                message.success("File uploaded successfully!");
                navigate("/bulk-leads-result", {
                    state: {
                        data: response.data,
                        fileName: file.name,
                    },
                });
                setFile(null); // Reset file after upload
                setSelectedCompany(null);
                handleCancelUpload(); // Close modal after successful upload
            } else {
                message.error(response.data.bulkUploadUsers.message);
            }
        } catch (err) {
            console.error("Upload error:", err);
            alert("File upload failed. Please try again.");
        }
    };

    const { data: stateData, stateLoading, stateError } = useQuery(Get_States);
    const stateOptions = stateData?.getStates?.map((state) => ({
        value: state.id,
        label: state.stateName,
    })) || [];

    const { companyloading, companyerror, data: companyName } = useQuery(Accounts_Query);
    const companyOptions = companyName?.allUserAccount?.map((company) => ({
        value: company.id,
        label: company.name,
    })) || [];

    // const { roleloading, roleerror, data: roleName } = useQuery(Role_Account_Query);
    // const roleOptions = roleName?.getAllJobrole?.map((role) => ({
    //     value: role.id,
    //     label: role.name,
    // })) || [];

    const [fetchRoles, { loading: roleLoading, error: roleError }] = useLazyQuery(Role_Account_Query, {
        fetchPolicy: "network-only", // Ensures fresh data on every request
        onCompleted: (data) => {
            const roles = data?.getAllJobrole?.map((role) => ({
                value: role.id,
                label: role.name,

                // accountId: role.account?.name
            })) || [];
            setRoleOptions(roles);
        },
    });


    // useEffect(() => {
    //     if (formDataList?.length > 0) {
    //         form.setFieldsValue({ formDataList });
    //     }
    // }, [formDataList]);

    const [fetchCities, { loading: citiesLoading, error: citiesError }] = useLazyQuery(Get_City, {
        fetchPolicy: "network-only", // Ensures fresh data on every request
        onCompleted: (data) => {
            const roles = data?.getCity?.map((city) => ({
                value: city.id,
                label: city.cityName,
            })) || [];
            setCityOptions(roles);
        },
    });

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedCity(null);
        setCityOptions([]); // Reset roles when company changes

        if (selectedOption?.value) {
            fetchCities({ variables: { stateId: parseInt(selectedOption.value, 10) } });
        }

        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors.state;
            return newErrors;
        });
    };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors.city;
            return newErrors;
        });
    };





    const handleEditCompanyChange = async (selectedValue) => {

        const selectedOption = companyOptions.find(company => company.value === selectedValue);

        if (!selectedOption) {
            console.error("Selected company not found in options:", selectedValue);
            return;
        }
        console.log("selectedvalue", selectedValue)
        console.log("Selected Company:", selectedOption);

        setSelectedCompany(selectedOption);
        setSelectedRole(null); // Reset role
        setSelectedDesignation(null); // Reset designation
        setRoleOptions([]); // Clear role options
        // setDesignationOptions([]); // Clear designation options

        form.setFieldsValue({ selectRole: undefined }); // Reset role field in the form

        if (selectedValue) {
            fetchRoles({
                variables: {
                    accountId: selectedValue // Adjust variable name as per your query
                }
            });
        }

        if (selectedOption?.label) {
            try {
                const response = await fetchRoles({ variables: { accountId: parseInt(selectedOption.value, 10) } });
                console.log("Roles API Response:", response); // Debugging

                if (response?.data?.getAllJobrole?.length) {
                    const formattedRoles = response.data.getAllJobrole.map(role => ({
                        value: role.id,
                        label: role.name
                    }));
                    setRoleOptions(formattedRoles);
                    console.log("Updated Role Options:", formattedRoles); // Debugging
                } else {
                    console.warn("No roles returned from API");
                    setRoleOptions([]); // Ensure state is cleared if no roles
                }
            } catch (error) {
                console.error("Error fetching roles:", error);
                setRoleOptions([]); // Ensure state is cleared on error
            }
        }
    };

    const handleEditRoleChange = async (selectedValue) => {
        const selectedOption = roleOptions.find(role => role.value === selectedValue);

        if (!selectedOption) {
            console.error("Selected role not found in options:", selectedValue);
            return;
        }
        setSelectedRole(selectedOption);
        setSelectedDesignation(null); // Reset designation options
        // setDesignationOptions([]); // Clear designation options

        form.setFieldsValue({ selectDesignation: undefined }); // Reset designation field in the form

        // if (selectedValue) {
        //     fetchDesignations({
        //         variables: {
        //             jobroleId: selectedValue // Adjust variable name as per your query
        //         }
        //     });
        // }

        // if (selectedOption?.label) {
        //     try {
        //         const response = await fetchDesignations({ variables: { jobroleId: parseInt(selectedOption.value, 10) } });
        //         console.log("Designations API Response:", response); // Debugging

        //         if (response?.data?.getDesignationsJobroleid?.length) {
        //             const formattedDesignations = response.data.getDesignationsJobroleid.map(designation => ({
        //                 value: designation.id,
        //                 label: designation.designation
        //             }));
        //             setDesignationOptions(formattedDesignations);
        //             console.log("Updated Designation Options:", formattedDesignations); // Debugging
        //         } else {
        //             console.warn("No designations returned from API");
        //             setDesignationOptions([]); // Ensure state is cleared if no designations
        //         }
        //     } catch (error) {
        //         console.error("Error fetching designations:", error);
        //         setDesignationOptions([]); // Ensure state is cleared on error
        //     }
        // }
    };

    const handleCompanyChange = (selectedOption) => {
        setSelectedCompany(selectedOption);
        setSelectedRole(null);
        setSelectedDesignation(null);
        setRoleOptions([]);
        // setDesignationOptions([]);

        // Also update formData to maintain consistency
        setFormData((prevData) => ({
            ...prevData,
            selectCompany: selectedOption, // Store the entire selected option
        }));

        // Clear errors and set touched as before
        setErrors((prev) => ({
            ...prev,
            selectCompany: selectedOption ? "" : prev.selectCompany,
            selectRole: "",
        }));

        setTouched((prev) => ({
            ...prev,
            selectCompany: true,
            selectRole: false,
        }));

        if (selectedOption) {
            fetchRoles({ variables: { accountId: parseInt(selectedOption.value, 10) } });
        }
    };

    const handleRoleChange = (selectedOption) => {
        setSelectedRole(selectedOption);
        setSelectedDesignation(null);
        // setDesignationOptions([]); // Reset designations when role changes
        // form.setFieldsValue({ selectRole: roleId });
        // if (selectedOption.value) {
        //     fetchDesignations({ variables: { jobroleId: parseInt(selectedOption.value, 10) } });
        // }
    };

    // const [fetchDesignations, { loading: designationLoading, error: designationError, refetch:refetchData }] = useLazyQuery(Current_Designation, {
    //     fetchPolicy: "network-only",
    //     onCompleted: (data) => {
    //         if (!roleIds) return;
    //         const designations = data?.getDesignationsJobroleid?.map((designation) => ({
    //             value: designation.id,
    //             label: designation.designation,
    //         })) || [];
    //         setDesignationOptions(designations);
    //         refetchData()
    //     },
    // });

    useEffect(() => {
        if (location.state?.roleId && location.state?.roleId !== roleIds) {
            setRoleIds(location.state.roleId);
            localStorage.setItem("roleId", location.state.roleId);
        }

        if (location.state?.accountId && location.state?.accountId !== accountIds) {
            setAccountIds(location.state.accountId);
            localStorage.setItem("accountId", location.state.accountId);
        }
    }, [location.state?.roleId, location.state?.accountId]);

    // useEffect(() => {
    //     if (roleIds) {
    //         console.log("Fetching Designations for Role ID:", roleIds);
    //         fetchDesignations({ variables: { jobroleId: Number(roleIds) } });
    //     }
    // }, [roleIds, fetchDesignations]);


    const handleDrop = (info) => {
        info.preventDefault();
        if (info.file) {
            setFile(info.file);
        }
    };

    const [getBulkUploadExcel, { loading: downloadLoading, data: downloadData, error: downloadError }] = useLazyQuery(Bulk_Upload_Sheet);

    const handleDownload = () => {
        getBulkUploadExcel();
    };

    useEffect(() => {
        if (downloadData && downloadData.getBulkUploadExcel) {
            console.log("API Response:", downloadData);

            // Get the URL from the response
            const fileUrl = downloadData.getBulkUploadExcel;
            console.log("File URL:", fileUrl);

            // Create an anchor element to trigger the download
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = 'Bulk_Upload_Sheet.xlsx';
            a.style.display = 'none'; // Hide the element
            document.body.appendChild(a);

            // Trigger the click event
            setTimeout(() => {
                a.click();
                // Clean up
                document.body.removeChild(a);
            }, 100);
        }

        if (downloadError) {
            console.error("API Error:", downloadError);
        }
    }, [downloadData, downloadError]);

    const [managerName, setManagerName] = useState("No Manager");  // Default to "No Manager"

    const [fetchManager, { data: employeeIdData, loading: employeeIdLoading, error: employeeIdError }] = useLazyQuery(Verify_Employee_Id);
    const handleEmployeeIdChange = (e, index) => {
        const employeeId = e.target.value;
        handleInputChange(e, "employeeIdOfManager", index);

        if (employeeId.length >= 3) { // Fetch only after 3+ characters
            fetchManager({ variables: { employeeId } });
        }
    };

    useEffect(() => {
        if (employeeIdData?.verifyEmployeeId) {
            // Set the manager's name in state
            const managerName = employeeIdData.verifyEmployeeId.name || "No Manager";
            console.log("Setting manager name:", managerName);
            setManagerName(managerName);  // Update state
        } else {
            setManagerName("No Manager");
        }
    }, [employeeIdData]);  // Dependency array - rerun whenever employeeIdData changes

    const handleEmployeeIdsChange = (e, index) => {
        const employeeId = e.target.value;
        handleInputChange(e, "employeeIds", index);

        if (employeeId.length >= 3) { // Fetch only after 3+ characters
            fetchManager({ variables: { employeeId } });
        }
    };

    // useEffect((index) => {
    //     if (employeeIdData?.verifyEmployeeId) {
    //         form.setFields([
    //             {
    //                 name: ["formDataList", index, "nameOfManager"],
    //                 value: employeeIdData?.verifyEmployeeId?.name,
    //             },
    //         ]);
    //     }
    // }, [employeeIdData, form, index]);
    // console.log(employeeIdData?.verifyEmployeeId?.name);
    // const managerName = employeeIdData?.verifyEmployeeId?.name || "No Manager";
    const handleDiscardClick = () => {
        navigate("/admin");
    };

    const { loading: genderLoading, error: genderError, data: genderData } = useQuery(List_Gender_From_Role, {
        variables: { jobroleId: roleId }, // Ensure roleId is correctly defined
    });

    // Check if genderData exists before mapping
    const genderOptions = genderData?.listGenderFromRole
        ? genderData.listGenderFromRole.map((gender) => ({
            value: gender.id,
            label: gender.name,
        }))
        : [];


    const { loading: employementLoading, error: employementError, data: employementData } = useQuery(
        List_Employement_Type_From_Role,
        { variables: { jobRoleId: roleId } } // Ensure roleId is defined
    );

    // Convert API response into dropdown options
    const employementOptions = employementData?.listEmployementTypeFromRole?.map((type) => ({
        value: type.id,   // Store the ID
        label: type.name, // Display the name
    })) || [];

    const { loading: designationsLoading, error: designationsError, data: designationsData } = useQuery(
        Current_Designation,
        { variables: { jobroleId: roleId } } // Ensure roleId is defined
    );

    // Convert API response into dropdown options
    const designationOptions = designationsData?.getDesignationsJobroleid?.map((type) => ({
        value: type.id,   // Store the ID
        label: type.designation, // Display the name
    })) || [];

    return (
        <div className="user-management-container">
            {/* Toggle and Back Button */}
            {/* <div className="toggle-container">
                <div className="user-management-toggle-switch">
                    <div className="view-role-toggle-switch">
                        <div
                            className={`toggle-option ${activeTab === "product-configuration" ? "active" : ""}`}
                            onClick={() => setActiveTab("product-configuration")}
                        >
                            Product Configuration
                        </div>
                        <div
                            className={`toggle-option ${activeTab === "user-management" ? "active" : ""}`}
                            onClick={() => setActiveTab("user-management")}
                        >
                            User Management
                        </div>
                        <div className={`toggle-slider ${activeTab}`} />
                    </div>
                </div>
                <button className="user-management-back-button" onClick={() => navigate(-1)}>
                    <img src={BackArrow} alt="Back" className="back-icon" />
                    Back
                </button>
            </div> */}

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "space-between", margin: "0px 4px" }}>

                <div className="product-selection-breadcrumb">
                    <span style={{ fontFamily: "Inter", margin: "0 5px", }}>Client</span> <span>&gt; </span>
                    <span style={{ fontFamily: "Inter", margin: "0 5px", }}>Select Product</span> <span>&gt; </span>
                    <span className="bold-text">Darts</span>
                </div>

                <button className="user-management-back-button" onClick={handleDiscardClick}>
                    {/* <img src={LeftArrow} alt="Less Than" style={{ marginRight: "5px", height: "14px", width: "14px" }} /> */}
                    Back
                    {/* <img src={HomeIcon} alt="Less Than" style={{ marginLeft: "5px", height: "13px", width: "13px" }} /> */}

                </button>
            </div>

            <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)} centered>
                <TabPane style={{ color: "red" }} tab="Masters Configuration" key="masters-configuration">
                    <MastersConfiguration />
                </TabPane>
                <TabPane tab="Product Configuration" key="2">
                    <ProductConfiguration />
                </TabPane>
                <TabPane tab="User Management" key="user-management">

                    {activeTab === "user-management" && (
                        selectionMode ? (
                            <div className="user-management-container-selectedUser">
                                <div className="selection-controls">
                                    <button onClick={handleSelectAll} className="select-button">
                                        {selectedUsers.length === tableData.length ? "Deselect All" : "Select All"}
                                    </button>
                                </div>
                                <div className="user-management-action-buttons">
                                    <button
                                        className={`user-management-delete-button ${selectedUsers.length === 0 ? "disabled" : ""}`}
                                        disabled={selectedUsers.length === 0}
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        Delete
                                    </button>
                                    <button className="done-button" onClick={handleDone}>
                                        Done
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <Card className="search-card">
                                <div className="search-container">
                                    <div className="search-group">
                                        <label>Search</label>
                                        <div className="search-input-wrapper">
                                            <Input
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                                placeholder="Search name / Employee ID / Designation"
                                                suffix={<SearchOutlined />}
                                            />
                                            <DatePicker
                                                placeholder="Joining Date"
                                                onChange={handleDateChangeSearch}
                                            />
                                        </div>
                                    </div>

                                    {/* Action Buttons */}
                                    <div className="button-group">
                                        <button className="add-user-btn" onClick={showModal}>
                                            <img src={AdduserIcon} alt="Add User" className="button-icon" />
                                            Add User
                                        </button>
                                        <button className="select-user-btn" onClick={toggleSelectionMode}>
                                            <img src={SelectuserIcon} alt="Select User" className="button-icon" />
                                            Select User
                                        </button>
                                    </div>
                                </div>
                            </Card>
                        )
                    )}

                    <div className="tab-content">
                        {activeTab === "user-management" ? (
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                pagination={false}
                                onChange={handleTableChange}
                                loading={loadingg}
                                components={{
                                    header: {
                                        cell: (props) => (
                                            <th {...props} className="custom-table-header" />
                                        ),
                                    },
                                }}
                            />
                        ) : (
                            <div className="product-configuration">
                                {/* <h2>Product Configuration</h2>
                        <p>Manage product configurations and settings here.</p> */}
                            </div>
                        )}
                    </div>
                    <br />
                    <div className="pagination-controls">
                        <span className="pagination-text">
                            {data?.getUsersForAccount?.pageMeta?.totalCount > 0
                                ? `${(currentPage - 1) * pageSize + 1} - ${Math.min(currentPage * pageSize, data?.getUsersForAccount?.pageMeta?.totalCount)} of ${data?.getUsersForAccount?.pageMeta?.totalCount}`
                                : "No Data"}
                        </span>
                        <button
                            className="pagination-button"
                            onClick={handlePreviousPage}
                            disabled={!data?.getUsersForAccount?.pageMeta?.hasPreviousPage}
                        >
                            &lt;
                        </button>
                        <button
                            className="pagination-button"
                            onClick={handleNextPage}
                            disabled={!data?.getUsersForAccount?.pageMeta?.hasNextPage}
                        >
                            &gt;
                        </button>
                    </div>
                </TabPane>
            </Tabs>

            {/* { */}




            <Modal title="User Registration Form" open={isModalVisible} onCancel={handleCancel} footer={null} width={700} centered>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <a

                            style={{
                                fontWeight: step === 0 ? 'bold' : 'normal',
                                color: step === 0 ? '#d32f2f' : 'rgb(188, 188, 188)', // Active: Red, Inactive: Blue
                            }}
                            onClick={() => setStep(0)}
                        >
                            1. User Basic Data
                        </a>

                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <a
                            onClick={() => setStep(1)}
                            style={{
                                fontWeight: step === 1 ? 'bold' : 'normal',
                                color: step === 1 ? '#d32f2f' : 'rgb(188, 188, 188)', // Active: Red, Inactive: Blue

                            }}
                        >
                            2. User Work Data
                        </a>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Form form={form} layout="vertical" onSubmit={(e) => e.preventDefault()}>
                    {step === 0 ? (
                        <>
                            {/* <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Select Company</label><br />
                                    <SelectDropdown
                                        options={companyOptions}
                                        placeholder="Select Company"
                                        onChange={handleCompanyChange}
                                        isLoading={companyloading}
                                        value={selectedCompany}
                                    />
                                    {touched.selectCompany && errors.selectCompany && (
                                        <div className="createClientErrors">{errors.selectCompany}</div>
                                    )}
                                </Col>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Select Role</label><br />
                                    <SelectDropdown
                                        options={roleOptions}
                                        placeholder="Select Role"
                                        value={selectedRole}
                                        isDisabled={!selectedCompany}
                                        onChange={handleRoleChange}
                                    />
                                    {touched.selectRole && errors.selectRole && (
                                        <div className="createClientErrors">{errors.selectRole}</div>
                                    )}
                                </Col>
                            </Row> */}
                            <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Employee ID</label>
                                    <TextInputField
                                        name="employeeid"
                                        value={formData.employeeid || ""}
                                        placeholder="Employee ID"
                                        feedbackMessage="Please input the current branch."
                                        validationRegex={validationRegexAlphaNumeric}
                                        onChange={handleInputChange}
                                    />
                                    {touched.employeeid && errors.employeeid && (
                                        <div className="createClientErrors">{errors.employeeid}</div>
                                    )}
                                </Col>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Employee Mail ID</label>
                                    <TextInputField
                                        name="employeeMailId"
                                        value={formData.employeeMailId}
                                        placeholder="Employee Mail ID"
                                        feedbackMessage="Please enter a valid email address."
                                        // validationRegex={emailRegex}
                                        onChange={handleInputChange}
                                    />
                                    {touched.employeeMailId && errors.employeeMailId && (
                                        <div className="createClientErrors">{errors.employeeMailId}</div>
                                    )}
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">First Name</label>
                                    <TextInputField
                                        name="firstName"
                                        value={formData.firstName}
                                        placeholder="First Name"
                                        feedbackMessage="Please input the current branch."
                                        validationRegex={validationRegexOnlyAlpha}
                                        onChange={handleInputChange}
                                    />
                                    {touched.firstName && errors.firstName && (
                                        <div className="createClientErrors">{errors.firstName}</div>
                                    )}
                                </Col>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Last Name</label>
                                    <TextInputField
                                        name="lastName"
                                        value={formData.lastName}
                                        placeholder="Last Name"
                                        feedbackMessage="Please input the current branch."
                                        validationRegex={validationRegexOnlyAlpha}
                                        onChange={handleInputChange}
                                    />
                                    {touched.lastName && errors.lastName && (
                                        <div className="createClientErrors">{errors.lastName}</div>
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Gender</label><br />
                                    {/* <Radio.Group
                                        onChange={(e) => handleInputChange({ target: { name: 'gender', value: e.target.value } })}
                                        value={formData.gender}>
                                        <Radio value="male">Male</Radio>
                                        <Radio value="female">Female</Radio>
                                        <Radio value="other">Other</Radio>
                                    </Radio.Group> */}
                                    <SelectDropdown
                                        options={genderOptions}
                                        placeholder="Select Gender"
                                        value={genderOptions.find(option => option.value === formData.gender) || null} // Ensure no undefined access
                                        onChange={(selectedOption) =>
                                            handleInputChange({ target: { name: "gender", value: selectedOption.value } })
                                        }
                                        isSearchable
                                    />
                                    {/* </Form.Item> */}
                                    {touched.gender && errors.gender && (
                                        <div className="createClientErrors">{errors.gender}</div>
                                    )}
                                </Col>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Date of Birth</label>
                                    <DatePicker
                                        style={{ width: "100%" }}
                                        placeholder="Date of Birth"
                                        value={formData.dateOfBirth ? dayjs(formData.dateOfBirth, "YYYY-MM-DD") : null}
                                        onChange={(date) => handleDateChange("dateOfBirth", date)}
                                    />
                                    {touched.dateOfBirth && errors.dateOfBirth && (
                                        <div className="createClientErrors">{errors.dateOfBirth}</div>
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label>Highest Education Qualification</label>
                                    <TextInputField
                                        name="highestEducationQualification"
                                        value={formData.highestEducationQualification}
                                        placeholder="Highest Education Qualification"
                                        feedbackMessage="Please input the current branch."
                                        validationRegex={validationRegexOnlyAlpha}
                                        onChange={handleInputChange}
                                    />
                                    {touched.highestEducationQualification && errors.highestEducationQualification && (
                                        <div className="createClientErrors">{errors.highestEducationQualification}</div>
                                    )}
                                </Col>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label>Previous Employer</label>
                                    <TextInputField
                                        name="previousEmployer"
                                        value={formData.previousEmployer}
                                        placeholder="Previous Employer"
                                        feedbackMessage="Please input the current branch."
                                        validationRegex={validationRegexAlphaNumeric}
                                        onChange={handleInputChange}
                                    />
                                    {touched.previousEmployer && errors.previousEmployer && (
                                        <div className="createClientErrors">{errors.previousEmployer}</div>
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label></label>Previous Experience (Months)
                                    <TextInputField
                                        name="previousExperience"
                                        value={formData.previousExperience}
                                        placeholder="Previous Experience (Months)"
                                        feedbackMessage="Please input the current branch."
                                        validationRegex={validationRegexAmount}
                                        onChange={(e) => handleInputChange(e, "previousExperience")}
                                    />
                                    {touched.previousExperience && errors.previousExperience && (
                                        <div className="createClientErrors">{errors.previousExperience}</div>
                                    )}
                                </Col>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Phone Number</label>
                                    <TextInputField
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        placeholder="Phone Number"
                                        feedbackMessage="Please input the current branch."
                                        validationRegex={validationRegexMobileNo}
                                        onChange={handleInputChange}
                                    />
                                    {touched.phoneNumber && errors.phoneNumber && (
                                        <div className="createClientErrors">{errors.phoneNumber}</div>
                                    )}
                                </Col>
                            </Row>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                {/* Left side: Bulk Upload and Download buttons */}
                                <div style={{ display: "flex" }}>
                                    <button
                                        className="global-admin-bulk-upload-button"
                                        onClick={showModalForUpload}
                                    // disabled={!selectedCompany || !selectedRole} // Disable if either is not selected
                                    // style={{
                                    //     opacity: (!selectedCompany || !selectedRole) ? 0.5 : 1, // Visually indicate disable state
                                    //     cursor: (!selectedCompany || !selectedRole) ? "not-allowed" : "pointer"
                                    // }}
                                    >
                                        <img src={BulkUpload} alt="Add User" style={{ marginRight: "4px", width: "15px", height: "15px" }} />
                                        Bulk Upload
                                        <img src={BulkUploadArrow} alt="Add User" style={{ marginLeft: "4px", width: "15px", height: "15px" }} />
                                    </button>

                                    <button className="handleDownload" onClick={handleDownload} disabled={downloadLoading}>
                                        Bulk Upload Sheet
                                    </button>
                                </div>

                                {/* Right side: Next button */}
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                                    <button className="nextButton" onClick={(e) => handleNext(e)}>Next</button>
                                </div>
                            </div>

                        </>

                    ) : (
                        <>
                            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                                {formDataList?.map((formData, index, item) => (
                                    <div key={index} style={{ borderBottom: "1px solid #ccc", paddingBottom: "20px", marginBottom: "20px" }}>
                                        <Row gutter={[16, 16]} style={
                                            {
                                                marginBottom: "-5px"
                                            }
                                        }>
                                            <Col sm={12} xs={23} md={11} lg={12}>
                                                <label className="required-label">Employment Type</label>

                                                <Form.Item
                                                    name={["formDataList", index, "fullTimeConsultant"]}
                                                    rules={[
                                                        { required: true, message: "This field is required!" }
                                                    ]}
                                                >
                                                    <SelectDropdown
                                                        options={employementOptions}
                                                        placeholder="Select Employment Type"
                                                        value={formDataList[index]?.fullTimeConsultant || null} // Ensure a valid value
                                                        onChange={(selectedOption) => {
                                                            // if (!formDataList[index]) return; // Prevent undefined error

                                                            // Update formDataList safely
                                                            const updatedList = [...formDataList];
                                                            updatedList[index] = {
                                                                ...updatedList[index],
                                                                fullTimeConsultant: selectedOption.value
                                                            };
                                                            setFormDataList(updatedList);

                                                            // Ensure form fields are updated correctly
                                                            form.setFieldsValue({
                                                                fullTimeConsultant: selectedOption.value
                                                            });
                                                        }}
                                                        isSearchable
                                                    />
                                                </Form.Item>

                                            </Col>

                                            <Col sm={12} xs={23} md={11} lg={12}>
                                                <label className="required-label">Date of Joining</label>

                                                <Form.Item
                                                    name={["formDataList", index, "dateOfJoining"]}
                                                    rules={[{ required: true, message: "Date of Joining is required!" }]}
                                                >
                                                    <DatePicker
                                                        style={{ width: "100%" }}
                                                        placeholder="Date of Joining"
                                                        onChange={(date) => handleListDateChange(index, "dateOfJoining", date)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={[16, 16]} style={
                                            {
                                                marginBottom: "-5px"
                                            }
                                        }>
                                            <Col sm={12} xs={23} md={11} lg={12}>
                                                <label className="required-label">Current Designation</label>

                                                <Form.Item
                                                    name={["currentDesignation"]}
                                                    rules={[{ required: true, message: "Designation is required!" }]}
                                                >
                                                    <SelectDropdown
                                                        options={designationOptions}
                                                        placeholder="Current Designation"
                                                        // isDisabled={!selectedRole}
                                                        // isLoading={designationLoading}
                                                        onChange={handleDesignationChange}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} xs={23} md={11} lg={12}>
                                                <label className="required-label">Current Branch</label>

                                                <Form.Item
                                                    name={["formDataList", index, "currentBranch"]}
                                                    rules={[
                                                        { required: true, message: "Branch is required!" },
                                                        {
                                                            pattern: validationRegexOnlyAlpha,
                                                            message: "Only alphabets are allowed!",
                                                        },
                                                    ]}
                                                >
                                                    <TextInputField
                                                        placeholder="Current Branch"
                                                        feedbackMessage="Please input the current branch."
                                                        onChange={(e) => handleInputChange(e, "currentBranch", index)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={[16, 16]} style={
                                            {
                                                marginBottom: "-5px"
                                            }
                                        }>
                                            <Col sm={12} xs={23} md={11} lg={12}>
                                                <label className="required-label">State</label>

                                                <Form.Item
                                                    name={["formDataList", index, "state"]}
                                                    rules={[{ required: true, message: "State is required!" }]}
                                                >
                                                    <SelectDropdown
                                                        options={stateOptions}
                                                        placeholder="Select State"
                                                        isLoading={stateLoading}
                                                        onChange={handleStateChange}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} xs={23} md={11} lg={12}>
                                                <label className="required-label">City</label>

                                                <Form.Item
                                                    name={["formDataList", index, "city"]}
                                                    rules={[{ required: true, message: "City is required!" }]}
                                                >
                                                    <SelectDropdown
                                                        options={cityOptions}
                                                        placeholder="Select City"
                                                        isDisabled={!selectedState}
                                                        isLoading={citiesLoading}
                                                        onChange={handleCityChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={[16, 16]} style={
                                            {
                                                marginBottom: "-5px"
                                            }
                                        }>
                                            <Col sm={12} xs={23} md={11} lg={12}>
                                                <label className="required-label">Zone</label>
                                                <Form.Item
                                                    name={["formDataList", index, "zone"]}
                                                    rules={[{ required: true, message: "Zone is required!" }]}
                                                >
                                                    <SelectDropdown
                                                        options={userTypeOptions}
                                                        placeholder="Zone"
                                                        onChange={(selectedOption) => handleZoneChange(selectedOption, index)}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} xs={23} md={11} lg={12}>
                                                <label>Employee ID of Manager</label>
                                                <Form.Item
                                                    name={["formDataList", index, "employeeIdOfManager"]}
                                                    rules={[
                                                        {
                                                            pattern: validationRegexAlphaNumeric,
                                                            message: "Only alphabets are allowed!",
                                                        },
                                                    ]}
                                                >
                                                    <TextInputField
                                                        placeholder="Employee ID Of Manager"
                                                        feedbackMessage="Please input the employee ID of the manager."
                                                        onChange={handleEmployeeIdChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={[16, 16]} style={
                                            {
                                                marginBottom: "x"
                                            }
                                        }>
                                            <Col sm={12} xs={23} md={11} lg={12}>
                                                <label>Date of Reporting</label>
                                                <Form.Item
                                                    name={["formDataList", index, "dateOfReporting"]}
                                                >
                                                    <DatePicker
                                                        style={{ width: "100%" }}
                                                        placeholder="Date of Reporting"
                                                        onChange={(date) => handleListDateChange(index, "dateOfReporting", date)}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={12} xs={23} md={11} lg={12}>
                                                <label >Name of Manager</label>
                                                <Form.Item
                                                    // name={["formDataList", index, "nameOfManager"]}
                                                    rules={[
                                                        {
                                                            pattern: validationRegexOnlyAlpha,
                                                            message: "Only alphabets are allowed!",
                                                        },
                                                    ]}
                                                >
                                                    <TextInputField
                                                        placeholder="Name of Manager"
                                                        feedbackMessage="Please input the name of the manager."
                                                        // disabled
                                                        // onChange={(e) => handleInputChange(e, "nameOfManager", index)}
                                                        // value={employeeIdData?.verifyEmployeeId?.name || "No Manager"}
                                                        value={managerName}
                                                        disabled
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {index > 0 && (
                                            <button
                                                className="user-management-cancel-button"
                                                onClick={() => removeUserFields(index)}
                                                style={{ marginTop: "10px", backgroundColor: "#d32f2f", color: "white", display: "flex", justifySelf: "end", alignItems: "center" }}
                                            >
                                                Remove User
                                            </button>
                                        )}
                                    </div>
                                ))}

                                <div className="global-admin-modal-footer" >




                                    {/* <button className="global-admin-add-user-button" onClick={addUserFields}>
                                        Add User
                                    </button> */}


                                    <button className="user-management-cancel-button" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="global-admin-apply-button"
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent default refresh behavior
                                            console.log("Form", form.getFieldsValue())
                                            const allFields = Object.keys(form.getFieldsValue());
                                            const fieldsToValidate = allFields.filter(field => field !== "branch" && field !== "cityName" && field !== "designation" && field !== "employeeType" && field !== "employeeIds" && field !== "joiningDate" && field !== "reportingDate" && field !== "stateName" && field !== "zone");
                                            console.log("::::777", fieldsToValidate)
                                            form.validateFields()
                                                .then((values) => {
                                                    console.log("Form Values:", values);
                                                    handleSubmit();
                                                })
                                                .catch((errorInfo) => {
                                                    console.log("Validation Failed:", errorInfo);
                                                });
                                        }}
                                    >
                                        Save
                                    </button>


                                </div>
                            </Form>
                        </>
                    )}
                </Form>
            </Modal>

            <Modal title="Edit Registration Form" open={isEditModalVisible} onCancel={handleEditCancel} footer={null} width={700} centered>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <a

                            style={{
                                fontWeight: step1 === 0 ? 'bold' : 'normal',
                                color: step1 === 0 ? '#d32f2f' : 'rgb(188, 188, 188)', // Active: Red, Inactive: Blue
                            }}
                            onClick={() => setStep1(0)}
                        >
                            1. User Basic Data
                        </a>

                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <a
                            onClick={() => setStep1(1)}
                            style={{
                                fontWeight: step1 === 1 ? 'bold' : 'normal',
                                color: step1 === 1 ? '#d32f2f' : 'rgb(188, 188, 188)', // Active: Red, Inactive: Blue

                            }}
                        >
                            2. User Work Data
                        </a>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Form form={formEdit} layout="vertical" onSubmit={(e) => e.preventDefault()}>
                    {step1 === 0 ? (
                        <>
                            {/* <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Select Company</label>
                                    <Form.Item
                                        name="accountName"
                                        rules={[{ required: true, message: "Company selection is required!" }]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                const label = option.label || ""; // Default to empty string if label is undefined
                                                return label.toLowerCase().includes(input.toLowerCase());
                                            }}
                                            options={companyOptions}
                                            placeholder="Select Company"
                                            onChange={handleEditCompanyChange}
                                            isLoading={companyloading}
                                            value={selectedCompany}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Select Role</label>
                                    <Form.Item
                                        name="selectRole"
                                        rules={[{ required: true, message: "Role selection is required!" }]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Select Role"
                                            options={roleOptions}
                                            loading={roleLoading}
                                            disabled={!selectedCompany}
                                            onChange={handleEditRoleChange}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '')
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Employee ID</label>
                                    <Form.Item
                                        name="employeeid"
                                        rules={[{ required: true, message: "Employee ID is required!" }]}
                                    >
                                        <TextInputField
                                            name="employeeid"
                                            value={editFormData.employeeid}
                                            placeholder="Employee ID"
                                            feedbackMessage="Please input the Employee ID."
                                            validationRegex={validationRegexAlphaNumeric}
                                            onChange={handleEditInputChange}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label>Employee Mail ID</label>
                                    <Form.Item
                                        name="employeeMailId"
                                        rules={[
                                            { required: true, message: "Employee Mail ID is required!" },
                                            { type: "email", message: "Please enter a valid email address!" }
                                        ]}
                                    >
                                        <TextInputField
                                            name="employeeMailId"
                                            value={editFormData.employeeMailId}
                                            placeholder="Employee Mail ID"
                                            feedbackMessage="Please enter a valid email address."
                                            onChange={handleEditInputChange}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">First Name</label>
                                    <Form.Item
                                        name="firstName"
                                        rules={[
                                            { required: true, message: "First name is required!" },
                                            { pattern: validationRegexOnlyAlpha, message: "Only alphabets are allowed!" }
                                        ]}
                                    >
                                        <TextInputField
                                            name="firstName"
                                            value={editFormData.firstName}
                                            placeholder="First Name"
                                            feedbackMessage="Please enter your first name."
                                            onChange={handleEditInputChange}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Last Name</label>
                                    <Form.Item
                                        name="lastName"
                                        rules={[
                                            { required: true, message: "Last name is required!" },
                                            { pattern: validationRegexOnlyAlpha, message: "Only alphabets are allowed!" }
                                        ]}
                                    >
                                        <TextInputField
                                            name="lastName"
                                            value={editFormData.lastName}
                                            placeholder="Last Name"
                                            feedbackMessage="Please enter your last name."
                                            onChange={handleEditInputChange}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Gender</label>
                                    <Form.Item
                                        name="gender"
                                        rules={[{ required: true, message: "Gender selection is required!" }]}
                                    >
                                        <Select
                                            options={genderOptions}
                                            placeholder="Select Gender"
                                            // value={genderOptions.find(option => option.value === formData.gender) || null} // Ensure no undefined access
                                            // onChange={(selectedOption) =>
                                            //     handleInputChange({ target: { name: "gender", value: selectedOption.value } })
                                            // }
                                            // value={genderOptions.find(option => option.value === form.getFieldValue("gender")) || null}
                                            // onChange={(selectedOption) => form.setFieldsValue({ gender: selectedOption })}
                                            value={formEdit.getFieldValue("gender")}  // Directly use form.getFieldValue("gender") to get the value
                                            onChange={(selectedValue) => formEdit.setFieldsValue({ gender: selectedValue })}  // Update form value on change
                                            isSearchable
                                        />
                                    </Form.Item>
                                </Col>

                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label className="required-label">Date of Birth</label>
                                    <Form.Item
                                        name="dateOfBirth"
                                        rules={[{ required: true, message: "Date of Birth is required!" }]}
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder="Date of Birth"
                                            value={formEdit.dateOfBirth ? (formEdit.dateOfBirth) : null}
                                            onClick={handleEditDateOfBirthDatePickerClick}
                                            onChange={(date) => handleEditDateChange("dateOfBirth", date)}
                                        // defaultPickerValue={moment().subtract(20, 'years')}
                                        // disabledDate={(current) => current && current > moment().endOf('day')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label >Phone Number</label>
                                    <Form.Item
                                        name="phoneNumber"
                                        rules={[
                                            { required: true, message: "Phone number is required!" },
                                            { pattern: validationRegexMobileNo, message: "Please enter a valid 10-digit phone number!" }
                                        ]}
                                    >
                                        <TextInputField
                                            name="phoneNumber"
                                            value={editFormData.phoneNumber}
                                            placeholder="Phone Number"
                                            feedbackMessage="Please enter a valid phone number."
                                            onChange={handleEditInputChange}
                                        />
                                    </Form.Item>
                                </Col>


                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label></label>Previous Experience (Months)
                                    <Form.Item
                                        name="previousExperience"
                                    // rules={[
                                    //     { required: true, message: "Previous Experience is required!" },
                                    //     { pattern: validationRegexMobileNo, message: "Please enter a previous Experience" }
                                    // ]}
                                    >
                                        <TextInputField
                                            name="previousExperience"
                                            value={editFormData.previousExperience}
                                            placeholder="Previous Experience (Months)"
                                            feedbackMessage="Please input the current branch."
                                            // validationRegex={validationRegexOnlyAlpha}
                                            onChange={(e) => handleEditInputChange(e, "previousExperience")}
                                        />
                                    </Form.Item>
                                    {/* {touched.previousExperience && errors.previousExperience && (
                                        <div className="createClientErrors">{errors.previousExperience}</div>
                                    )} */}
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label>Highest Education Qualification</label>
                                    <Form.Item
                                        name="highestEducationQualification"
                                    // rules={[
                                    //     { required: true, message: "Highest Educatio nQualificatio is required!" },
                                    //     { pattern: validationRegexOnlyAlpha, message: "Please enter a highest education qualification!" }
                                    // ]}
                                    >
                                        <TextInputField
                                            name="highestEducationQualification"
                                            value={editFormData.highestEducationQualification}
                                            placeholder="Highest Education Qualification"
                                            feedbackMessage="Please input the current branch."
                                            // validationRegex={validationRegexOnlyAlpha}
                                            onChange={handleEditInputChange}
                                        />
                                    </Form.Item>

                                </Col>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label>Previous Employer</label>
                                    <Form.Item
                                        name="previousEmployer"
                                    // rules={[
                                    //     { required: true, message: "Previous employer is required!" },
                                    //     { pattern: validationRegexOnlyAlpha, message: "Please enter a previous employer!" }
                                    // ]}
                                    >
                                        <TextInputField
                                            name="previousEmployer"
                                            value={editFormData.previousEmployer}
                                            placeholder="Previous Employer"
                                            feedbackMessage="Please input the current branch."
                                            validationRegex={validationRegexAlphaNumeric}
                                            onChange={handleEditInputChange}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div style={{ display: "flex", justifyContent: "end" }}>
                                {/* <button
                                    className="global-admin-bulk-upload-button"
                                    onClick={showModalForUpload}
                                    disabled={!selectedCompany || !selectedRole} // Disable if either is not selected
                                    style={{
                                        opacity: (!selectedCompany || !selectedRole) ? 0.5 : 1, // Visually indicate disable state
                                        cursor: (!selectedCompany || !selectedRole) ? "not-allowed" : "pointer"
                                    }}
                                >
                                    <img src={BulkUpload} alt="Add User" style={{ marginRight: "4px", width: "15px", height: "15px" }} />
                                    Bulk Upload
                                    <img src={BulkUploadArrow} alt="Add User" style={{ marginLeft: "4px", width: "15px", height: "15px" }} />
                                </button> */}

                                <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                                    <button className="nextButton" onClick={EdithandleNext}>Next</button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <Form form={formEdit}
                                layout="vertical"
                                // initialValues={{ formDataList }}
                                onFinish={handleEditSubmit}
                                onFinishFailed={(errorInfo) => {
                                    console.log("Validation Failed:", errorInfo);

                                    if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
                                        const fieldNames = errorInfo.errorFields.map(field => field.name[0]).join(', ');
                                        message.error(`Please complete these required fields: ${fieldNames}`);
                                    }
                                }}>

                                <div style={{ borderBottom: "1px solid #ccc", paddingBottom: "20px", marginBottom: "20px" }}>
                                    <Row gutter={[16, 16]}>
                                        <Col sm={12} xs={23} md={11} lg={12}>
                                            <label className="required-label">Employment Type</label>

                                            <Form.Item
                                                name={"employeeType"}
                                                rules={[
                                                    { required: true, message: "This field is required!" },
                                                    // { pattern: validationRegexOnlyAlpha, message: "Only alphabets are allowed!" }
                                                ]}
                                            >
                                                {/* <TextInputField
                                                    placeholder="Full-Time/Consultant"
                                                    feedbackMessage="Please input the full-time/ consultant."
                                                    value={form.getFieldValue('employeeType') || ''}
                                                    onChange={(e) => {
                                                        handleInputChange(e, "employeeType",);
                                                        // form.setFieldsValue({ formDataList }); // Ensure form updates
                                                    }}

                                                /> */}

                                                <Select
                                                    options={employementOptions}
                                                    placeholder="Select Employment Type"
                                                    value={formEdit.employeeType || null} // Ensure valid value
                                                    onChange={(selectedOption) => {
                                                        setEditFormData({ ...editFormData, employeeType: selectedOption })
                                                    }}
                                                    isSearchable
                                                />

                                            </Form.Item>
                                        </Col>

                                        <Col sm={12} xs={23} md={11} lg={12}>
                                            <label className="required-label">Date of Joining</label>

                                            <Form.Item
                                                name={"joiningDate"}
                                                rules={[{ required: true, message: "Date of Joining is required!" }]}

                                            >
                                                <DatePicker
                                                    showToday={true}
                                                    style={{ width: "100%" }}
                                                    placeholder="Date of Joining"
                                                    onClick={handleEditJoiningDatePickerClick}
                                                    value={formEdit.getFieldValue('joiningDate') || null}
                                                    onChange={(date) => handleListDateChange("joiningDate", date)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]}>
                                        <Col sm={12} xs={23} md={11} lg={12}>
                                            <label className="required-label">Current Designation</label>

                                            <Form.Item
                                                name={"designation"}
                                                rules={[{ required: true, message: "Designation is required!" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        // Safely access the label and ensure it's a string
                                                        const label = option.label || ""; // Default to empty string if label is undefined
                                                        return label.toLowerCase().includes(input.toLowerCase());
                                                    }}
                                                    options={designationOptions}
                                                    placeholder="Current Designation"
                                                    value={formEdit.getFieldValue('designation') || null}
                                                    // isDisabled={!selectedRole}
                                                    // isLoading={designationLoading}
                                                    onChange={handleEditDesignationChange}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col sm={12} xs={23} md={11} lg={12}>
                                            <label className="required-label">Current Branch</label>

                                            <Form.Item
                                                name={"branch"}
                                                rules={[
                                                    { required: true, message: "Branch is required!" },
                                                    {
                                                        pattern: validationRegexOnlyAlpha,
                                                        message: "Only alphabets are allowed!",
                                                    },
                                                ]}
                                            >
                                                <TextInputField
                                                    value={formEdit.getFieldValue('branch') || ''}
                                                    placeholder="Current Branch"
                                                    feedbackMessage="Please input the current branch."
                                                    onChange={(e) => handleEditInputChange(e, "currentBranch")}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]}>
                                        <Col sm={12} xs={23} md={11} lg={12}>
                                            <label className="required-label">State</label>

                                            <Form.Item
                                                name={"stateName"}
                                                rules={[{ required: true, message: "State is required!" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        // Safely access the label and ensure it's a string
                                                        const label = option.label || ""; // Default to empty string if label is undefined
                                                        return label.toLowerCase().includes(input.toLowerCase());
                                                    }}
                                                    options={stateOptions}
                                                    value={formEdit.getFieldValue('stateName') || ''}
                                                    handleSubmit placeholder="Select State"
                                                    isLoading={stateLoading}
                                                    onChange={handleEditStateChange}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col sm={12} xs={23} md={11} lg={12}>
                                            <label className="required-label">City</label>

                                            <Form.Item
                                                name={"cityName"}
                                                rules={[{ required: true, message: "City is required!" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        // Safely access the label and ensure it's a string
                                                        const label = option.label || ""; // Default to empty string if label is undefined
                                                        return label.toLowerCase().includes(input.toLowerCase());
                                                    }}
                                                    options={cityOptions}
                                                    value={formEdit.getFieldValue('cityName') || ''}
                                                    placeholder="Select City"
                                                    isDisabled={!selectedState}
                                                    isLoading={citiesLoading}
                                                    onChange={handleEditCityChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]}>
                                        <Col sm={12} xs={23} md={11} lg={12}>
                                            <label className="required-label">Zone</label>
                                            <Form.Item
                                                name={"zone"}

                                                rules={[{ required: true, message: "Zone is required!" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        // Safely access the label and ensure it's a string
                                                        const label = option.label || ""; // Default to empty string if label is undefined
                                                        return label.toLowerCase().includes(input.toLowerCase());
                                                    }}
                                                    options={userTypeOptions}
                                                    placeholder="Zone"
                                                    value={formEdit.getFieldValue('zone') || ''}
                                                    onChange={(value) => {
                                                        handleEditZoneChange(value); // Pass value directly, no need to wrap it in an object
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col sm={12} xs={23} md={11} lg={12}>
                                            <label>Employee ID of Manager</label>
                                            <Form.Item
                                                name={"employeeIds"}
                                                rules={[
                                                    {
                                                        pattern: validationRegexAlphaNumeric,
                                                        message: "Only alphabets are allowed!",
                                                    },
                                                ]}
                                            >
                                                <TextInputField
                                                    value={formEdit.getFieldValue('employeeIds')}
                                                    placeholder="Employee ID Of Manager"
                                                    feedbackMessage="Please input the employee ID of the manager."
                                                    // onChange={(e) => handleInputChange(e, "employeeIds")}
                                                    onChange={handleEditEmployeeIdsChange}

                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]}>
                                        <Col sm={12} xs={23} md={11} lg={12}>
                                            <label>Date of Reporting</label>
                                            <Form.Item
                                                name={"reportingDate"}
                                            >
                                                <DatePicker
                                                    style={{ width: "100%" }}
                                                    placeholder="Date of Reporting"
                                                    onClick={handleEditReportingDatePickerClick}
                                                    value={formEdit.getFieldValue('reportingDate') || ''}
                                                    onChange={(date) => handleListDateChange("reportingDate", date)}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col sm={12} xs={23} md={11} lg={12}>
                                            <label >Name of Manager</label>
                                            <Form.Item
                                            // name={"employeeName"}
                                            // rules={[
                                            //     {
                                            //         pattern: validationRegexOnlyAlpha,
                                            //         message: "Only alphabets are allowed!",
                                            //     },
                                            // ]}
                                            >
                                                <TextInputField
                                                    // value={form.getFieldValue('employeeName')}
                                                    placeholder="Name of Manager"
                                                    feedbackMessage="Please input the name of the manager."
                                                    // onChange={(e) => handleInputChange(e, "employeeName")}
                                                    value={managerName}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {/* {index > 0 && (
                                            <button
                                                className="user-management-cancel-button"
                                                onClick={() => removeUserFields(index)}
                                                style={{ marginTop: "10px", backgroundColor: "#d32f2f", color: "white", display: "flex", justifySelf: "end", alignItems: "center" }}
                                            >
                                                Remove User
                                            </button>
                                        )} */}
                                </div>


                                <div className="global-admin-modal-footer" >



                                    {/* <button className="global-admin-add-user-button" onClick={addUserFields}>
                                        Add User
                                    </button> */}

                                    <button className="user-management-cancel-button" onClick={handleEditCancel}>
                                        Cancel
                                    </button>
                                    <button
                                        type="submit" // Prevent form submission
                                        className="global-admin-apply-button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const allFields = Object.keys(formEdit.getFieldsValue());

                                            // Exclude "formDataList" from validation
                                            const fieldsToValidate = allFields.filter(field => field !== "formDataList");
                                            formEdit.validateFields(fieldsToValidate)
                                                .then((values) => {
                                                    delete values.formDataList;
                                                    handleEditSubmit()
                                                })
                                                .catch((errorInfo) => {
                                                    console.log("PPP", formEdit.getFieldsValue())
                                                });
                                            // handleEditSubmit();
                                        }}
                                    // onClick={(e) => {
                                    //     e.preventDefault(); // Prevent default button behavior
                                    //     handleEditSubmit(); // Directly call the submit function
                                    // }}

                                    >
                                        Save
                                    </button>


                                </div>
                            </Form>
                        </>
                    )}
                </Form>
            </Modal>

            <Modal
                title="Bulk Lead Upload"
                open={isModalVisibleForUpload}
                onCancel={handleCancelUpload}
                footer={null}
                width={600}
                centered
                destroyOnClose
            >
                {/* Clickable div */}
                <div
                    style={{
                        padding: "20px",
                        textAlign: "center",
                        border: "2px dashed #ccc",
                        borderRadius: "8px",
                        cursor: "pointer"
                    }}
                    onClick={() => document.getElementById("fileUpload").click()} // Opens file input
                >
                    <div>
                        <img src={BulkUploadFiles} alt="Upload Icon" />
                    </div>

                    <p style={{ fontSize: "16px", fontFamily: "Inter" }}>
                        Drag & Drop files or <span style={{ color: "#c81e1e" }}>Browse</span>
                    </p>
                    <p style={{ color: "#666", fontSize: "12px", marginTop: "10px" }}>
                        Supported formats: xlsx
                    </p>

                    {/* Hidden file input */}
                    <input
                        type="file"
                        id="fileUpload"
                        style={{ display: "none" }}
                        accept=".xlsx"
                        onChange={(e) => setFile(e.target.files[0])} // Store file on selection
                    />
                </div>

                {/* Show selected file name */}
                {file && (
                    <p style={{ textAlign: "center", marginTop: "10px", fontSize: "14px" }}>
                        File Selected: {file.name}
                    </p>
                )}

                {/* Upload button */}
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <button
                        onClick={handleUpload}
                        disabled={!file}
                        style={{
                            background: "#FAC9CA",
                            color: "#9B9C9C",
                            border: "none",
                            padding: "8px 20px",
                            borderRadius: "4px",
                            transition: "background 0.3s ease-in-out",
                            cursor: file ? "pointer" : "not-allowed",
                            fontWeight: "500",
                            background: file ? "#C12328" : "#FAC9CA", // Red when active, light pink when disabled
                            color: file ? "#FFF" : "#9B9C9C",
                        }}
                    >
                        Upload File
                    </button>
                </div>
            </Modal>

            <Modal
                title="Confirm Deletion"
                open={isModalOpen}
                onCancel={handleCancelDelete}
                footer={[
                    <button key="cancel" onClick={() => setIsModalOpen(false)} className="modal-button cancel-button">
                        No
                    </button>,
                    <button key="confirm" onClick={handleConfirmDelete} className="modal-button confirm-button">
                        Yes
                    </button>,
                ]}
            >
                <p>Are you sure you want to delete these users?</p>
            </Modal>

            {/* <Card> */}

            {/* <div style={{ display: "flex", justifyContent: "space-between", fontSize: "18px", fontFamily: "Inter" }}>
                <span>
                    Since there is a vacancy in both <span style={{ color: "#d32f2f" }}>Supermanager</span> and <span style={{ color: "#d32f2f" }}>Manager</span> positions, it is required to either <br />assign or add new users to confirm the respective positions.
                </span>
                <button className="assignNow" style={{ marginLeft: "10px" }} onClick={handleOpen}>
                    Assign Now
                </button>
            </div> */}
            {/* </Card> */}


            <Modal open={open} onCancel={handleClose} centered
                footer={null}>
                <h3>Assign Managerial Positions</h3>

                <div style={{ marginBottom: "20px", padding: "10px", border: "1px solid #ddd", borderRadius: "8px" }}>
                    <h4>Supermanager</h4>
                    <label>
                        <input type="radio" name="supermanager" defaultChecked /> Assign From Existing Users
                    </label>
                    <SearchableDropdown type="Select" style={{ width: "100%", marginTop: "5px" }}>
                        <option>Select User</option>
                    </SearchableDropdown>
                    <br />
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                        <label style={{ display: "flex", alignItems: "center", gap: "5px", }}>
                            <input type="radio" name="supermanager" /> Add New Supermanager
                        </label>
                        <button style={{ backgroundColor: "#f8d7da", color: "#d32f2f", border: "none", padding: "5px 10px", borderRadius: "5px", marginLeft: "10px" }}>
                            + Add New
                        </button>
                    </div>

                </div>

                <div style={{ marginBottom: "20px", padding: "10px", border: "1px solid #ddd", borderRadius: "8px" }}>
                    <h4>Manager</h4>
                    <label>
                        <input type="radio" name="manager" defaultChecked /> Assign From Existing Users
                    </label>
                    <SearchableDropdown type="Select" style={{ width: "100%", marginTop: "5px" }}>
                        <option>Select User</option>
                    </SearchableDropdown>
                    <br />
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", display: "flex", justifyContent: "space-between", marginTop: "10px" }}>

                        <label>
                            <input type="radio" name="manager" /> Add New Manager
                        </label>
                        <button style={{ backgroundColor: "#f8d7da", color: "#d32f2f", border: "none", padding: "5px 10px", borderRadius: "5px", marginLeft: "10px" }}>
                            + Add New
                        </button>
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    <button className="assignManagerailPosition" onClick={handleClose}>
                        Save
                    </button>
                </div>

            </Modal>

        </div >
    );
};

export default UserManagement;