import React, { useRef, useState, useEffect, useCallback, } from "react";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import { FaUserShield, FaUserTie, FaUser, FaUserEdit } from "react-icons/fa";
import Select from "react-select";
import "./globalAdmin.css";
import { Layout, Modal, Button, Col, Row, Upload, message, Form } from "antd";
import { FaTrash, FaSave } from "react-icons/fa";
import SearchIcon from "../Images/global-admin-search-icon.png";
import CreateIconPlus from "../Images/createplus_icon.png";
import Globe from "../Images/Globe-icon.png";
import Admin from "../Images/global-admin-dropdown-admin-icon.png";
import User from "../Images/global-admin-dropdown-user-icon.png";
import { passwordRegex, validationRegexAmount, validationRegexOnlyAlpha } from "../regex/regex";
import TextInputField from "../reusableField/TextInputField2";
import CreateClientImage from "../Images/add-new-profile-photo.png";
import DeleteIcon from "../Images/global-admin-delete-icon.png"
import ApplyIcon from "../Images/global-admin-apply-icon.png"
import CancelIcon from "../Images/global-admin-cancel.png"
import DeleteIconRedColor from "../Images/global-admin-delete-icon-red-color.png"
import { Account_Form_Query, Role_Account_Query, Get_Lense_Account_Projects, Upload_Document, update_Account, Role_Management, Get_All_Job_Role, List_All_Super_Admins, Add_update_super_admins } from "../services/userAPI"; // Import your mutation
import { useNavigate } from "react-router-dom";
import { debounce } from 'lodash';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { Link, MemoryRouter, Route, Routes, useLocation } from 'react-router';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import defaultProfile from "../Images/profile-user.png";
import { CircularProgress } from "@mui/material";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./utils/imageUtils";
import ImageUploadCrop from './imageUploadCrop';
const { Content } = Layout;

export default function Globaladmin() {
    const navigate = useNavigate();
    const usersData = [
        { name: "Venkata Sumanth", },
        { name: "Venkata Sumanth", },
        { name: "Venkata Sumanth", },
        { name: "Venkata Sumanth", },
    ];
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const imageRef = useRef(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [users, setUsers] = useState(usersData);
    const [clientImage, setClientImage] = useState(null);
    const [errors, setErrors] = useState({})
    const [uploadedFilename, setUploadedFilename] = useState(null);
    const [isPickingColor, setIsPickingColor] = useState(false);
    const urlRegex = /^(https:\/\/|www\.)[a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]+)*(\.[a-zA-Z]{2,})(:[0-9]+)?(\/[a-zA-Z0-9\-_#=&\/?]*)?$/;
    const [isFormValid, setIsFormValid] = useState(false);
    const [touched, setTouched] = useState({})
    const [maskedPassword, setMaskedPassword] = useState()
    const [actualPassword, setActualPassword] = useState('');
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [resetTrigger, setResetTrigger] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        emailId: "",
        password: "",
        contactNumber: "",
        clientImage: "",
        imageUrl: "",
        mainLogoUrl: "",
        id: "",
        userId: null,
    });
    const [page, setPage] = useState(1);
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = "Enter users name.";
        }

        // if (!emailRegex.test(formData.emailId)) {
        //     newErrors.emailId = 'Please enter a valid company URL.';
        // }

        if (!formData.emailId) {
            newErrors.emailId = "Enter email-id.";
        } else if (!emailRegex.test(formData.emailId)) {  // Check email format
            newErrors.emailId = "Please enter a valid email address.";
        }

        // const hasExistingImage = selectedUser?.profilePicture?.trim() || formData.imageUrl?.trim();
        // const hasNewImage = clientImage && isImageChanged;

        // if (!hasExistingImage && !hasNewImage) {
        //     newErrors.clientImage = "Please upload an image";
        //     isValid = false;
        // }

        const hasExistingImage = selectedUser?.profilePicture?.trim() || formData.imageUrl?.trim();
        const hasNewImage = clientImage?.trim() || uploadedFilename?.trim(); // Ensure image is checked properly

        if (!hasExistingImage && !hasNewImage) {
            newErrors.clientImage = "Please upload an image";
            isValid = false;
        }

        if (!formData.password) {
            newErrors.password = "Enter password.";
        }

        if (!formData.contactNumber) {
            newErrors.contactNumber = "Enter contact number.";
        }

        // if ((formData.darts || formData.clan) && !formData.numberLogin && !formData.emailLogin) {
        //     newErrors.loginMethod = "Select mobile or email-id.";
        // }

        // if (!formData.emailLogin && !formData.numberLogin && !formData.lense) {
        //     newErrors.projectAccess = "At least one project access option should be selected.";
        // }

        setErrors(newErrors);
        // return isValid;
        return Object.keys(newErrors).length === 0;
    };


    const handleImageUpload = async ({ file }) => {
        const reader = new FileReader();
        reader.onload = () => {
            // setImageSrc(reader.result);
            // setCropModalVisible(true); // Open crop modal
        };
        reader.readAsDataURL(file);
    };

    // const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    //     setCroppedAreaPixels(croppedAreaPixels);
    // }, []);

    // const getCroppedImage = async () => {
    //     try {
    //         const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    //         setFinalCroppedImage(croppedImage);
    //         setCropModalVisible(false);
    //     } catch (error) {
    //         console.error("Crop failed:", error);
    //     }
    // };

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [formData]);

    const [addUpdateSuperAdmin, { addUpdateSuperAdminloading, addUpdateSuperAdminerror }] = useMutation(Add_update_super_admins);
    const [selectedUser, setSelectedUser] = useState(null);
    useEffect(() => {
        if (selectedUser) {
            setFormData({
                id: selectedUser.id, // Ensure ID is populated when editing
                name: selectedUser.firstName,
                emailId: selectedUser.email,
                password: "", // Password might not be returned from API
                contactNumber: selectedUser.contactNumber,
            });
        }
    }, [selectedUser]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Mark all fields as touched to trigger validation messages
        setTouched({
            name: true,
            emailId: true,
            password: true,
            contactNumber: true,
            clientImage: true,
        });

        // Validate form
        if (!validateForm()) {
            message.error("Please fix the errors before submitting.");
            return;
        }

        const userId = selectedUser?.id ?? formData.userId ?? null;
        console.log("userId", userId)

        const finalImageUrl = isImageChanged ?
            (uploadedFilename || defaultProfile) :
            (formData.imageUrl || selectedUser?.profilePicture || defaultProfile);

        try {
            const { data } = await addUpdateSuperAdmin({
                variables: {
                    updatedUser: {
                        userId: selectedUser?.id ?? formData.userId ?? null,
                        firstName: formData.name,
                        surname: "",
                        email: formData.emailId,
                        password: formData.password,
                        contactNumber: formData.contactNumber,
                        profilePicture: uploadedFilename,
                        userId: userId,
                        userStatus: null,
                        userType: null,
                    }
                }
            });

            if (data?.addUpdateSuperAdmins?.success) {
                message.success(userId ? "User updated successfully!" : "User added successfully!");
                if (formData.userId) {
                    editHandleCancel();

                } else {
                    handleCancel();
                }

                setTimeout(() => {
                    setFormData({
                        name: "",
                        emailId: "",
                        password: "",
                        contactNumber: "",
                        userId: null,
                        imageUrl: "",
                        mainLogoUrl: "",
                        userType: null,
                        clientImage:"",
                    });

                    setTouched({});
                    setErrors({});
                    setClientImage(null);
                    setIsImageChanged(false);
                    setIsModalVisible(false);
                    setIsImageChanged(false);
                    setUploadedFilename(false);
                    setIsImageChanged(false);
                    handleCancel();
                    setResetTrigger((prev) => !prev);

                    refetch(); // Refresh data

                    // Navigate to global admin AFTER modal closes
                    navigate("/global-admin");
                });
            } else {
                message.error(data?.addUpdateSuperAdmins?.message || "Something went wrong!");
            }
        } catch (error) {
            const errorMessage = error.graphQLErrors?.[0]?.message ||
                "Something went wrong!";
            message.error(errorMessage);
        }
    };

    const handleImageClick = (e) => {
        if (!isPickingColor) return;
        e.preventDefault();
        e.stopPropagation();

        if (selectedColor) {
            setFormData(prevData => ({
                ...prevData,
                brandColor: selectedColor.hex
            }));
        }
        setIsPickingColor(false);
        if (imageRef.current) {
            imageRef.current.classList.remove('color-picking-cursor');
            imageRef.current.classList.remove('active-picker-border');
        }
    };

    const [uploadDocument] = useMutation(Upload_Document, {
        onCompleted: (data) => {
            if (data.uploadDocument.success) {
                const filename = data.uploadDocument?.data?.data?.filename;
                const imageUrl = data.uploadDocument?.data?.data?.url;
                setUploadedFilename(filename);

                setFormData((prevData) => ({
                    ...prevData,
                    // imageUrl: imageUrl || require("/Images/profile-user.png"),
                    imageUrl: imageUrl && imageUrl.trim() !== "" ? imageUrl : defaultProfile,
                    // filename: filename,
                }));
                message.success("Image uploaded successfully!");
            } else {
                message.error("Failed to upload image: " + data.uploadDocument.message);
            }
        },
        onError: (error) => {
            message.error("Upload failed: " + error.message);
        },
    });

    const handleImageChange = ({ file }) => {

        // const reader = new FileReader();
        // reader.onload = (e) => {
        //     setClientImage(e.target.result);
        //     // drawImageOnCanvas(e.target.result);
        // };
        // reader.readAsDataURL(file);


        // if (file) {
        //     const reader = new FileReader();
        //     reader.onload = (e) => {
        //         setClientImage(e.target.result);
        //     };
        //     reader.readAsDataURL(file);
        // }

        if (!file) {
            message.error("Invalid file object.");
            return;
        }

        const allowedFormats = ["image/jpeg", "image/png"];
        const actualFile = file.originFileObj || file;

        if (!actualFile) {
            message.error("No valid file detected.");
            return;
        }

        if (!allowedFormats.includes(actualFile.type)) {
            message.error("Invalid file format. Only JPEG and PNG are allowed.");
            return;
        }

        try {

            const reader = new FileReader();
            reader.onload = (e) => {
                setClientImage(e.target.result);
                setIsImageChanged(true);
            };
            reader.readAsDataURL(actualFile);

            // Create a preview of the image
            // const imageUrl = URL.createObjectURL(actualFile);
            // setClientImage(imageUrl);

            // setErrors((prevErrors) => ({
            //     ...prevErrors,
            //     clientImage: "", // Clear error for clientImage
            // }));

            // Upload the actual file
            uploadDocument({
                variables: {
                    s3folder: "COMPANY_LOGO",
                    document: actualFile, // Pass the raw file object here
                },
                context: {
                    useMultipart: true, // Ensure the Apollo Client supports multipart file uploads
                },
            });
            setErrors((prevErrors) => ({
                ...prevErrors,
                clientImage: "",
            }));
        } catch (error) {
            console.error("Error handling the file:", error);
            message.error("File upload failed.");
        }
    };

    const handleRoleChange = (selectedOption, userId) => {
        setUsers(prevUsers =>
            prevUsers.map(user =>
                user.id === userId ? { ...user, userType: selectedOption.value } : user // Use `userType` instead of `role`
            )
        );
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
        setFormData({
            name: "",
            emailId: "",
            password: "",
            contactNumber: "",
            imageUrl: "",
        }); // Reset form fields
    
        setClientImage(""); // Reset uploaded image
        setErrors({}); // Clear errors
        // setIsModalVisible(true); 
    };

    const handleCancel = () => {
        setFormData({
            name: "",
            emailId: "",
            password: "",
            contactNumber: "",
            imageUrl: "",
            mainLogoUrl: ""
        });
        setClientImage(null);
        setTouched({});
        setErrors({});
        setIsModalVisible(false);
        setIsImageChanged(false);
        setUploadedFilename(null);
        setIsImageChanged(false);
        setSelectedUser(false)
        setResetTrigger((prev) => !prev);
    };


    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const editShowModal = (user) => {
        const newFormData = {
            userId: user?.id || "",
            name: user?.firstName || "",
            emailId: user?.email || "",
            password: user?.password || "",
            contactNumber: user?.contactNumber || "",
            imageUrl: user?.profilePicture || defaultProfile,
            // imageUrl:user?.profilePicture && user.profilePicture.trim() !== "" ? user.profilePicture : defaultProfile,
        };

        setFormData(newFormData);
        setIsImageChanged(false);
        setClientImage(user?.profilePicture || defaultProfile); // Set current image
        setUploadedFilename(null);

        if (user?.profilePicture?.trim()) {
            setErrors(prev => ({
                ...prev,
                clientImage: "",
                imageUrl: "",
            }));
            // setClientImage(user.profilePicture);
        }

        setSelectedUser(user)
        setIsEditModalVisible(true);
    };

    useEffect(() => {
        if (selectedUser) {
            setFormData({
                userId: selectedUser.id,
                name: selectedUser.firstName,
                emailId: selectedUser.email,
                password: selectedUser.password,
                contactNumber: selectedUser.contactNumber,
                imageUrl: selectedUser.profilePicture,
            });
        }
    }, [selectedUser]);  // Runs whenever selectedUser changes


    const editHandleCancel = () => {
        setFormData({
            name: "",
            emailId: "",
            password: "",
            contactNumber: "",
            imageUrl: "",
            userId: null,
        });
        setClientImage(null);
        setTouched({});
        setErrors({});
        setSelectedUser(false)
        setIsEditModalVisible(false);
    };

    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

    // Function to show delete confirmation modal
    const showDeleteModal = (user) => {
        setSelectedUser(user);
        setDeleteModalVisible(true);
    };

    // Function to handle delete action
    const handleDeleteUser = async (user) => {
        try {
            const newStatus = user.userStatus === 1 ? 0 : 1; // Toggle status

            await addUpdateSuperAdmin({
                variables: {
                    updatedUser: {
                        userId: user.id,
                        userStatus: newStatus,
                        firstName: null,
                        surname: null,
                        email: null,
                        password: null,
                        contactNumber: null,
                        profilePicture: null,
                        userType: null
                    },
                },
            });

            message.success(`User ${newStatus === 0 ? "deactivated" : "reactivated"} successfully`);

            // Refresh user list
            refetch();

            setDeleteModalVisible(false);
            setIsEditModalVisible(false)
        } catch (error) {
            message.error("Failed to update user status");
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setTouched((prevTouched) => ({
            ...prevTouched,
            [name]: true,
        }));

    };

    const roleOptions = [

        {
            value: "global_admin",
            label: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={Globe} style={{ width: "15%", }} />&nbsp;
                    Global Admin
                </div>
            ),
            color: "#F8D7DA",
        },
        {
            value: "Admin",
            label: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={Admin} style={{ width: "25%" }} />&nbsp;
                    Admin
                </div>
            ),
            color: "#FAD7A0",
        },
        {
            value: "User",
            label: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={User} style={{ width: "30%" }} />&nbsp;
                    User
                </div>
            ),
            color: "#D5F5E3",
        },

    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: "40px",
            minHeight: "40px",
            width: "170px",
            minWidth: "170px",
            maxWidth: "170px",
            borderRadius: "20px",
            border: "none",
            boxShadow: "none",
            "&:hover": {
                border: "none"
            }
        }),
        option: (provided, state) => ({
            ...provided,
            height: "40px",
            width: "170px",
            minWidth: "170px",
            maxWidth: "170px",
            display: "flex",
            alignItems: "center",
            backgroundColor: state.data.color,
            color: "black",
            padding: "8px 12px",
            cursor: "pointer",
            border: "none",
            margin: "0",
            marginLeft: "20%",
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden", // Hide overflow content
            textOverflow: "ellipsis"
        }),
        menu: (provided) => ({
            ...provided,
            width: "0px",
            minWidth: "0px",
            maxWidth: "0px",
            animation: "fadeIn 0.4s ease",
            marginTop: "4px",
            padding: "0",
            borderRadius: "100%",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            position: "absolute",
            left: "0",
            zIndex: -5,
            // marginTop: "-2%",
        }),
        menuList: (provided) => ({
            ...provided,
            padding: "4px",
            width: "200px",
            // borderRadius: "15%",
            marginTop: "-10px",
            "&::-webkit-scrollbar": {
                width: "4px"
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "2px"
            }
        }),
        singleValue: (provided, state) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            width: "180px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "40px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: "40px",
            width: "180px",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "8px"
        })
    };

    // const resetImageUpload = () => {
    //     setImageSrc(null);
    //     setCroppedAreaPixels(null);
    //     setCrop({ x: 0, y: 0 });
    //     setZoom(1);

    //     // Reset form data
    //     setClientImage(null);
    //     setUploadedFilename(null);
    //     setIsImageChanged(false);
    //     setFormData({
    //         name: "",
    //         emailId: "",
    //         password: "",
    //         contactNumber: "",
    //         userId: null,
    //         imageUrl: "",
    //         mainLogoUrl: "",
    //         userType: null,
    //     });
    //     setErrors({});
    //     setTouched({});
    // };


    const getCustomStyles = (userType) => {
        const selectedOption = roleOptions.find((option) => option.value === userType);
        return {
            control: (provided) => ({
                ...provided,
                height: "40px",
                minHeight: "40px",
                width: "180px",
                minWidth: "180px",
                maxWidth: "180px",
                borderRadius: "20px",
                backgroundColor: selectedOption ? selectedOption.color : "#fff",
                border: "none",
                boxShadow: "none",
                "&:hover": {
                    border: "none"
                },
            }),
            option: (provided, { data, isSelected }) => ({
                ...provided,
                height: data.value === "Global Admin" ? "50px" : "40px",
                width: "175px",
                minWidth: "175px",
                maxWidth: "175px",
                display: "flex",
                alignItems: "center",
                backgroundColor: isSelected ? data.color : "#fff",
                color: "#000",
                "&:hover": {
                    backgroundColor: data.color ? `${data.color}99` : "#eee",
                },
                cursor: "pointer",
                border: "none",
                margin: "0",
                borderRadius: "3%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontSize: "12px",
                textOverflow: "ellipsis",
                border: "1px solid #fff",
            }),
            singleValue: (provided) => ({
                ...provided,
                color: "#000",
            }),
        }
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setActualPassword(value);
        setMaskedPassword("*".repeat(value.length));
        setFormData({ ...formData, password: value });
    };

    const [search, setSearch] = useState("");
    const [cursor, setCursor] = useState(null);
    const [fetchAdmins, { loading, error, data, refetch }] = useLazyQuery(List_All_Super_Admins, {
        fetchPolicy: 'network-only', // Always fetch fresh data
    });
    const [filteredData, setFilteredData] = useState([]);
    const limit = 5;


    const debouncedFetchAdmins = debounce((searchString) => {


        fetchAdmins({
            variables: {
                searchString: searchString || null,
                pagingCriteria: {
                    limit,
                    cursor: cursor,
                    sortByField: "created_at",
                    sortOrder: "desc",
                },
            },
        });
    },);

    useEffect(() => {
        if (!search) {
            fetchAdmins({
                variables: {
                    searchString: null,
                    pagingCriteria: {
                        limit,
                        cursor: null,
                        sortByField: "created_at",
                        sortOrder: "desc",
                    },
                },
            });
        } else {
            debouncedFetchAdmins(search); // Trigger search-based API call
        }
    }, [search, fetchAdmins]); // Only trigger if search or fetchAdmins changes

    useEffect(() => {
        if (data?.listAllSuperadmins?.data) {
            const filtered = data.listAllSuperadmins.data.filter((user) => {
                const firstName = user.firstName?.toLowerCase() || ""; // Default to empty string
                const surname = user.surname?.toLowerCase() || ""; // Default to empty string
                return firstName.includes(search.toLowerCase()) || surname.includes(search.toLowerCase());
            });
            setFilteredData(filtered);
        }
    }, [search, data]);


    useEffect(() => {
        debouncedFetchAdmins(search); // Trigger search-based API call when search changes
    }, [search, cursor]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value); // Update search state
    };

    // const[currentPage,setcurrentPage]=useState(1);
    // const recordsPerPage=5;
    // const lastIndex=currentPage * recordsPerPage;
    // const firstIndex=lastIndex-recordsPerPage;
    // const records=data.slice(firstIndex,lastIndex);
    // const npage=Math.ceil(Data.length/recordsPerPage);
    // const numnbers=[...Array(npage + 1).keys()].slice(1);

    function handleNextPage() {
        if (data?.listAllSuperadmins?.pageMeta?.nextPage) {
            setCursor(data.listAllSuperadmins.pageMeta.nextPage);
            setPage(page + 1);
        } else {
            console.log("No next page available.");
        }
    }

    const handlePreviousPage = () => {
        if (data?.listAllSuperadmins?.pageMeta?.previousPage && page > 1) {
            setCursor(data.listAllSuperadmins.pageMeta.previousPage);
            setPage(page - 1);
        } else {
            console.log("No previous page available.");
        }
    };

    return (
        <Content className="content">
            <style>
                {`
                    @keyframes fadeIn {
                        from {
                            opacity: 0;
                            transform: translateY(-4px);
                        }
                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                `}
            </style>
            <div className="main-content">
                <div className="global-admin-header">
                    <div className="header-content">
                        <div className="search-container-global-admin relative">
                            <input
                                type="text"
                                placeholder="Search user..."
                                className="search-box"
                                value={search}
                                onChange={handleSearchChange}
                            />
                            {/* <img
                                    className="searchIconGlobalAdmin"
                                    src={SearchIcon}
                                    alt="Search"
                                /> */}
                        </div>
                        <button className="global-admin-addNew-button" onClick={showModal}>
                            <img src={CreateIconPlus} alt="Add" className="global-admin-CreateIcon" />
                            Add&nbsp;New
                        </button>
                    </div>
                </div>
                <h2 className="user-title">User Information</h2>
                <div className="user-list-container">
                    {loading ? ( // Show loading spinner if data is still being fetched
                        <div style={{ display: "flex", justifyContent: "center", padding: "20px", color: "#C12328" }}>
                            <CircularProgress style={{ color: "#C12328" }} />
                        </div>
                    ) : (
                        <Paper sx={{
                            width: "100%", overflow: "hidden",
                        }}>
                            <TableContainer>
                                <Table>
                                    {/* Table Header */}
                                    <TableHead style={{ backgroundColor: "#F3F4F6" }}>
                                        <TableRow >
                                            <TableCell sx={{ fontWeight: "bold", borderBottom: "1px solid #e5e7eb" }}>User Name</TableCell>
                                            <TableCell sx={{ fontWeight: "bold", borderBottom: "1px solid #e5e7eb" }}>User Type</TableCell>
                                            <TableCell sx={{ fontWeight: "bold", borderBottom: "1px solid #e5e7eb" }}>Status</TableCell>
                                            <TableCell sx={{ fontWeight: "bold", borderBottom: "1px solid #e5e7eb" }}>Edit User</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.listAllSuperadmins?.data?.map((user) => (
                                            <TableRow key={user.id}

                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: 'transparent !important'
                                                    },
                                                    '& td': {
                                                        // padding: '16px 16px 32px 16px',
                                                        // borderBottom: 'none',
                                                        backgroundColor: 'transparent !important',
                                                    },
                                                    // mb: 2
                                                }}
                                            >
                                                <TableCell
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                    }}
                                                >
                                                    <div className="user-avatar">
                                                        {/* <img src={user.profilePicture} F className="user-profile-image" /> */}
                                                        <img
                                                            src={user.profilePicture ? user.profilePicture : defaultProfile}
                                                            alt=" No Profile"
                                                            className="user-profile-image"
                                                        />
                                                    </div>
                                                    <span>{user.surname}{user.firstName}</span>
                                                </TableCell>

                                                <TableCell>
                                                    <Select
                                                        // options={roleOptions}
                                                        isSearchable={false}
                                                        value={roleOptions.find((option) => option.value === user.userType)}
                                                        onChange={(selectedOption) => handleRoleChange(selectedOption, user.id)}
                                                        className="user-role-dropdown"
                                                        styles={{ ...customStyles, ...getCustomStyles(user.userType) }}
                                                        menuPortalTarget={document.body}
                                                        menuShouldScrollIntoView={false}
                                                        menuShouldBlockScroll={true}
                                                        getOptionLabel={(e) => (
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                {e.label}
                                                            </div>
                                                        )}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    < strong style={{ color: user.userStatus === 1 ? "green" : "red" }}>
                                                        {user.userStatus === 1 ? " Active" : " Inactive"}
                                                    </strong>
                                                </TableCell>

                                                <TableCell>
                                                    <button className="global-admin-edit-button" onClick={() => editShowModal(user)}>
                                                        Edit
                                                        <FaUserEdit className="global-admin-icon" />
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )}
                    <div className="pagination-controls">
                        <span className="pagination-text">
                            {data?.listAllSuperadmins?.pageMeta?.totalCount > 0
                                ? `${(page - 1) * limit + 1} - ${Math.min(page * limit, data?.listAllSuperadmins?.pageMeta?.totalCount)} of ${data?.listAllSuperadmins?.pageMeta?.totalCount}`
                                : "No Data"}
                        </span>
                        <button
                            className="pagination-button"
                            onClick={handlePreviousPage}
                            disabled={page === 1}>
                            &lt;
                        </button>
                        <button
                            className="pagination-button"
                            onClick={handleNextPage}
                            disabled={!data?.listAllSuperadmins?.pageMeta?.hasNextPage}
                        >
                            &gt;
                        </button>
                    </div>
                </div>


                {/* Modal for Adding New User */}
                <Modal
                    title="Add New User"
                    open={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    centered
                    width={1000}
                // size="lg"
                >
                    <div className="add-user-container">
                        <Row className="user-form" style={{ display: "flex", alignItems: "center", marginLeft: "3%" }}>
                            {/* Left Side - Image */}
                            <Col span={4} style={{ flexShrink: 0 }}>
                                {/* <img src={CreateClientImage} alt="Profile Image"
                                            style={{ width: "120px", height: "120px", borderRadius: "50%", }}
                                        /> */}
                                <ImageUploadCrop
                                    onImageUpdate={(imageUrl, filename) => {
                                        setClientImage(imageUrl);
                                        setUploadedFilename(filename);
                                        setIsImageChanged(true);
                                        setFormData(prev => ({
                                            ...prev,
                                            imageUrl: imageUrl
                                        }));
                                        setErrors(prev => ({
                                            ...prev,
                                            clientImage: ""
                                        }));
                                    }}
                                    initialImage={clientImage || formData.imageUrl || formData.mainLogoUrl}
                                    resetTrigger={resetTrigger}
                                />
                                {touched.clientImage && errors.clientImage && (
                                    <div className="createClientErrors">{errors.clientImage}</div>
                                )}
                            </Col>

                            {/* Right Side - Input Fields */}
                            <Row gutter={[16, 16]}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label style={{ fontSize: "15px", color: "#1C1B1F" }}>User's Name</label>
                                    <TextInputField
                                        name="name"
                                        value={formData.name}
                                        placeholder="Enter User's Name"
                                        feedbackMessage="Please input the user name."
                                        validationRegex={validationRegexOnlyAlpha}
                                        onChange={handleInputChange}
                                    />
                                    {touched.name && errors.name && (
                                        <div className="createClientErrors">{errors.name}</div>
                                    )}
                                </Col>
                                <Col sm={12} xs={23} md={11} lg={12} >
                                    <label style={{ fontSize: "15px", color: "#1C1B1F" }}>User's Email ID</label>
                                    <TextInputField
                                        name="emailId"
                                        value={formData.emailId}
                                        placeholder="Enter User's Email ID"
                                        feedbackMessage={errors.emailId}
                                        error={!!errors.emailId}
                                        onChange={handleInputChange}
                                    // validationRegex={emailRegex}

                                    />
                                    {touched.emailId && errors.emailId && (
                                        <div className="createClientErrors">{errors.emailId}</div>
                                    )}
                                </Col>
                                <Col sm={12} xs={23} md={11} lg={12} >
                                    <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Password</label>
                                    <TextInputField
                                        name="password"
                                        value={formData.password}
                                        // value={formData.password}
                                        placeholder="Enter Password"
                                        type="password"
                                        autoComplete="off"
                                        feedbackMessage="Please input the password."
                                        onChange={handlePasswordChange}
                                        style="-webkit-text-security: disc;"
                                    // validationRegex={validationRegexOnlyAlpha}
                                    />
                                    {touched.password && errors.password && (
                                        <div className="createClientErrors">{errors.password}</div>
                                    )}
                                </Col>
                                <Col sm={12} xs={23} md={11} lg={12} >
                                    <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Contact Number</label>
                                    <TextInputField
                                        name="contactNumber"
                                        value={formData.contactNumber}
                                        placeholder="Enter User's Contact No."
                                        feedbackMessage="Please input the contact number."
                                        onChange={handleInputChange}
                                        validationRegex={validationRegexAmount}
                                    />
                                    {touched.contactNumber && errors.contactNumber && (
                                        <div className="createClientErrors">{errors.contactNumber}</div>
                                    )}
                                </Col>
                            </Row>
                        </Row>

                        {/* Footer Buttons */}
                        <div className="global-admin-modal-footer" >
                            <button className="global-admin-discard-button" onClick={handleCancel}>
                                <img src={DeleteIcon} alt="Less Than" className="globaldeleteIcon" />
                                Discard
                            </button>
                            <button className="global-admin-apply-button" onClick={handleSubmit} disabled={loading}>
                                <img src={ApplyIcon} alt="Less Than" className="globalApplyIcon" />
                                {loading ? "Submitting..." : "Apply"}
                                {/* Apply */}
                            </button>
                        </div>
                    </div>

                    {/* <Modal open={cropModalVisible} onCancel={() => setCropModalVisible(false)} onOk={getCroppedImage} title="Crop Image">
                        <div style={{ position: "relative", width: "100%", height: 300 }}>
                            <Cropper image={imageSrc} crop={crop} zoom={zoom} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} aspect={1} />
                        </div>
                    </Modal> */}
                </Modal>

                {/* Modal for Edit Users */}
                <Modal
                    title="Edit User"
                    open={isEditModalVisible}
                    onCancel={editHandleCancel}
                    footer={null}
                    centered
                    width={1000}
                    closable={false}
                    style={{ marginLFeft: "4%" }}
                // size="lg"
                >
                    <div className="add-user-container">
                        <Row className="user-form" style={{ display: "flex", alignItems: "center", marginLeft: "3%", }}>
                            {/* Left Side - Image */}
                            <Col span={4} style={{ flexShrink: 0 }}>
                                {/* <img src={CreateClientImage} alt="Profile Image"
                                        style={{ width: "120px", height: "120px", borderRadius: "50%", }}
                                    /> */}
                                <Upload
                                    style={{ marginLeft: "-22%" }}
                                    name="clientImage"
                                    showUploadList={false}
                                    customRequest={handleImageChange}
                                    accept="image/*"
                                >
                                    <div className="global-admin-image-upload-circle">
                                        {clientImage ? (
                                            <img
                                                ref={imageRef}
                                                src={clientImage}
                                                alt="Uploaded"
                                                className="uploaded-image"
                                                onClick={handleImageClick}
                                            // onMouseMove={handleMouseMove}
                                            // onLoad={initializeCanvas}
                                            />
                                        ) : formData.imageUrl ? (
                                            <img
                                                ref={imageRef}
                                                src={formData.imageUrl}
                                                alt="Existing Client"
                                                className="uploaded-image"
                                                onClick={handleImageClick}
                                            />
                                        ) : formData.mainLogoUrl ? (
                                            <img
                                                ref={imageRef}
                                                src={formData.mainLogoUrl}
                                                alt="Existing Logo"
                                                className="uploaded-image"
                                                onClick={handleImageClick}
                                            />
                                        ) : (
                                            <img
                                                src={CreateClientImage}
                                                alt="Upload Icon"
                                                className="upload-icon"
                                            />
                                        )}
                                    </div>

                                    {touched.clientImage && errors.clientImage && (
                                        <div className="createClientErrors">{errors.clientImage}</div>
                                    )}

                                </Upload>
                            </Col>

                            {/* Right Side - Input Fields */}
                            <Row gutter={[16, 16]}>
                                <Col sm={12} xs={23} md={11} lg={12}>
                                    <label style={{ fontSize: "15px", color: "#1C1B1F" }}>User's Name</label>
                                    <TextInputField
                                        name="name"
                                        value={formData.name}
                                        placeholder="Enter User's Name"
                                        feedbackMessage="Please input the user name."
                                        validationRegex={validationRegexOnlyAlpha}
                                        onChange={handleInputChange}
                                    />
                                    {touched.name && errors.name && (
                                        <div className="createClientErrors">{errors.name}</div>
                                    )}
                                </Col>
                                <Col sm={12} xs={23} md={11} lg={12} >
                                    <label style={{ fontSize: "15px", color: "#1C1B1F" }}>User's Email ID</label>
                                    <TextInputField
                                        name="emailId"
                                        value={formData.emailId}
                                        placeholder="Enter User's Email ID"
                                        feedbackMessage={errors.emailId}
                                        error={!!errors.emailId}
                                        onChange={handleInputChange}
                                    // validationRegex={emailRegex}

                                    />
                                    {touched.emailId && errors.emailId && (
                                        <div className="createClientErrors">{errors.emailId}</div>
                                    )}
                                </Col>
                                <Col sm={12} xs={23} md={11} lg={12} >
                                    <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Password</label>
                                    <TextInputField
                                        name="password"
                                        value={formData.password}
                                        // value={formData.password}
                                        placeholder="Enter Password"
                                        type="password"
                                        autoComplete="off"
                                        feedbackMessage="Please input the password."
                                        onChange={handlePasswordChange}
                                    // validationRegex={validationRegexOnlyAlpha}
                                    />
                                    {touched.password && errors.password && (
                                        <div className="createClientErrors">{errors.password}</div>
                                    )}
                                </Col>
                                <Col sm={12} xs={23} md={11} lg={12} >
                                    <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Contact Number</label>
                                    <TextInputField
                                        name="contactNumber"
                                        value={formData.contactNumber}
                                        placeholder="Enter User's Contact No."
                                        feedbackMessage="Please input the contact number."
                                        onChange={handleInputChange}
                                        validationRegex={validationRegexAmount}
                                    />
                                    {touched.contactNumber && errors.contactNumber && (
                                        <div className="createClientErrors">{errors.contactNumber}</div>
                                    )}
                                </Col>
                            </Row>
                        </Row>

                        {/* Footer Buttons */}
                        <div className="modal-footer">
                            {selectedUser && (
                                <button
                                    style={{
                                        color: selectedUser.userStatus === 1 ? "#C12328" : "green", // Red for deactivate, green for activate
                                        border: `1px solid ${selectedUser.userStatus === 1 ? "#C12328" : "green"}`, // Matching border color
                                        padding: "5px 10px",
                                        borderRadius: "5px",
                                    }}
                                    className="global-admin-delete-icon-button"
                                    onClick={() => showDeleteModal(selectedUser)} // Only the selected user
                                >
                                    {/* <img src={DeleteIconRedColor} alt="Delete Icon" className="globaldeleteIconRedColor" /> */}
                                    <span style={{ color: selectedUser.userStatus === 1 ? "#C12328" : "green", }}>
                                        {selectedUser.userStatus === 1 ? "Deactivate User" : "Activate User"}
                                    </span>
                                </button>
                            )}

                            <div className="edit-cancel-and-apply"
                                style={{
                                    display: "flex", gap: "12px", width: "102%", justifyContent: "end"
                                }}
                            >
                                <button className="global-admin-discard-button" onClick={editHandleCancel}>
                                    <img src={CancelIcon} alt="Less Than" className="globaldeleteIcon" />
                                    Cancel
                                </button>
                                <button className="global-admin-apply-button" onClick={handleSubmit} >
                                    <img src={ApplyIcon} alt="Less Than" className="globalApplyIcon" />

                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Modal for Delete user */}
                {isDeleteModalVisible && selectedUser && (
                    <Modal
                        // title={selectedUser.userStatus === 1 ? "Confirm Deletion" : "Confirm Revocation"}
                        open={isDeleteModalVisible}
                        onCancel={() => setDeleteModalVisible(false)}
                        footer={null}
                        centered
                        width={500}
                        closable={false}
                    >
                        <p>
                            Do you want to {selectedUser.userStatus === 1 ? "deactivate" : "activate"} this
                            <span style={{ fontWeight: "bold" }}>{selectedUser.name} User?</span>
                        </p>
                        <div className="delete-modal-footer">
                            <button
                                style={{ backgroundColor: selectedUser.userStatus === 1 ? "#C12328" : "green", border: `1px solid ${selectedUser.userStatus === 1 ? "#C12328" : "green"}`, }}
                                className="global-admin-Delete-button"
                                onClick={() => handleDeleteUser(selectedUser)}  // ✅ Ensure correct user is deleted/revoked
                            >
                                {selectedUser.userStatus === 1 ? "Deactivate User" : "Activate User"}
                            </button>
                            <button
                                className="global-admin-Cancel-button"
                                onClick={() => setDeleteModalVisible(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </Modal>
                )}

            </div >
        </Content >

    );
}

