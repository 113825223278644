import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./textInput.css";

const TextInputField = ({
    label,
    name,
    required,
    value = "",
    type = "text",
    onChange = () => { }, // Default no-op function
    placeholder,
    feedbackMessage,
    validationRegex,
    validationErrorMessage = "Invalid input",
    maxLength,
    disabled,
    onClick
}) => {
    const [error, setError] = useState(null);

    const handleInputChange = (event) => {
        let inputValue = event.target.value;

        // Limit input length if maxLength is provided
        if (maxLength && inputValue.length > maxLength) {
            inputValue = inputValue.substring(0, maxLength);
        }

        // Validate input
        const isValidInput = validationRegex ? validationRegex.test(inputValue) : true;

        // Update error state and call onChange prop
        if (isValidInput) {
            setError(null);
            onChange({ target: { name, value: inputValue } }); // Safely call onChange
        } else {
            setError(validationErrorMessage);
        }
    };

    return (
        <Form.Group autoComplete="off">
            {label && <Form.Label className="label-style">{label}</Form.Label>}
            {type === "password" && (
                <input type="text" name="fakeUser" style={{ display: "none" }} autoComplete="off" />
            )}
            <Form.Control
                name={type === "password" ? `password-${Math.random()}` : name}
                className="textInput"
                required={required}
                placeholder={placeholder}
                value={value}
                type={type}
                disabled={disabled}
                onClick={onClick}
                onChange={handleInputChange}
                maxLength={maxLength}
                isInvalid={!!error}
                autoComplete={type === "password" ? "new-password" : "off"}
            />
            {error && <div className="error-message">{error}</div>}
        </Form.Group>
    );
};

export default TextInputField;
