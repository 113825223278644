import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { Add_update_super_admins, currentUserProfileQuery, Upload_Document } from "../services/userAPI";
import "./resuableHeader.css";
import Icon2 from "../Images/icon2.png";
import HeaderCloseIcon from "../Images/header-circle-image.png";
import HeaderOpenIcon from "../Images/header-circle-down.png";
import HeaderOpenW from "../Images/expand_circle-down-white-color.png";
import Logout from "../Images/logout-icon.png";
import { IoIosLogOut } from "react-icons/io";
import { Menu, X } from "lucide-react";
import { Button, Col, message, Modal, Row, Upload } from "antd";
import Lessthan from "../Images/lessthan_Icon.png"
import CreateIconPlus from "../Images/profile-edit-icon.png"
import LoadingImage from "../Images/icon2.png"
import Home from "../Images/home-image.png";
import CreateFolder from "../Images/create_new_folder_image.png";
import TextInputField from "../reusableField/TextInputField2";
import CreateClientImage from "../Images/add-new-profile-photo.png";
import { passwordRegex, validationRegexAmount, validationRegexOnlyAlpha } from "../regex/regex";
import ApplyIcon from "../Images/global-admin-apply-icon.png"
import CancelIcon from "../Images/global-admin-cancel.png"
import "../components/globalAdmin.css"
// import defaultProfile from "../Images/default-profile-image.png";
import defaultProfile from "../Images/profile-user.png";

const ReusableHeader = ({ setIsAuthenticated = () => { } }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [clientImage, setClientImage] = useState(null);
  const [errors, setErrors] = useState({})
  const imageRef = useRef(null);
  const [touched, setTouched] = useState({})
  const [isPickingColor, setIsPickingColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [uploadedFilename, setUploadedFilename] = useState(null);
  const [maskedPassword, setMaskedPassword] = useState()
  const [actualPassword, setActualPassword] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setActualPassword(value);
    setMaskedPassword("*".repeat(value.length));
    setFormData({ ...formData, password: value });
  };



  const showDeleteModal = (user) => {
    setSelectedUser(user);
    setDeleteModalVisible(true);
  };

  const handleImageClick = (e) => {
    if (!isPickingColor) return;
    e.preventDefault();
    e.stopPropagation();

    if (selectedColor) {
      setFormData(prevData => ({
        ...prevData,
        brandColor: selectedColor.hex
      }));
    }
    setIsPickingColor(false);
    if (imageRef.current) {
      imageRef.current.classList.remove('color-picking-cursor');
      imageRef.current.classList.remove('active-picker-border');
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    emailId: "",
    password: "",
    phoneNumber: "",
    imageUrl: "",
  });

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [formData]);

  const [uploadDocument] = useMutation(Upload_Document, {
    onCompleted: (data) => {
      if (data.uploadDocument.success) {
        const filename = data.uploadDocument?.data?.data?.filename;
        const imageUrl = data.uploadDocument?.data?.data?.url;
        setUploadedFilename(filename);

        setFormData((prevData) => ({
          ...prevData,
          imageUrl: imageUrl,
          // filename: filename,
        }));
        message.success("Image uploaded successfully!");
      } else {
        message.error("Failed to upload image: " + data.uploadDocument.message);
      }
    },
    onError: (error) => {
      message.error("Upload failed: " + error.message);
    },
  });

  const { loading, error, data, refetch } = useQuery(currentUserProfileQuery, {
    skip: !!userData,
    fetchPolicy: "network-only",
  });

  const [addUpdateSuperAdmin] = useMutation(Add_update_super_admins);

  useEffect(() => {
    if (data?.currentUserProfile) {
      setUserData(data.currentUserProfile);
    }
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const logout = () => {
    localStorage.removeItem("flutter.accessToken");
    setIsAuthenticated(false);
    setUserData(null);
    window.location.href = "/login-page";
  };

  const truncateDesignation = (designation) => {
    const words = designation.split(" ");
    if (words.length > 2) {
      return `${words.slice(0, 2).join(" ")}...`;
    }
    return designation;
  };

  const truncateUserName = (designation) => {
    return designation.split(" ")[0]; // Returns only the first word
  };

  <div className="loading-container">
    <div className="spinner"></div>
  </div>

  if (error) return <p>Error: {error.message}</p>;

  const getIcon = () => {
    if (isDropdownOpen) {
      return HeaderOpenIcon;
    }
    if (isHovered) {
      return HeaderOpenW;
    }
    return HeaderCloseIcon;
  };

  const handleDiscardClick = () => {
    navigate("/admin");
  };




  // Add these functions
  const handleEditClick = () => {

    setFormData({
      userId: userData?.id,
      name: userData?.name || "",
      emailId: userData?.email || "",
      password: userData?.password || "",
      phoneNumber: userData?.phoneNumber || "",
      imageUrl: userData?.profilePicture || "",
    });

    setIsEditModalVisible(true);
  };

  const handleCancel = () => {
    setIsEditModalVisible(false);
    setFormData({
      name: "",
      emailId: "",
      password: "",
      phoneNumber: "",
      imageUrl: "",
    });
    setClientImage(null);
    setTouched({});
    setErrors({});
    setIsEditModalVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Enter users name.";
    }

    // if (!emailRegex.test(formData.emailId)) {
    //     newErrors.emailId = 'Please enter a valid company URL.';
    // }

    if (!formData.emailId) {
      newErrors.emailId = "Enter email-id.";
    } else if (!emailRegex.test(formData.emailId)) {  // Check email format
      newErrors.emailId = "Please enter a valid email address.";
    }

    if (!formData.imageUrl && !clientImage && !formData.mainLogoUrl) {
      newErrors.clientImage = "Upload image.";
    }

    if (!formData.password) {
      newErrors.password = "Enter password.";
    }

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Enter contact number.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    setTouched({
      name: true,
      emailId: true,
      password: true,
      phoneNumber: true,
      clientImage: true,
    });

    if (!validateForm()) {
      message.error("Please fix the errors before submitting.");
      return;
    }

    try {
      const { data: updateData } = await addUpdateSuperAdmin({
        variables: {
          updatedUser: {
            userId: userData.id,
            firstName: formData.name,
            surname: "",
            email: formData.emailId,
            password: formData.password,
            contactNumber: formData.phoneNumber,
            profilePicture: uploadedFilename,
            userStatus: null,
            userType: null
          }
        }
      });

      if (updateData?.addUpdateSuperAdmins?.success) {
        handleCancel();
        // Refresh the user data
        setUserData(prev => ({
          ...prev,
          name: formData.name,
          email: formData.emailId,
          phoneNumber: formData.phoneNumber,
          password: formData.password,
          profilePicture: updateData?.addUpdateSuperAdmins?.data?.profilePicture
        }));

        // Refetch the query to get fresh data
        await refetch();
      }
    } catch (error) {
      console.error(error.message);
    }
  };


  const handleImageChange = ({ file }) => {

    const reader = new FileReader();
    reader.onload = (e) => {
      setClientImage(e.target.result);
      // drawImageOnCanvas(e.target.result);
    };
    reader.readAsDataURL(file);


    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setClientImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }

    if (!file) {
      message.error("Invalid file object.");
      return;
    }

    const allowedFormats = ["image/jpeg", "image/png"];
    const actualFile = file.originFileObj || file;

    if (!actualFile) {
      message.error("No valid file detected.");
      return;
    }

    if (!allowedFormats.includes(actualFile.type)) {
      message.error("Invalid file format. Only JPEG and PNG are allowed.");
      return;
    }

    try {
      // Create a preview of the image
      const imageUrl = URL.createObjectURL(actualFile);
      setClientImage(imageUrl);

      setErrors((prevErrors) => ({
        ...prevErrors,
        clientImage: "", // Clear error for clientImage
      }));

      // Upload the actual file
      uploadDocument({
        variables: {
          s3folder: "COMPANY_LOGO",
          document: actualFile, // Pass the raw file object here
        },
        context: {
          useMultipart: true, // Ensure the Apollo Client supports multipart file uploads
        },
      });
    } catch (error) {
      console.error("Error handling the file:", error);
      message.error("File upload failed.");
    }
  };

  return (
    <header className="header-container">

      <div className="reusable-logo-container">
        {!(isMobile && location.pathname === "/create") && (
          <img src={Icon2} alt="Logo" />
        )}
      </div>

      {isMobile && !(location.pathname === "/create") && (
        <div className="mobile-menu-button">
          {isMobileMenuOpen ? (
            <X className="menu-icon" onClick={toggleMobileMenu} />
          ) : (
            <Menu className="menu-icon" onClick={toggleMobileMenu} />
          )}
        </div>
      )}

      {!isMobile && location.pathname === "/create" || location.pathname === "/create-client-form" ? (
        <></>
      ) : (
        userData && (
          <div className={`profile-dropdown ${isDropdownOpen ? "open" : ""}`}>

          
            <div
              className="profile-button"
              onClick={toggleDropdown}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="profile-content">
                <img src={getIcon()} alt="Toggle" className="header-icon" />
                <div className="text-container">
                  <span className="profile-name">{truncateUserName(userData.name)}

                  </span>
                  <span
                    className="profile-designation"
                    title={userData.designation}
                  >
                    {truncateDesignation(userData.designation)}
                  </span>
                </div>
                <img
                  // src={userData.profilePicture || "default-profile-image.png"}
                  src={userData.profilePicture ? userData.profilePicture : defaultProfile}
                  alt="User"
                  className="profile-image"
                />
              </div>
            </div>


            {/* <div className="actions-container">
              <button className="logout-button" onClick={logout}>
                <img src={Logout} alt="Logout" className="logout-icon" />
                LOGOUT
              </button>
              <img
                src={CreateIconPlus}
                onClick={handleEditClick}
                alt="Edit"
                className="profile-edit-icon"
              />
            </div> */}

            <div className="actions-container">

              <div className="edit-container">
                <button className="profile-edit-button" onClick={handleEditClick}>
                  <img
                    src={CreateIconPlus}
                    // onClick={handleEditClick}
                    alt="Edit"
                    className="profile-edit-icon"
                  />
                  EDIT
                </button>
              </div>
              <div className="logout-container">
                <button className="logout-button" onClick={logout}>
                  <img src={Logout} alt="Logout" className="logout-icon" />
                  LOGOUT
                </button>
              </div>

            </div>

          </div>
        )
      )}

      <Modal
        title="Edit Profile"
        open={isEditModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
        width={1000}
        closable={false}
        style={{ marginLFeft: "4%" }}
      // size="lg"
      >
        <div className="add-user-container">
          <Row className="user-form" style={{ display: "flex", alignItems: "center", marginLeft: "3%", }}>
            <Col span={4} style={{ flexShrink: 0 }}>
              <Upload
                style={{ marginLeft: "-22%" }}
                name="clientImage"
                showUploadList={false}
                customRequest={handleImageChange}
                accept="image/*"
              >
                <div className="global-admin-image-upload-circle">
                  {clientImage ? (
                    <img
                      ref={imageRef}
                      src={clientImage}
                      alt="Uploaded"
                      className="uploaded-image"
                      onClick={handleImageClick}
                    // onMouseMove={handleMouseMove}
                    // onLoad={initializeCanvas}
                    />
                  ) : formData.imageUrl ? (
                    <img
                      ref={imageRef}
                      src={formData.imageUrl}
                      alt="Existing Client"
                      className="uploaded-image"
                      onClick={handleImageClick}
                    />
                  ) : formData.mainLogoUrl ? (
                    <img
                      ref={imageRef}
                      src={formData.mainLogoUrl}
                      alt="Existing Logo"
                      className="uploaded-image"
                      onClick={handleImageClick}
                    />
                  ) : (
                    <img
                      src={CreateClientImage}
                      alt="Upload Icon"
                      className="upload-icon"
                    />
                  )}
                </div>

                {touched.clientImage && errors.clientImage && (
                  <div className="createClientErrors">{errors.clientImage}</div>
                )}

              </Upload>
            </Col>

            {/* Right Side - Input Fields */}
            <Row gutter={[16, 16]}>
              <Col sm={12} xs={23} md={11} lg={12}>
                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>User's Name</label>
                <TextInputField
                  name="name"
                  value={formData.name}
                  placeholder="Enter User's Name"
                  feedbackMessage="Please input the user name."
                  validationRegex={validationRegexOnlyAlpha}
                  onChange={handleInputChange}
                />
                {touched.name && errors.name && (
                  <div className="createClientErrors">{errors.name}</div>
                )}
              </Col>
              <Col sm={12} xs={23} md={11} lg={12} >
                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>User's Email ID</label>
                <TextInputField
                  name="emailId"
                  value={formData.emailId}
                  placeholder="Enter User's Email ID"
                  feedbackMessage={errors.emailId}
                  error={!!errors.emailId}
                  onChange={handleInputChange}
                // validationRegex={emailRegex}

                />
                {touched.emailId && errors.emailId && (
                  <div className="createClientErrors">{errors.emailId}</div>
                )}
              </Col>
              <Col sm={12} xs={23} md={11} lg={12} >
                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Password</label>
                <TextInputField
                  name="password"
                  value={formData.password}
                  // value={formData.password}
                  placeholder="Enter Password"
                  type="password"
                  autoComplete="off"
                  feedbackMessage="Please input the password."
                  onChange={handlePasswordChange}
                // validationRegex={validationRegexOnlyAlpha}
                />
                {touched.password && errors.password && (
                  <div className="createClientErrors">{errors.password}</div>
                )}
              </Col>
              <Col sm={12} xs={23} md={11} lg={12} >
                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Contact Number</label>
                <TextInputField
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  placeholder="Enter User's Contact No."
                  feedbackMessage="Please input the contact number."
                  onChange={handleInputChange}
                  validationRegex={validationRegexAmount}
                />
                {touched.phoneNumber && errors.phoneNumber && (
                  <div className="createClientErrors">{errors.phoneNumber}</div>
                )}
              </Col>
            </Row>
          </Row>

          {/* Footer Buttons */}
          <div className="modal-footer">
            {selectedUser && (
              <button
                style={{
                  color: selectedUser.userStatus === 1 ? "#C12328" : "green", // Red for deactivate, green for activate
                  border: `1px solid ${selectedUser.userStatus === 1 ? "#C12328" : "green"}`, // Matching border color
                  padding: "5px 10px", // Add padding for better visibility
                  borderRadius: "5px", // Optional rounded corners
                }}
                className="global-admin-delete-icon-button"
                onClick={() => showDeleteModal(selectedUser)} // Only the selected user
              >
                {/* <img src={DeleteIconRedColor} alt="Delete Icon" className="globaldeleteIconRedColor" /> */}
                <span style={{ color: selectedUser.userStatus === 1 ? "#C12328" : "green", }}>
                  {selectedUser.userStatus === 1 ? "Deactivate User" : "Activate User"}
                </span>
              </button>
            )}



            <div className="edit-cancel-and-apply"
              style={{
                display: "flex", gap: "12px", width: "102%", justifyContent: "end"
              }}
            >
              <button className="global-admin-discard-button" onClick={handleCancel}>
                <img src={CancelIcon} alt="Less Than" className="globaldeleteIcon" />
                Cancel
              </button>
              <button className="global-admin-apply-button" onClick={handleSubmit} >
                <img src={ApplyIcon} alt="Less Than" className="globalApplyIcon" />

                Apply
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {isMobile && (
        <div className={`mobile-menu ${isMobileMenuOpen ? "active" : ""}`}>
          <div className="mobile-menu-header">
            <span style={{ fontSize: "large", fontFamily: "Inter" }}>Close</span>
            <X className="menu-close-icon" onClick={toggleMobileMenu} />
          </div>
          {userData && (
            <div className="mobile-profile-info">
              <img
                src={userData.profilePicture ? userData.profilePicture : defaultProfile}

                alt="User"
                className="is-mobile-profile-image"
              />
              <div className="is-mobile-text-container">
                <span className="is-mobile-profile-name">{userData.name}</span>
                <span className="is-mobile-profile-designation">{truncateDesignation(userData.designation)}</span>
              </div>
            </div>
          )}

          <button className="mobile-menu-item active" onClick={() => {
            navigate('/dashboard');
            toggleMobileMenu();
          }}>
            <span>Dashboard</span>
            <img
              src={Home}
              alt="Home"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "7%",
              }}
            />
          </button>

          {/* <button className="mobile-menu-item active" onClick={() => {
            navigate('/admin');
            toggleMobileMenu();
          }}>
            <span>Admin</span>
            <img
              src={Home}
              alt="Home"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "7%",
              }}
            />
          </button> */}

          {/* <button className="mobile-menu-item" onClick={() => {
            navigate('/create');
            toggleMobileMenu();
          }}>
            <span>Create</span>
            <img
              src={CreateFolder}
              alt="Create"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "7%",

              }}
            />
          </button> */}
          <button className="mobile-menu-item" onClick={() => {
            navigate('/global-admin');
            toggleMobileMenu();
          }}>
            <span>Global Admin</span>
            <img
              src={CreateFolder}
              alt="Create"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "7%",

              }}
            />
          </button>
          <button className="mobile-logout-button" onClick={logout}>
            <span>Logout</span>
            <IoIosLogOut className="logout-icon" />

          </button>
        </div>
      )}


    </header>
  );
};

export default ReusableHeader;
