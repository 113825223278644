import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { gql } from "graphql-tag"; // GraphQL tag to define your mutation
import "./productConfiguration.css";
import TargetFunnelConfiguration from "./targetFunnelConfiguration";
import { Role_Management } from "../services/userAPI";

const ProductConfiguration = () => {
    const location = useLocation();
    const dartsFormNeeded = location.state?.dartsFormNeeded; // Get the value dynamically
    const accountId = location.state?.accountId;
    const roleId = location.state?.roleId;
    const pocName = location.state?.pocName;
    const pocEmail = location.state?.pocEmail;
    const pocNumber = location.state?.pocNumber;
    const numberLogin = location.state?.numberLogin;
    const emailLogin = location.state?.emailLogin;
    const mandatoryTimeDistribution = location.state?.mandatoryTimeDistribution;
    const targetEditDays = location.state?.targetEditDays;
    const inputEditDays = location.state?.inputEditDays;
    const name = location.state?.name;
    const dartsNeeded = location.state?.dartsNeeded;
    const clanNeeded = location.state?.clanNeeded;
    // Get the value dynamically

    console.log("dartsFormNeeded value:", dartsFormNeeded); // Debugging log

    const [inputType, setInputType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Initialize the mutation function
    const [addJobRole, { data, error }] = useMutation(Role_Management);

    // Update state dynamically when `dartsFormNeeded` changes
    useEffect(() => {
        if (dartsFormNeeded !== undefined) {
            setInputType(dartsFormNeeded ? "Forms" : "Numbers");
        }
    }, [dartsFormNeeded]);

    const handleInputChange = (event) => {
        setInputType(event.target.value);
    };

    const handleSave = async () => {
        setLoading(true);
        setErrorMessage("");

        // Prepare the data for the mutation
        const dartsFormNeededValue = inputType === "Forms" ? true : false;

        try {
            // Call the mutation
            const response = await addJobRole({
                variables: {
                    dartsFormNeeded: dartsFormNeededValue,
                    accountId: accountId,
                    roleId: roleId,
                    pocName: pocName,
                    pocEmail: pocEmail,
                    pocNumber: pocNumber,
                    numberLogin: numberLogin,
                    emailLogin: emailLogin,
                    mandatoryTimeDistribution: mandatoryTimeDistribution,
                    targetEditDays: targetEditDays,
                    inputEditDays: inputEditDays,
                    name: name,
                    dartsNeeded: dartsNeeded,
                    clanNeeded: clanNeeded,

                }
            });

            // Handle response
            if (response.data.addJobrole.success) {
                console.log("Job role added successfully!");
                // You can add a success message or further handling here
            } else {
                setErrorMessage(response.data.addJobrole.message || "Something went wrong!");
            }
        } catch (err) {
            setErrorMessage("Error occurred while saving data.");
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="product-config-container">
            {/* {inputType === "TargetFunnel" ? (
                <TargetFunnelConfiguration onBack={() => setInputType("Forms")} />
            ) : (
                <>
                    <h2 className="product-config-title">Input Type Configuration</h2>

                    <div className="product-config-card">
                        <label className="product-config-option">
                            <input
                                type="radio"
                                name="inputType"
                                value="Forms"
                                checked={inputType === "Forms"}
                                onChange={handleInputChange}
                            />
                            <div>
                                <strong>Forms</strong>
                                <p>Structured data input through forms</p>
                            </div>
                        </label>

                        <label className="product-config-option">
                            <input
                                type="radio"
                                name="inputType"
                                value="Numbers"
                                checked={inputType === "Numbers"}
                                onChange={handleInputChange}
                            />
                            <div>
                                <strong>Numbers</strong>
                                <p>Simple numerical input forms</p>
                            </div>
                        </label>
                    </div>

                    {errorMessage && (
                        <div className="error-message">
                            <p>{errorMessage}</p>
                        </div>
                    )}

                    <div className="product-config-buttons">

                    </div>

                    <div className="product-config-buttons">
                        <button
                            className="product-config-btn next"
                            onClick={handleSave}
                            disabled={loading}
                        >
                            {loading ? "Saving..." : "Save"}
                        </button>
                        <button
                            className="product-config-btn next"
                            onClick={() => setInputType("TargetFunnel")}
                        >
                            Next →
                        </button>
                    </div>
                </>
            )} */}
        </div>
    );
};

export default ProductConfiguration;
