import React, { useState, useEffect } from "react";
import "./superManagerDashboard.css";
import { Layout } from "antd";
import { FaChevronDown, FaChevronRight, FaUsers, FaUserShield } from "react-icons/fa";
import { HiBuildingOffice2 } from "react-icons/hi2";
import Lense from "../Images/Lense-Logo.png";
import Darts from "../Images/Darts-Logo.png";
import Clan from "../Images/clan_logo.png";
import Clients from "../Images/clientsLogo.png";
import Roles from "../Images/rolesLogo.png";
import Users from "../Images/usersLogo.png";
import UsersIcon from "../Images/Users-icon.png";
import { Accounts_Query, Dashboard_API, Get_ClientWise_DetailsForAdmin, Role_Account_Query } from "../services/userAPI";
import { useLazyQuery, useQuery } from "@apollo/client";
import defaultProfile from "../Images/profile-user.png";
import { useNavigate } from "react-router-dom";
import LoadingImage from "../Images/icon2.png";
import SearchableDropdown from "../reusableField/searchableDropdownField";
import Editicon from "../Images/Edit.png";
import MoreClientsImage from "../Images/more-clients-logo.png";
import LenseLogo from "../Images/lense-logo-red-color.png";
import DartsLogo from "../Images/darts-logo-red-color.png";
import ClanLogo from "../Images/clan-logo-red-color.png";


const { Content } = Layout;

const SuperManagerDashboard = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [sortBy, setSortBy] = useState(1);
    const { loading, error, data } = useQuery(Dashboard_API);
    const navigate = useNavigate();
    const [selectedRoles, setSelectedRoles] = useState({});
    const [selectedValues, setSelectedValues] = useState({});
    const [loadingRoles, setLoadingRoles] = useState({});
    const [imageError, setImageError] = useState({});
    const [searchQuery, setSearchQuery] = useState(""); 
    const [hoverIndex, setHoverIndex] = useState(null);
    const { loading: accountsLoading, error: accountsError, data: accountsData } = useQuery(Accounts_Query);
    useEffect(() => {
        refetch({ searchTerm, sortBy });
    }, [sortBy]);

    const [expandedClient, setExpandedClient] = useState(null);

    const toggleExpand = (clientId) => {
        setExpandedClient(expandedClient === clientId ? null : clientId);
    };

    const statsData = {
        clients: data?.getDashboardValues?.totalClients,
        roles: data?.getDashboardValues?.totalRoles,
        users: data?.getDashboardValues?.totalUsers,
    };

    const { loading: getClinetLoading, error: getClientError, data: getClientData, refetch } = useQuery(Get_ClientWise_DetailsForAdmin, {
        variables: { searchTerm, sortBy },
    });

    const clients = getClientData?.getClientWiseDetailsForAdmin?.map((client) => ({
        id: client.account.id,
        name: client.account.name,
        icon: client.account.mainLogoUrl,
        users: client.numberOfUsers,
        roles: client.numberOfRoles,
        userTypes: [
            { name: "Supermanagers", count: client.numberOfSupermanagers },
            { name: "Managers", count: client.numberOfManagers },
            { name: "Users", count: client.numberOfUsers },
        ],
        applications: [
            { name: "LENSE", image: Lense, count: client.numberOfUsersInLense },
            { name: "DARTS", image: Darts, count: client.numberOfUsersInDarts },
            { name: "CLAN", image: Clan, count: client.numberOfUsersInClan },
        ],
    }));
    const [fetchRoles] = useLazyQuery(Role_Account_Query, {
        onCompleted: (data) => {
            const accountId = data.getAllJobrole[0]?.account?.id || data.getAllJobrole?.accountId;
            if (accountId) {
                setSelectedRoles((prevRoles) => ({
                    ...prevRoles,
                    [accountId]: data.getAllJobrole,
                }));
                setLoadingRoles((prev) => ({
                    ...prev,
                    [accountId]: false,
                }));
            }
        },
        onError: (error) => {
            console.error("Error fetching roles:", error);
        },
    });

    const handleImageError = (userId) => {
        setImageError((prev) => ({
            ...prev,
            [userId]: true, // Set error flag for this image
        }));
    };

    const handleAccountChange = (accountId) => {
        if (!selectedRoles[accountId]) {
            fetchRoles({
                variables: { accountId: parseInt(accountId) },
            });
        }
    };

    const handleRoleChange = (value, accountId) => {
        setSelectedValues((prev) => ({
            ...prev,
            [accountId]: value,
        }));
    };

    const handleEdit = (accountId, userData) => {
        const selectedRole = selectedValues[accountId];
        navigate(`/create?accountId=${accountId}&roleId=${selectedRole}`, {
            state: {
                id: userData.id,
                name: userData.name,
                url: userData.url,
                address: userData.address,
                address2: userData.address2,
                brandColor: userData.brandColor,
                lenseNeeded: userData.lenseNeeded,
                dartsNeeded: userData.dartsNeeded,
                clanNeeded: userData.clanNeeded,
                emailLogin: userData.emailLogin,
                numberLogin: userData.numberLogin,
                imageUrl: userData.imageUrl,
                mainLogoUrl: userData.mainLogoUrl,
                selfRegistration: userData.selfRegistration,
            },
        });
    };


    const viewRole = () => {
        navigate("./view-role")
    }

    if (accountsLoading)
        return (
            <div className="loading-container">
                <div className="spinner"></div>
            </div>
        );

    if (accountsError) return <div>Error: {accountsError.message}</div>;

    // Filter cards based on the search query
    const filteredAccounts = accountsData?.allUserAccount?.filter((user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()))
        ?.reverse();


    const overviewData = [
        { name: "LENSE", image: Lense, hoverImage: LenseLogo, totalUsers: data?.getDashboardValues?.totalLenseUsers },
        { name: "DARTS", image: Darts, hoverImage: DartsLogo, totalUsers: data?.getDashboardValues?.totalDartsUsers },
        { name: "CLAN", image: Clan, hoverImage: ClanLogo, totalUsers: data?.getDashboardValues?.totalClanUsers },
    ];

    const handleSortChange = (event) => {
        const selectedSort = parseInt(event.target.value, 10);
        setSortBy(selectedSort);
        refetch({ searchTerm, sortBy: selectedSort });
    };



    return (

        <Content>
            <div className="dashboard-container">
                {/* Header */}
                <div>
                    <h2>Dashboard</h2>
                </div>

                <div className="stats-container">
                    {/* Left side - Stats */}
                    <div className="stats-left">
                        <div className="stats-item-client">
                            <div className="stats-icon">
                                <img className="super-manager-image-client" src={Clients} alt="Clients" />
                            </div>
                            <div className="stats-content">
                                <span className="stats-client">{statsData.clients === 1 ? "Client" : "Clients"}</span>
                                <span className="stats-value-client">{statsData.clients}</span>
                            </div>
                        </div>

                        <div className="stats-item">
                            <div className="stats-icon">
                                <img className="super-manager-image" src={Roles} alt="Roles" />
                            </div>
                            <div className="stats-content">
                                <span className="stats-label">{statsData.roles === 1 ? "Role" : "Roles"}</span>
                                <span className="stats-value">{statsData.roles}</span>
                            </div>
                        </div>

                        <div className="stats-item">
                            <div className="stats-icon">
                                <img className="super-manager-image" src={Users} alt="Users" />
                            </div>
                            <div className="stats-content">
                                <span className="stats-label">{statsData.users === 1 ? "Product User" : "Product Users"}</span>
                                <span className="stats-value">{statsData.users}</span>
                            </div>
                        </div>
                    </div>

                    {/* Right side - Product cards */}
                    <div className="stats-right">
                        {overviewData.map((item, index) => (
                            <div
                                className={`product-card-dashboard ${hoverIndex === index ? "hovered" : ""}`}
                                key={index}
                                onMouseEnter={() => setHoverIndex(index)}
                                onMouseLeave={() => setHoverIndex(null)}
                            >
                                <div className="product-logo">
                                    <img src={hoverIndex === index && item.hoverImage ? item.hoverImage : item.image} alt={item.name} />
                                </div>
                                <div className="product-stats">
                                    <span className="product-label">Total Users</span>
                                    <span className="product-value">{item.totalUsers}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Overview Cards */}

                <br />

                <div>
                    <h2>Clients</h2>

                </div>
                <Content>

                    {/* <div className="home-content"> */}
                    <div className="cards-container-dashboard">
                        {filteredAccounts?.slice(0, 4).map((user) => {
                            const isRoleSelected = Boolean(selectedValues[user.id]);
                            const hasNoRoles = user.numberOfRoles === 0;

                            return (
                                <div key={user.id} className="card">
                                    <div className="card-header">
                                        {imageError[user.id] || !user.mainLogoUrl ? (
                                            <div className="placeholderImage">
                                                <span className="placeholder-text">No Image</span>
                                            </div>
                                        ) : (
                                            <img
                                                src={user.mainLogoUrl}
                                                alt={user.name}
                                                className="card-image"
                                                onError={() => handleImageError(user.id)} // Handle error
                                            />
                                        )}
                                        <img
                                            src={Editicon}
                                            alt="Edit"
                                            className="edit-icon"
                                            onClick={() => handleEdit(user.id, user)} // Edit button triggers handleEdit
                                        />
                                    </div>
                                    <h3 className="card-title">{user.name}</h3>

                                    <div className="card-actions">
                                        {loadingRoles[user.id] ? (
                                            <img src={LoadingImage} className="loading-image" />
                                        ) : (
                                            <SearchableDropdown
                                                options={selectedRoles[user.id] || []}
                                                label="name"
                                                id="id"
                                                selectedVal={selectedValues[user.id]}
                                                handleChange={(value) => handleRoleChange(value, user.id)}
                                                placeholder={
                                                    hasNoRoles ? "No roles available" : "Select Role"
                                                }
                                                onFocus={() => handleAccountChange(user.id)} // Call fetchRoles on focus
                                                disabled={loadingRoles[user.id] || hasNoRoles}
                                            />
                                        )}
                                        <button
                                            className={`view-role-button`}
                                            onClick={() => {
                                                const selectedRoleId = selectedValues[user.id];
                                                if (selectedRoleId) {
                                                    const selectedRoleData = selectedRoles[user.id]?.find(
                                                        (role) => role.id === selectedRoleId
                                                    );

                                                    if (selectedRoleData) {
                                                        navigate("/view-role", {
                                                            state: {
                                                                roleId: selectedRoleId,
                                                                dartsNeeded: selectedRoleData.dartsNeeded,
                                                                clanNeeded: selectedRoleData.clanNeeded,
                                                                lenseNeeded: selectedRoleData.lenseNeeded || false,
                                                                emailLogin: selectedRoleData.emailLogin,
                                                                inputEditDays: selectedRoleData.inputEditDays,
                                                                mandatoryTimeDistribution: selectedRoleData.mandatoryTimeDistribution,
                                                                numberLogin: selectedRoleData.numberLogin,
                                                                name: selectedRoleData.name,
                                                                pocEmail: selectedRoleData.pocEmail || "",
                                                                pocName: selectedRoleData.pocName || "",
                                                                pocNumber: selectedRoleData.pocContactNumber || "",
                                                                targetEditDays: selectedRoleData.targetEditDays,
                                                                dartsFormNeeded: selectedRoleData.dartsFormNeeded,
                                                                accountId: selectedRoleData.account.id,
                                                            },
                                                        });
                                                    }
                                                }
                                            }}
                                            disabled={!selectedValues[user.id] || hasNoRoles}
                                            style={{
                                                backgroundColor: selectedValues[user.id]
                                                    ? "#C12328"
                                                    : hasNoRoles
                                                        ? "#dcdcdc"
                                                        : "#FAC9CA",
                                                cursor: selectedValues[user.id]
                                                    ? "pointer"
                                                    : "not-allowed",
                                            }}
                                        >
                                            View Role
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {/* More Clients Button */}
                    {filteredAccounts?.length > 3 && (
                        <div className="more-clients-container">
                            <button className="more-clients-btn" onClick={() => navigate("/admin")}>
                                More Clients
                                <img src={MoreClientsImage} className="moreClientsImage" />
                            </button>
                        </div>
                    )}
                    {/* </div> */}
                </Content>


                {/* <div className="clients-wrapper">
                    <h2 className="clients-title">Clients</h2>

                    <div className="search-sort-container">
                        <div className="super-manager-search-container">
                            <input
                                type="text"
                                placeholder="Search Clients..."
                                className="search-input"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}

                            />
                            <button className="search-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </button>
                        </div>

                        <div className="sort-container">
                            <span className="sort-label">Sort by:</span>
                            <select className="sort-select" value={sortBy} onChange={handleSortChange}>
                                <option value="1">Latest</option>
                                <option value="2">Old</option>
                                <option value="3">Max.Users</option>
                                <option value="4">Min.Users</option>
                            </select>
                        </div>
                    </div>

                    <div className="client-list">
                        {clients?.map((client) => (
                            <div key={client.id} className="client-wrapper">
                                <div
                                    className="client-item"
                                    onClick={() => toggleExpand(client.id)}
                                >
                                    <div className="client-left">
                                        {expandedClient === client.id ?
                                            <FaChevronDown className="chevron-icon" /> :
                                            <FaChevronRight className="chevron-icon" />
                                        }
                                        <div className="client-icon">
                                            <img
                                                src={client.icon || defaultProfile}
                                                alt={client.name}
                                                className="client-logo"
                                                onError={(e) => e.target.src = defaultProfile}
                                            />
                                        </div>

                                        <span className="client-name">{client.name}</span>
                                    </div>

                                    <div className="client-right">
                                        <div className="stats-badge users-badge">
                                            <img className="super-manager-image" src={Users} />
                                            <span>{client.users === 1 ? "Product User" : "Product Users"}</span>
                                            <span className="badge-value">{client.users}</span>
                                        </div>

                                        <div className="stats-badge roles-badge">
                                            <img className="super-manager-image" src={Roles} />
                                            <span>{client.roles === 1 ? "Role" : "Roles"}</span>
                                            <span className="badge-value">{client.roles}</span>
                                        </div>
                                    </div>
                                </div>

                                {expandedClient === client.id && (
                                    <div className="client-expanded">
                                        <div className="super-manager-cards-container">
                                            <div className="cards-row">
                                                {client.userTypes && client.userTypes.map((type, index) => (
                                                    <div key={index} className="super-manager-role-card">
                                                        <div className="super-manager-card-title">{type.name}</div>
                                                        <div className="card-count">
                                                            <img src={UsersIcon} className="user-icon" />
                                                            <span>{type.count}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="super-manager-cards-container">
                                            <div className="cards-row">
                                                {client.applications && client.applications.map((app, index) => (
                                                    <div key={index} className="app-card">
                                                        <div className="app-logo">
                                                            <img src={app.image} alt={app.name} className="app-image" />
                                                        </div>
                                                        <div className="card-count">
                                                            <img src={UsersIcon} className="user-icon" />
                                                            <span>{app.count}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div> */}
            </div >
        </Content>
    );
};

export default SuperManagerDashboard;
