import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useBreadcrumb, loadBreadcrumbsFromStorage } from "./breadcrumbContext";
import { Breadcrumb } from "antd";

const formatBreadcrumbName = (name) => {
    return name
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const DynamicBreadcrumb = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { breadcrumbs, appendBreadcrumb, setBreadcrumb, restoreFromHistory } = useBreadcrumb();
    const pathnames = location.pathname.split('/').filter(Boolean);
    const isInitialRender = useRef(true);
    const prevPath = useRef(location.pathname);

    // useEffect(() => {
    //     const currentPath = location.pathname;
        
    //     // Define paths where breadcrumbs should be reset
    //     const excludedPaths = ['/create', '/global-admin', '/admin', '/super-manager-dashboard'];
        
    //     // Skip processing on initial render to prevent immediate breadcrumb reset
    //     if (isInitialRender.current) {
    //         isInitialRender.current = false;
    //         return;
    //     }

    //     // Check if we're navigating back by comparing current path with previous path
    //     const isNavigatingBack = prevPath.current !== currentPath && 
    //                              document.referrer.includes(location.hostname);

    //     // Try to restore from history first if going back
    //     // if (isNavigatingBack) {
    //     //     const restored = restoreFromHistory(currentPath);
    //     //     if (restored) {
    //     //         prevPath.current = currentPath;
    //     //         return;
    //     //     }
    //     // }

    //     // Check if the current path is in the excluded paths, then reset breadcrumbs
    //     if (excludedPaths.some(path => currentPath === path || currentPath === path + '/')) {
    //         setBreadcrumb([{ name: "Home", path: "/" }]);
    //         prevPath.current = currentPath;
    //         return;
    //     }

    //     // Build path segments and corresponding breadcrumbs
    //     let builtPath = '';
    //     const newBreadcrumbs = [];

    //     // Always start with Home
    //     newBreadcrumbs.push({
    //         name: 'Home',
    //         path: '/',
    //     });

    //     // Add each path segment
    //     pathnames.forEach((segment, index) => {
    //         builtPath += `/${segment}`;
    //         newBreadcrumbs.push({
    //             name: formatBreadcrumbName(segment),
    //             path: builtPath,
    //         });
    //     });

    //     // Update breadcrumbs
    //     setBreadcrumb(newBreadcrumbs);
    //     prevPath.current = currentPath;

    // }, [location.pathname, setBreadcrumb, restoreFromHistory]);

    // useEffect(() => {
    //     // Listen for popstate (browser back/forward) events
    //     const handlePopState = () => {
    //         // Try to restore the breadcrumb state from history
    //         const restored = restoreFromHistory(window.location.pathname);
    //         if (!restored) {
    //             // If no history entry, rebuild breadcrumbs based on current path
    //             const paths = window.location.pathname.split('/').filter(Boolean);
    //             let builtPath = '';
    //             const newBreadcrumbs = [{ name: "Home", path: "/" }];
                
    //             paths.forEach(segment => {
    //                 builtPath += `/${segment}`;
    //                 newBreadcrumbs.push({
    //                     name: formatBreadcrumbName(segment),
    //                     path: builtPath,
    //                 });
    //             });
                
    //             setBreadcrumb(newBreadcrumbs);
    //         }
    //     };
    
    //     window.addEventListener("popstate", handlePopState);
    
    //     return () => {
    //         window.removeEventListener("popstate", handlePopState);
    //     };
    // }, [restoreFromHistory, setBreadcrumb]);
    
    // const handleBreadcrumbClick = (path, index) => {
    //     // When clicking on a breadcrumb, truncate the breadcrumb array to that point
    //     if (index < breadcrumbs.length - 1) {
    //         setBreadcrumb(breadcrumbs.slice(0, index + 1));
    //     }
    //     navigate(path);
    // };

    return (
        <Breadcrumb style={{ marginTop: '8%', marginLeft: '7%', marginBottom: "1%" }}>
            {/* {breadcrumbs.map((crumb, index) => {
                const isCurrentPath = crumb.path === location.pathname;

                return (
                    <Breadcrumb.Item
                        key={crumb.path}
                        style={{
                            color: isCurrentPath ? "#4CAF50" : "#000",
                            fontWeight: isCurrentPath ? "bold" : "normal",
                            fontSize: isCurrentPath ? "16px" : "14px",
                            cursor: !isCurrentPath ? "pointer" : "default"
                        }}
                        onClick={() => !isCurrentPath && handleBreadcrumbClick(crumb.path, index)}
                    >
                        {isCurrentPath ? (
                            crumb.name
                        ) : (
                            <Link to={crumb.path}>{crumb.name}</Link>
                        )}
                    </Breadcrumb.Item>
                );
            })} */}
        </Breadcrumb>
    );
};

export default DynamicBreadcrumb;