import React, { useState } from "react";
import { Layout } from "antd";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Accounts_Query, Role_Account_Query } from "../services/userAPI";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import Editicon from "../Images/Edit.png";
import SearchableDropdown from "../reusableField/searchableDropdownField";
import LoadingImage from "../Images/icon2.png";
import Search from "../Images/Search-icon.png"
import { Input } from "antd";
import "antd/dist/reset.css";

const { Content } = Layout;

const Home = () => {
    const navigate = useNavigate();
    const [selectedRoles, setSelectedRoles] = useState({});
    const [selectedValues, setSelectedValues] = useState({});
    const [loadingRoles, setLoadingRoles] = useState({});
    const [imageError, setImageError] = useState({});
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const { loading: accountsLoading, error: accountsError, data: accountsData } = useQuery(Accounts_Query);

    const [fetchRoles] = useLazyQuery(Role_Account_Query, {
        onCompleted: (data) => {
            const accountId = data.getAllJobrole[0]?.account?.id || data.getAllJobrole?.accountId;
            if (accountId) {
                setSelectedRoles((prevRoles) => ({
                    ...prevRoles,
                    [accountId]: data.getAllJobrole,
                }));
                setLoadingRoles((prev) => ({
                    ...prev,
                    [accountId]: false,
                }));
            }
        },
        onError: (error) => {
            console.error("Error fetching roles:", error);
        },
    });

    const handleImageError = (userId) => {
        setImageError((prev) => ({
            ...prev,
            [userId]: true, // Set error flag for this image
        }));
    };

    const handleAccountChange = (accountId) => {
        if (!selectedRoles[accountId]) {
            fetchRoles({
                variables: { accountId: parseInt(accountId) },
            });
        }
    };

    const handleRoleChange = (value, accountId) => {
        setSelectedValues((prev) => ({
            ...prev,
            [accountId]: value,
        }));
    };

    const handleEdit = (accountId, userData) => {
        const selectedRole = selectedValues[accountId];
        navigate(`/create?accountId=${accountId}&roleId=${selectedRole}`, {
            state: {
                id: userData.id,
                name: userData.name,
                url: userData.url,
                address: userData.address,
                address2: userData.address2,
                brandColor: userData.brandColor,
                lenseNeeded: userData.lenseNeeded,
                dartsNeeded: userData.dartsNeeded,
                clanNeeded: userData.clanNeeded,
                emailLogin: userData.emailLogin,
                numberLogin: userData.numberLogin,
                imageUrl: userData.imageUrl,
                mainLogoUrl: userData.mainLogoUrl,
                selfRegistration: userData.selfRegistration,
            },
        });
    };


    const viewRole = () => {
        navigate("./view-role")
    }

    if (accountsLoading)
        return (
            <div className="loading-container">
                <div className="spinner"></div>
            </div>
        );

    if (accountsError) return <div>Error: {accountsError.message}</div>;

    // Filter cards based on the search query
    const filteredAccounts = accountsData?.allUserAccount?.filter((user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()))
        ?.reverse();

    return (
        <Content>
            <div className="home-content">

                {/* <img
                        src={Search} // The search icon image
                        alt="Search"
                        className="searchicon"
                    /> */}
                <div className="add-roles-container">
                    <div>
                        <span style={{ fontFamily: "Inter" }}>Dashboard</span> <span>&gt; </span>
                        <span className="bold-text">Clients</span>
                    </div>

                    <button className="add-roles-button" onClick={() => navigate("/create")}>
                        Add Clients
                    </button>
                </div>

                <input
                    type="text"
                    className="searchinput"
                    placeholder="Search client..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />

                <div className="cards-container">
                    {filteredAccounts?.map((user) => {
                        const isRoleSelected = Boolean(selectedValues[user.id]);
                        const hasNoRoles = user.numberOfRoles === 0;

                        return (

                            <div key={user.id} className="card">
                                <div className="card-header">
                                    {imageError[user.id] || !user.mainLogoUrl ? (
                                        <div className="placeholderImage">
                                            <span className="placeholder-text">No Image</span>
                                        </div>
                                    ) : (
                                        <img
                                            src={user.mainLogoUrl}
                                            alt={user.name}
                                            className="card-image"
                                            onError={() => handleImageError(user.id)} // Handle error
                                        />
                                    )}
                                    <img
                                        src={Editicon}
                                        alt="Edit"
                                        className="edit-icon"
                                        onClick={() => handleEdit(user.id, user)} // Edit button triggers handleEdit
                                    />
                                </div>
                                <h3 className="card-title">{user.name}</h3>

                                <div className="card-actions">
                                    {loadingRoles[user.id] ? (
                                        <img
                                            src={LoadingImage}
                                            className="loading-image"
                                        />
                                    ) : (
                                        <SearchableDropdown
                                            options={selectedRoles[user.id] || []}
                                            label="name"
                                            id="id"
                                            selectedVal={selectedValues[user.id]}
                                            handleChange={(value) => handleRoleChange(value, user.id)}
                                            placeholder={
                                                hasNoRoles
                                                    ? "No roles available"
                                                    : "Select Role"
                                            }
                                            onFocus={() => handleAccountChange(user.id)} // Call fetchRoles on focus
                                            disabled={loadingRoles[user.id] || hasNoRoles}
                                        />
                                    )}
                                    <button
                                        className={`view-role-button`}
                                        onClick={() => {
                                            const selectedRoleId = selectedValues[user.id];
                                            if (selectedRoleId) {
                                                // Find the selected role object from selectedRoles array
                                                const selectedRoleData = selectedRoles[user.id]?.find(
                                                    role => role.id === selectedRoleId
                                                );

                                                if (selectedRoleData) {
                                                    navigate("/view-role", {
                                                        state: {
                                                            roleId: selectedRoleId,
                                                            dartsNeeded: selectedRoleData.dartsNeeded,
                                                            clanNeeded: selectedRoleData.clanNeeded,
                                                            lenseNeeded: selectedRoleData.lenseNeeded || false,
                                                            emailLogin: selectedRoleData.emailLogin,
                                                            inputEditDays: selectedRoleData.inputEditDays,
                                                            mandatoryTimeDistribution: selectedRoleData.mandatoryTimeDistribution,
                                                            numberLogin: selectedRoleData.numberLogin,
                                                            name: selectedRoleData.name,
                                                            pocEmail: selectedRoleData.pocEmail || "",
                                                            pocName: selectedRoleData.pocName || "",
                                                            pocNumber: selectedRoleData.pocContactNumber || "",
                                                            targetEditDays: selectedRoleData.targetEditDays,
                                                            dartsFormNeeded: selectedRoleData.dartsFormNeeded,
                                                            accountId: selectedRoleData.account.id
                                                        },
                                                    });
                                                }
                                            }
                                        }}
                                        disabled={!selectedValues[user.id] || hasNoRoles}
                                        style={{
                                            backgroundColor: selectedValues[user.id]
                                                ? "#C12328"
                                                : hasNoRoles
                                                    ? "#dcdcdc"
                                                    : "#FAC9CA",
                                            cursor: selectedValues[user.id]
                                                ? "pointer"
                                                : "not-allowed",
                                        }}
                                    >
                                        View Role
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Content>
    );
};

export default Home;
