import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./targetFunnelPreview.css";
import Funnel1 from "../Images/funnel1.png";
import Funnel2 from "../Images/funnel2.png";
import Funnel3 from "../Images/funnel3.png";
import Funnel4 from "../Images/funnel4.png";
import Funnel5 from "../Images/funnel5.png";
import Funnel6 from "../Images/funnel6.png";
import Funnel7 from "../Images/funnel7.png";
import Funnel8 from "../Images/funnel8.png";
import Funnel9 from "../Images/funnel9.png";
import Funnel10 from "../Images/funnel10.png";
import Funnel11 from "../Images/funnel11.png";
import Funnel12 from "../Images/funnel12.png";
import Funnel13 from "../Images/funnel13.png";
import Funnel14 from "../Images/funnel14.png";
import Funnel15 from "../Images/funnel15.png";
import Funnel16 from "../Images/funnel16.png";
import Funnel17 from "../Images/funnel17.png";
import Funnel18 from "../Images/funnel18.png";
import Funnel19 from "../Images/funnel19.png";
import Funnel20 from "../Images/funnel20.png";
import RedLine1 from "../Images/red-line1.png";
import RedLine2 from "../Images/red-line2.png";
import RedLine3 from "../Images/red-line3.png";
import RedLine4 from "../Images/red-line4.png";
import RedLine5 from "../Images/red-line5.png";
import RedLine6 from "../Images/red-line6.png";
import RedLine7 from "../Images/red-line7.png";
import RedLine8 from "../Images/red-line8.png";
import RedLine9 from "../Images/red-line9.png";
import RedLine10 from "../Images/red-line10.png";
import RedLine11 from "../Images/red-line11.png";
import RedLine12 from "../Images/red-line12.png";
import RedLine13 from "../Images/red-line13.png";
import RedLine14 from "../Images/red-line14.png";
import RedLine15 from "../Images/red-line15.png";
import RedLine16 from "../Images/red-line16.png";
import RedLine17 from "../Images/red-line17.png";
import RedLine18 from "../Images/red-line18.png";
import RedLine19 from "../Images/red-line19.png";
import RedLine20 from "../Images/red-line20.png";
import RedLine21 from "../Images/red-line21.png";

const TargetFunnelPreview = () => {
    const navigate = useNavigate();

    const stages = [
        { name: "CV Sourced", target: 1024, funnel: Funnel1, redLine: RedLine1,  },
        { name: "Call Conducted", target: 512, funnel: Funnel2, redLine: RedLine2,  },
        { name: "Candidate Assessed", target: 128, funnel: Funnel3, redLine: RedLine3,  },
        { name: "Interview Scheduled", target: 32, funnel: Funnel4, redLine: RedLine4,  },
        { name: "Interview Conducted", target: 8, funnel: Funnel5, redLine: RedLine5,  },
        { name: "Offers", target: 4, funnel: Funnel6, redLine: RedLine6,  },
        { name: "Joiners", target: 2, funnel: Funnel7, redLine: RedLine7,  },
        { name: "Joiners", target: 2, funnel: Funnel8, redLine: RedLine8,  },
        { name: "Joiners", target: 2, funnel: Funnel9, redLine: RedLine9,  },
        { name: "Joiners", target: 2, funnel: Funnel10, redLine: RedLine10,  },
        { name: "Joiners", target: 2, funnel: Funnel11, redLine: RedLine11,  },
        { name: "Joiners", target: 2, funnel: Funnel12, redLine: RedLine12,  },
        { name: "Joiners", target: 2, funnel: Funnel13, redLine: RedLine13,  },
        { name: "Joiners", target: 2, funnel: Funnel14, redLine: RedLine14,  },
        { name: "Joiners", target: 2, funnel: Funnel15, redLine: RedLine15,  },
        { name: "Joiners", target: 2, funnel: Funnel16, redLine: RedLine16,  },
        { name: "Joiners", target: 2, funnel: Funnel17, redLine: RedLine17,  },
        { name: "Joiners", target: 2, funnel: Funnel18, redLine: RedLine18,  },
        { name: "Joiners", target: 2, funnel: Funnel19, redLine: RedLine19,  },
        { name: "Joiners", target: 2, funnel: Funnel20, redLine: RedLine20,  },
    ];

    return (
        <div className="target-funnel-preview">
            <h2>Target Funnel Preview</h2>
            <div className="funnel-container">
                {stages.map((stage, index) => (
                    <div key={`stage-${index}`} className="funnel-row">
                        {/* Funnel Image */}
                        <div className="funnel-stage">
                            <img src={stage.funnel} alt={stage.name} className="funnel-image" />
                        </div>

                        {/* Red Line */}
                        <div className="red-line-container">
                            <img src={stage.redLine} alt="Red Line" className="red-line-image" />
                        </div>

                        {/* Input Container */}
                        <div className="input-container">
                            <div
                                className="red-line"
                                style={{ width: stage.width }}
                            ></div>
                            <input
                                type="text"
                                className="stage-input"
                                placeholder={stage.name}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <div className="funnel-actions">
                <button className="back-btn" onClick={() => navigate(-1)}>Back</button>
                <button className="proceed-btn">Proceed</button>
            </div>
        </div>
    );
};

export default TargetFunnelPreview;