import React, { useState, useRef, useCallback, useEffect } from "react";
import { Modal, Upload, message } from "antd";
import Cropper from "react-easy-crop";
import { Upload_Document } from "../services/userAPI";
import { useMutation } from "@apollo/client";
import CreateClientImage from "../Images/add-new-profile-photo.png";

const ImageUploadCrop = ({ onImageUpdate, initialImage, resetTrigger }) => {
    const [cropModalVisible, setCropModalVisible] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [imageSrc, setImageSrc] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(initialImage); // Store uploaded image
    const imageRef = useRef(null);

    const [uploadDocument] = useMutation(Upload_Document, {
        onCompleted: (data) => {
            if (data.uploadDocument.success) {
                const filename = data.uploadDocument?.data?.data?.filename;
                const imageUrl = data.uploadDocument?.data?.data?.url;

                console.log("S3 returned filename:", filename);
                onImageUpdate(imageUrl, filename);
                setUploadedImage(imageUrl); // Update UI preview
                message.success("Image uploaded successfully!");
            } else {
                message.error("Failed to upload image: " + data.uploadDocument.message);
            }
        },
        onError: (error) => {
            message.error("Upload failed: " + error.message);
        },
    });

    const handleImageChange = async ({ file }) => {
        if (!file) {
            message.error("Invalid file object.");
            return;
        }

        const allowedFormats = ["image/jpeg", "image/png"];
        const actualFile = file.originFileObj || file;

        if (!allowedFormats.includes(actualFile.type)) {
            message.error("Invalid file format. Only JPEG and PNG are allowed.");
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImageSrc(reader.result);
            setCropModalVisible(true);
        };
        reader.readAsDataURL(actualFile);
    };

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const getCroppedImage = async () => {
        try {
            const canvas = document.createElement("canvas");
            const image = new Image();
            image.src = imageSrc;

            await new Promise((resolve) => {
                image.onload = resolve;
            });

            canvas.width = croppedAreaPixels.width;
            canvas.height = croppedAreaPixels.height;
            const ctx = canvas.getContext("2d");

            ctx.drawImage(
                image,
                croppedAreaPixels.x,
                croppedAreaPixels.y,
                croppedAreaPixels.width,
                croppedAreaPixels.height,
                0,
                0,
                croppedAreaPixels.width,
                croppedAreaPixels.height
            );

            const croppedImageUrl = canvas.toDataURL("image/jpeg"); // Convert to Data URL

            setImageSrc(croppedImageUrl); // Update UI preview

            // Upload to server
            const croppedBlob = await new Promise((resolve) => {
                canvas.toBlob(resolve, "image/jpeg", 0.95);
            });

            const uniqueFilename = `cropped-image-${Date.now()}.jpg`;
            const croppedFile = new File([croppedBlob], uniqueFilename, { type: "image/jpeg" });

            await uploadDocument({
                variables: {
                    s3folder: "COMPANY_LOGO",
                    document: croppedFile,
                },
                context: {
                    useMultipart: true,
                },
            });

            setCropModalVisible(false);
        } catch (error) {
            console.error("Error processing/uploading cropped image:", error);
            message.error("Failed to process or upload the cropped image");
        }
    };

    const resetImageUpload = () => {
        setImageSrc(null);
        setUploadedImage(null);
        setCroppedAreaPixels(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
    };

    // 🔹 Reset image on `resetTrigger` change
    useEffect(() => {
        if (resetTrigger) {
            resetImageUpload();
        }
    }, [resetTrigger]);

    return (
        <>
            <Upload
                name="clientImage"
                showUploadList={false}
                customRequest={handleImageChange}
                accept="image/*"
            >
                <div className="global-admin-image-upload-circle">
                    {imageSrc ? (
                        <img ref={imageRef} src={imageSrc} alt="Uploaded" className="uploaded-image" />
                    ) : uploadedImage ? (
                        <img ref={imageRef} src={uploadedImage} alt="Existing Image" className="uploaded-image" />
                    ) : (
                        <img src={CreateClientImage} alt="Upload Icon" className="upload-icon" />
                    )}
                </div>
            </Upload>

            <Modal
                open={cropModalVisible}
                onCancel={() => {
                    resetImageUpload();
                    setCropModalVisible(false);
                }}
                onOk={getCroppedImage}
                title="Crop Image"
                okText="Save"
                cancelText="Cancel"
            >
                <div style={{ position: "relative", height: "300px" }}>
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </div>
            </Modal>
        </>
    );
};

export default ImageUploadCrop;
