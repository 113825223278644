import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Table, Button, Input, Popconfirm, Card, Modal, message } from "antd";
import { SearchOutlined, CheckOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { List_Employement_Type_From_Role, Add_Update_Employement_Type } from "../services/userAPI";
import DeleteIcon from "../Images/user-management-delete-icon.png";

const EmployementTypeConfiguration = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newEmploymentType, setNewEmploymentType] = useState("");
    const [editingKey, setEditingKey] = useState(null);
    const [editedName, setEditedName] = useState(""); // For storing edited name
    const location = useLocation();
    const roleId = location.state?.roleId;
    const inputRef = useRef(null);

    const { loading, error, data, refetch } = useQuery(List_Employement_Type_From_Role, {
        variables: { jobRoleId: roleId },
    });

    useEffect(() => {
        if (isModalVisible && inputRef.current) {
            // Add a small delay to ensure the modal is fully rendered
            setTimeout(() => {
                inputRef.current.focus();
            }, 100);
        }
    }, [isModalVisible]);

    const handleEdit = (record) => {
        setEditingKey(record.key);
        setEditedName(record.designation);
    };

    const [employmentTypes, setEmploymentTypes] = useState([]);

    useEffect(() => {
        if (data) {
            setEmploymentTypes(data.listEmployementTypeFromRole || []);
        }
    }, [data]);

    const filteredData = employmentTypes.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const paginationText = filteredData.length > 0
        ? `${(currentPage - 1) * pageSize + 1} - ${Math.min(currentPage * pageSize, filteredData.length)} of ${filteredData.length}`
        : "No Data";

    const [addUpdateEmployementType, { loading: addLoading }] = useMutation(Add_Update_Employement_Type);

    const handleAddEmploymentType = async () => {
        if (!newEmploymentType.trim()) {
            message.error("Please enter an employment type.");
            return;
        }
        try {
            const response = await addUpdateEmployementType({
                variables: { employementType: newEmploymentType, jobRoleId: roleId },
            });
            if (response.data.addUpdateEmployementType.success) {
                message.success("Employment Type added successfully!");
                setIsModalVisible(false);
                setNewEmploymentType("");
                refetch();
            } else {
                message.error(response.data.addUpdateEmployementType.message);
            }
        } catch (error) {
            console.error("Error adding employment type:", error);
            message.error("Failed to add employment type.");
        }
    };

    const columns = [
        {
            title: "Employment Type",
            dataIndex: "name", // Adjusted based on GraphQL response
            key: "name",
            render: (text, record) => (
                editingKey === record.id ? (
                    <Input
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        onPressEnter={() => handleSave(record.id)}
                    />
                ) : (
                    text
                )
            ),
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    {editingKey === record.id ? (
                        <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={() => handleSave(record.id)}
                            style={{ marginRight: 8 }}
                        />
                    ) : (
                        <>
                            <Popconfirm
                                title="Are you sure you want to delete this?"
                                onConfirm={() => handleDelete(record.id, record.name, record.roleId)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <img src={DeleteIcon} alt="Delete" style={{ cursor: "pointer" }} />
                            </Popconfirm>
                        </>
                    )}
                </div>
            ),
        },
    ];

    const handleDelete = async (id, name, roleId) => {
        try {
            const response = await addUpdateEmployementType({
                variables: {
                    employmentTypeId: id,
                    employementType: name, // Keep the name as it is
                    jobRoleId: roleId,
                    status: 0, // Update status to 0 (soft delete)
                },
            });

            if (response.data.addUpdateEmployementType.success) {
                message.success("Employment Type deleted successfully!");
                refetch(); // Refresh the table
            } else {
                message.error(response.data.addUpdateEmployementType.message);
            }
        } catch (error) {
            console.error("Error deleting employment type:", error);
            message.error("Failed to delete employment type.");
        }
    };



    const handleSave = (key) => {
        const updatedData = paginatedData.map((item) =>
            item.key === key ? { ...item, designation: editedName } : item
        );
        console.log("Updated designation:", editedName);
        setEditingKey(null); // Reset the editing state
    };



    return (
        <div>
            <Card className="search-card">
                <label style={{ fontFamily: "Inter", fontSize: "20px" }}>Employement Type</label>

                <div className="search-container">
                    <div className="search-group">
                        <label>Search</label>
                        <div className="search-input-wrapper">
                            <Input
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search Employment Type"
                                suffix={<SearchOutlined />}
                            />
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="button-group" style={{ marginTop: "20px" }}>
                        <button className="add-user-btn" onClick={() => setIsModalVisible(true)}>
                            Add Employment
                        </button>
                    </div>
                </div>
                <br />
                <Table
                    columns={columns}
                    dataSource={paginatedData}
                    pagination={false}
                    loading={loading}
                />

                <div className="pagination-controls">
                    <span className="pagination-text">{paginationText}</span>
                    <button
                        className="pagination-button"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        &lt;
                    </button>
                    <button
                        className="pagination-button"
                        // onClick={handleNextPage}
                        disabled={currentPage * pageSize >= filteredData.length} onClick={() => setCurrentPage(currentPage + 1)}
                    >
                        &gt;
                    </button>
                </div>
            </Card>

            <Modal
                title="Add New Employement"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <div>
                    <Input
                        ref={inputRef}
                        value={newEmploymentType}
                        onChange={(e) => setNewEmploymentType(e.target.value)}
                        placeholder="Enter Employment Type"
                        autoFocus={true}
                    />
                    <div style={{ marginTop: "20px", textAlign: "right" }}>
                        <button className="masters-configuration-cancel-button" onClick={() => setIsModalVisible(false)} style={{ marginRight: 8 }}>
                            Cancel
                        </button>
                        <button className="masters-configuration-add-button" onClick={handleAddEmploymentType} loading={addLoading}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>

        </div>
    );
};

export default EmployementTypeConfiguration;
