"use client";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { AgCharts } from "ag-charts-react";
import "ag-charts-community";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";

import "./agGrids.css";
import {
    ClientSideRowModelModule,
    CsvExportModule,
    ModuleRegistry,
    NumberFilterModule,
    TextFilterModule,
    ValidationModule,
} from "ag-grid-community";

import {
    ColumnMenuModule,
    ContextMenuModule,
    ExcelExportModule,
    MultiFilterModule,
    SetFilterModule,
    SideBarModule,
    GridChartsModule,
    RangeSelectionModule,
    RowGroupingModule,
    MenuModule,
    FiltersToolPanelModule,
    ColumnsToolPanelModule,
    StatusBarModule,
    // GridChartsModule,
    // RangeSelectionModule,
    RichSelectModule,
} from "ag-grid-enterprise";
import { Padding } from "@mui/icons-material";

// ✅ Register AG Grid modules
ModuleRegistry.registerModules([
    TextFilterModule,
    NumberFilterModule,
    ClientSideRowModelModule,
    CsvExportModule,
    ExcelExportModule,
    ColumnMenuModule,
    ContextMenuModule,
    MultiFilterModule,
    SetFilterModule,
    ValidationModule,
    SideBarModule,
    GridChartsModule,
    RangeSelectionModule,
    RowGroupingModule,
    MenuModule,
    FiltersToolPanelModule,
    ColumnsToolPanelModule,
    StatusBarModule,
    GridChartsModule,
    RangeSelectionModule,
    RichSelectModule,
    SetFilterModule,
    MultiFilterModule,
    SideBarModule

]);

const GridExample = () => {
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%", Padding: "20px" }), []);
    const gridStyle = useMemo(() => ({
        height: "600px",
        width: "100%",
        padding: "20px",
        border: "1px solid #ddd", // Add border for better visibility
        borderRadius: "8px", // Rounded corners
        background: "#fff", // White background
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", // Soft shadow for better appearance
    }), []);
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    // ✅ Fetch data from API
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(
    //                 "https://churntesting.clanonline.in/reports/get_data_after_marts?input_id=67a45bc5cf384697019f644b"
    //             );
    //             const data = await response.json();

    //             // ✅ Transform API response to match AG Grid format
    //             const formattedData = data.map((employee) => ({
    //                 employee_id: employee.employee_id,
    //                 name: employee["people data mart"]["Employee Name"],
    //                 gender: employee["people data mart"]["Gender"],
    //                 dob: employee["people data mart"]["Date of Birth"],
    //                 age: employee["people data mart"]["Age as on last date of study - in years"],
    //                 status: employee["people data mart"][" Employee work  status on the last date of study"],
    //                 designation: employee["people data mart"]["Designation at the beginning of the study"],
    //                 branch: employee["people data mart"]["Branch name"],
    //                 city: employee["people data mart"]["Current City"],
    //                 state: employee["people data mart"]["State"],
    //                 zone: employee["people data mart"]["Zone"],
    //                 supervisor_name: employee["people data mart"][" Name of Immediate Supervisor in the role "],
    //                 product_department: employee["people data mart"]["Product Department"],
    //                 residency_months: employee["people data mart"]["Residency months"],
    //             }));

    //             setRowData(formattedData);

    //             // setChartOptions({
    //             //     data: formattedData,
    //             //     series: [
    //             //         {
    //             //             type: "bar",
    //             //             xKey: "name",
    //             //             yKey: "age",
    //             //             label: {
    //             //                 enabled: true,
    //             //             },
    //             //         },
    //             //     ],
    //             //     title: {
    //             //         text: "Employee Age Distribution",
    //             //     },
    //             // });
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };

    //     fetchData();
    // }, []);

    const defaultColDef = {
        // flex: 1,
        resizable: true,
        sortable: true,
        filter: true,
        // minWidth: 300,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        // suppressMenu: true,
        floatingFilter: true,
        cellStyle: { padding: "10px" },
    };


    // Add specific grid options
    const gridOptions = {
        pivotMode: false, // Start with pivot mode off
        sideBar: {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: false,
                        suppressValues: false,
                        suppressPivots: false,
                        suppressPivotMode: false,
                    }
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                }
            ]
        }
    };

    const formatDateString = (value) => {
        if (typeof value === 'string' && value.includes('T')) {
            return value.split
                ('T')[0];
        }
        return value;
    };

    const onGridReady = (params) => {
        gridRef.current = params.api;

        // Check if columnApi is available before proceeding
        if (!params.columnApi) {
            console.warn("columnApi is undefined");
            return;
        }

        setTimeout(() => {
            const allColumnIds = params.columnApi.getAllColumns()?.map(column => column.getId()) || [];
            if (allColumnIds.length > 0) {
                params.columnApi.autoSizeColumns(allColumnIds);
            }
        }, 100); // Small delay to ensure grid is ready
    };

    useEffect(() => {
        if (gridRef.current && gridRef.current.columnApi) {
            const allColumnIds = gridRef.current.columnApi.getAllColumns()?.map(col => col.getId()) || [];
            if (allColumnIds.length > 0) {
                gridRef.current.columnApi.autoSizeColumns(allColumnIds);
            }
        }
    }, [rowData]); // Trigger when row data updates

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    "https://churntesting.clanonline.in/reports/get_data_after_marts?input_id=67a45bc5cf384697019f644b"
                );
                const data = await response.json();

                if (data.length === 0) return; // No data, skip processing

                // ✅ Extract first object to determine column structure
                // const firstEmployee = data[0];
                // const transformedData = data.map(emp => {
                //     let flatObj = { employee_id: emp.employee_id };
                //     Object.entries(emp["people data mart"]).forEach(([key, value]) => {
                //         flatObj[key] = value;
                //     });
                //     return flatObj;
                // });

                // const transformedData = data.map(emp => {
                //     let flatObj = { employee_id: emp.employee_id }; // Keep employee_id separately
                //     const nestedObjectKey = Object.keys(emp).find(key => typeof emp[key] === "object");

                //     if (nestedObjectKey) {
                //         Object.entries(emp[nestedObjectKey]).forEach(([key, value]) => {
                //             flatObj[key] = value;
                //         });
                //     }

                //     return flatObj;
                // });


                let transformedData = [];
                let columnGroups = {};

                data.forEach((emp) => {
                    let flatObj = { employee_id: emp.employee_id };

                    Object.keys(emp).forEach((key) => {
                        if (typeof emp[key] === "object" && emp[key] !== null) {
                            if (key.toLowerCase().includes("calendar month")) {
                                // Grouping month-based data
                                if (!columnGroups["Performance Metrics"]) columnGroups["Performance Metrics"] = [];

                                Object.entries(emp[key]).forEach(([month, value]) => {
                                    let columnName = `${month} (${key.replace("calendar month ", "")})`;
                                    // if (typeof value === "string" && value.includes("T")) {
                                    //     value = value.split("T")[0];
                                    // }
                                    flatObj[columnName] = value;
                                    if (!columnGroups["Performance Metrics"].includes(columnName)) {
                                        columnGroups["Performance Metrics"].push(columnName);
                                    }
                                });
                            } else {
                                // Other nested objects
                                Object.entries(emp[key]).forEach(([nestedKey, value]) => {
                                    flatObj[nestedKey] = formatDateString(value);
                                    if (!columnGroups[key]) columnGroups[key] = [];
                                    if (!columnGroups[key].includes(nestedKey)) {
                                        columnGroups[key].push(nestedKey);
                                    }
                                });
                            }
                        } else {
                            flatObj[key] = formatDateString(emp[key]);
                        }
                    });
                    if (!columnGroups["General Info"]) {
                        columnGroups["General Info"] = [];
                    }
                    if (!columnGroups["General Info"].includes("employee_id")) {
                        columnGroups["General Info"].unshift("employee_id");
                    }
                    transformedData.push(flatObj);
                });

                // ✅ Convert columnGroups to AG Grid columnDefs
                let dynamicColumns = Object.keys(columnGroups).map((group) => ({
                    headerName: group,
                    children: columnGroups[group].map((field) => ({
                        field,
                        headerName: field.replace(/_/g, " "),
                        filter: isNaN(transformedData[0][field]) ? "agTextColumnFilter" : "agNumberColumnFilter",
                        sortable: true,
                        floatingFilter: true,
                        resizable: true,
                        enablePivot: true,
                        enableRowGroup: true,
                        enableValue: true,
                        aggFunc: isNaN(transformedData[0][field]) ? 'count' : 'sum',
                        // Enable charting for this column
                        chartDataType: isNaN(transformedData[0][field]) ? 'category' : 'series',
                        // Allow range selection
                        enableRangeSelection: true,
                        // Enable cell data types
                        valueFormatter: (params) => {
                            if (typeof params.value === 'number') {
                                return params.value.toLocaleString();
                            }
                            return params.value;
                        }
                    })),
                }));

                setRowData(transformedData);
                setColumnDefs(dynamicColumns);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);



    const sideBar = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: false,
                    suppressValues: false,
                    suppressPivots: false,
                    suppressPivotMode: false
                }
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel'
            },
            {
                id: "charts",
                labelDefault: "Charts",
                labelKey: "charts",
                iconKey: "chart",
                toolPanel: "agChartsToolPanel"
            }
        ],
        position: 'right',
        defaultToolPanel: 'columns'
    };

    // Add chart configuration
    const chartThemeOverrides = {
        common: {
            title: {
                enabled: true,
                fontSize: 14,
            },
            legend: {
                position: 'bottom',
            },
        }
    };


    return (
        <div style={containerStyle}>
            <div className="ag-theme-alpine" style={gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    {...gridOptions}
                    pagination={true}
                    paginationPageSize={10}
                    rowSelection="multiple"
                    enableRangeSelection={true}
                    animateRows={true}
                    onGridReady={onGridReady}
                    enableCharts={true}
                    sideBar={sideBar}
                    groupDisplayType="multipleColumns"
                    rowGroupPanelShow="always"
                    pivotPanelShow="always"
                    chartThemeOverrides={chartThemeOverrides}
                    statusBar={{
                        statusPanels: [
                            { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                            { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                            { statusPanel: 'agFilteredRowCountComponent' },
                            { statusPanel: 'agSelectedRowCountComponent' },
                            { statusPanel: 'agAggregationComponent' }
                        ]
                    }}
                    enableRangeHandle={true}
                    suppressMultiRangeSelection={false}
                    allowDragFromColumnsToolPanel={true}
                // pivotMode={true}
                // sideBar={{ toolPanels: ["columns", "filters"] }}
                // sideBar={{
                //     toolPanels: [
                //         {
                //             id: "filters",
                //             labelDefault: "Filters",
                //             labelKey: "filters",
                //             iconKey: "filter",
                //             toolPanel: "agFiltersToolPanel",
                //             toolPanelParams: {
                //                 suppressColumnExpandAll: false,
                //                 suppressColumnFilter: false,
                //                 suppressColumnSelectAll: false,
                //                 suppressColumnExpand: false,
                //             },
                //         },
                //     ],
                //     defaultToolPanel: "filters", // Auto-open filter panel
                // }}
                />
            </div>
        </div>
    );
};

export default GridExample;
