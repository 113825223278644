import React, { useState, useRef, useEffect } from "react";
import { Form, message, Layout, Row, Col, Upload, Checkbox, Modal, Input } from "antd";
import { useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { validationRegexAddress, validationRegexAmount, validationRegexOnlyAlpha } from "../regex/regex";
import { Account_Form_Query, Role_Account_Query, Get_Lense_Account_Projects, Upload_Document, update_Account, Role_Management, Get_All_Job_Role, Delete_Account, Delete_Job_Role } from "../services/userAPI"; // Import your mutation
import TextInputField from "../reusableField/TextInputField2";
import "./createClient.css";
import { ChromePicker } from "react-color";
import { useNavigate } from "react-router-dom";
import CreateClientImage from "../Images/createClientImage.png"
import AddRoleIcon from "../Images/Add_Role_icon.png"
import { Radio } from "antd";
import Lessthan from "../Images/lessthan_Icon.png"
import CreateIconPlus from "../Images/createplus_icon.png"
import DeleteButton from "../Images/deleteButton-icon.png"
import { Pencil } from "lucide-react";
import roleManagementEditIcon from "../Images/rolemanagementediticon.png";

const { Content } = Layout;
const pencilCursor = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjUgMy41TDIwLjUgNy41TTE2LjUgMy41TDQuNSAxNS41TDMuNSAyMC41TDguNSAxOS41TDIwLjUgNy41TTE2LjUgMy41WiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+`;
const CreateClient = () => {
    const { id } = useParams();
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const location = useLocation();
    const accountId = id || location.state?.id;
    const [isPickingColor, setIsPickingColor] = useState(false);
    const { projectsData: passedProjectsData } = location.state || {};
    const [uploadedFilename, setUploadedFilename] = useState(null);
    const [color, setColor] = useState("#FFAD01");
    const [selectedColor, setSelectedColor] = useState(null);
    const canvasRef = useRef(null);
    const [showPicker, setShowPicker] = useState(false);
    const [clientImage, setClientImage] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [role, setRole] = useState([]);
    const [form] = Form.useForm();
    const [errors, setErrors] = useState({})
    const [touched, setTouched] = useState({})
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingRoleId, setEditingRoleId] = useState(null);
    const imageRef = useRef(null);
    const [isToggled, setIsToggled] = useState(() => Boolean(location.state?.selfRegistration));
    const [showdeleteJobRoleModal, setShowDeleteJobRoleModal] = useState(false);
    const [showdeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [selectedRoleId, setSelectedRoleId] = useState(null);
    const [formData, setFormData] = useState({
        id: location.state?.id || "",
        name: location.state?.name || "",
        imageUrl: location.state?.imageUrl || "",
        mainLogoUrl: location.state?.mainLogoUrl || "",
        address: location.state?.address || "",
        role: location.state?.role || "",
        companyURL: location.state?.url || "",
        address2: location.state?.address2 || "",
        roles: [],
        selfRegistration: "",
        brandColor: location.state?.brandColor || "#FFAD01",
        mandatoryTimeDistribution: false,
        dartsForm: false,
        filename: "",
        imageUrl: "",
    });

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const validationRegexOnlyAlphaAddRole = /^[a-zA-Z\s]{2,30}$/;  // Letters and spaces, 2-30 characters
    const validationRegexMobileNoAddRole = /^[6-9]\d{9}$/;  // Indian mobile number format
    const emailRegexAddRole = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const urlRegex = /^(https:\/\/|www\.)[a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]+)*(\.[a-zA-Z]{2,})(:[0-9]+)?(\/[a-zA-Z0-9\-_#=&\/?]*)?$/;

    const customStyles = `
    .pencil-cursor {
      cursor: url(${pencilCursor}) 0 24, auto !important;
    }
  `;

    const normalizeColor = (inputColor) => {
        // If color is already a string and starts with #, return as is
        // Otherwise, prepend # if there's a color value
        return (typeof inputColor === "string" && inputColor.startsWith("#"))
            ? inputColor.toUpperCase()
            : `#${(inputColor || '').toUpperCase()}`;
    };

    const handleColorClick = () => {
        // Toggle picker visibility
        setShowPicker(prevState => !prevState);
        console.log("Color picker clicked, showPicker:", !showPicker);
    };

    const handleColorChange = (selectedColor) => {
        const newColor = selectedColor.hex;

        // Update both local state and form data
        setColor(newColor);
        setFormData(prevData => ({
            ...prevData,
            brandColor: newColor
        }));
    };

    const initializeCanvas = () => {
        if (!imageRef.current) return;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = imageRef.current;
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        ctx.drawImage(img, 0, 0);
        canvasRef.current = canvas;
    };

    const handleMouseMove = (e) => {
        if (!isPickingColor || !imageRef.current || !canvasRef.current) return;

        const img = imageRef.current;
        const rect = img.getBoundingClientRect();

        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;

        const actualX = Math.floor(x * scaleX);
        const actualY = Math.floor(y * scaleY);

        const ctx = canvasRef.current.getContext('2d');
        const pixelData = ctx.getImageData(actualX, actualY, 1, 1).data;

        const rgb = `rgb(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]})`;
        const hex = `#${pixelData[0].toString(16).padStart(2, '0')}${pixelData[1].toString(16).padStart(2, '0')}${pixelData[2].toString(16).padStart(2, '0')}`;

        setPosition({ x, y });
        setSelectedColor({ rgb, hex });
    };

    const startColorPicking = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPickingColor(true);
        initializeCanvas();
        if (imageRef.current) {
            imageRef.current.classList.add('color-picking-cursor');
            imageRef.current.classList.add('active-picker-border');
        }
    };

    const handleImageClick = (e) => {
        if (!isPickingColor) return;
        e.preventDefault();
        e.stopPropagation();

        if (selectedColor) {
            setFormData(prevData => ({
                ...prevData,
                brandColor: selectedColor.hex
            }));
        }
        setIsPickingColor(false);
        if (imageRef.current) {
            imageRef.current.classList.remove('color-picking-cursor');
            imageRef.current.classList.remove('active-picker-border');
        }
    };

    const { data: rolesData, loading: rolesLoading, error: rolesError, refetch: refetchRoles } = useQuery(Role_Account_Query, {
        variables: { accountId: parseInt(accountId) },
        skip: !accountId,
    });

    const showModal = () => {
        setIsModalVisible(true);
    };

    const roleManagementHandleCheckboxChange = (name) => {
        setFormData((prev) => {
            const updatedData = { ...prev, [name]: !prev[name] };
            form.validateFields(['productAccess']);

            return updatedData;
        });
    };

    const [addJobrole, { loading: addRoleLoading }] = useMutation(Role_Management, {
        onCompleted: (data) => {
            if (data.addJobrole.success) {
                message.success(isEditMode ? "Role updated successfully!" : "Role added successfully!");
                handleCancel();
                refetchRoles();
            } else {
                message.error(data.addJobrole.message || "Failed to update role");
            }
        },
        onError: (error) => {
            message.error("Something went wrong: " + error.message);
        },
    });

    useEffect(() => {
        setRole(rolesData?.getAllJobrole || []);
    }, [rolesData]);

    const addJobRoleHandleSubmit = async () => {
        try {
            const values = await form.validateFields();

            // Ensure at least one product access is selected
            if (!formData.lense && !formData.darts && !formData.clan) {
                message.error("Please select at least one product access!");
                return;
            }

            // If "Darts" or "Clan" is selected, a login method must be chosen
            if ((formData.darts || formData.clan) && !formData.numberLogin && !formData.emailLogin) {
                message.error("Please select a login method when Darts or Clan is selected!");
                return;
            }

            const newRole = {
                accountId: accountId,
                name: values["role name"],
                clanNeeded: formData.clan || false,
                dartsNeeded: formData.darts || false,
                dartsFormNeeded: false,
                pocName: values["POC name"],
                pocEmail: values["email"],
                pocNumber: values["contact no"],
                numberLogin: formData.numberLogin || false,
                emailLogin: formData.emailLogin || false,
                mandatoryTimeDistribution: false,
                targetEditDays: 0,
                inputEditDays: 0,
            };

            let response;
            if (isEditMode) {
                response = await addJobrole({
                    variables: {
                        ...newRole,
                        jobroleId: parseInt(editingRoleId),
                    },
                });
            } else {
                response = await addJobrole({
                    variables: newRole,
                });
            }

            if (response?.data?.addJobrole?.success) {
                message.success("Job role added successfully!");
                // Close the modal
                setIsModalVisible(false);
                setFormData((prevData) => ({
                    ...prevData,
                    lense: false,
                    darts: false,
                    clan: false,
                    numberLogin: false,
                    emailLogin: false
                }));

                form.resetFields();
                setIsEditMode(false);
                setEditingRoleId(null);
                refetchRoles();
            }
            //  else {
            //     // Show backend validation message
            //     message.error(response?.data?.addJobrole?.message || "Failed to add job role.");
            // }
        } catch (error) {
            message.error("Please fix the errors before submitting.");
        };
    };

    const handleEdit = (roleItem) => {

        setIsEditMode(true);
        setEditingRoleId(roleItem.id);
        setIsModalVisible(true);

        setFormData({
            ...formData,
            lense: roleItem.lenseNeeded || false,
            darts: roleItem.dartsNeeded || false,
            clan: roleItem.clanNeeded || false,
            numberLogin: roleItem.numberLogin || false,
            emailLogin: roleItem.emailLogin || false,
        });

        // Set form values
        form.setFieldsValue({
            "role name": roleItem.name,
            "POC name": roleItem.pocName,
            "contact no": roleItem.pocContactNumber,
            "email": roleItem.pocEmail,
            "loginMethod": roleItem.numberLogin ? "mobile" : roleItem.emailLogin ? "email" : ""
        });
    };

    const handleCancel = () => {
        form.resetFields();
        setErrors({});
        setFormData((prevData) => ({
            ...prevData, // Preserve existing form data
            darts: false, // Only update `darts`
        }));
        setIsModalVisible(false);
    };

    const handleDiscardClick = () => {
        navigate("/dashboard");
    };

    const handleToggle = () => {
        setIsToggled((prev) => !prev);
    };

    useEffect(() => {
        if (location.state?.selfRegistration !== undefined) {
            setIsToggled(location.state.selfRegistration);
        }
    }, [location.state?.selfRegistration]);

    const [uploadDocument] = useMutation(Upload_Document, {
        onCompleted: (data) => {
            if (data.uploadDocument.success) {
                const filename = data.uploadDocument?.data?.data?.filename;
                const imageUrl = data.uploadDocument?.data?.data?.url;
                setUploadedFilename(filename);

                setFormData((prevData) => ({
                    ...prevData,
                    imageUrl: imageUrl,
                }));
                message.success("Image uploaded successfully!");
            } else {
                message.error("Failed to upload image: " + data.uploadDocument.message);
            }
        },
        onError: (error) => {
            message.error("Upload failed: " + error.message);
        },
    });

    const navigate = useNavigate();

    const [addAccount, { error }] = useMutation(Account_Form_Query, {
        onCompleted: (data) => {
            message.success("Client created successfully!");
            navigate("/create");
        },
        onError: (error) => {
            message.error("Something went wrong");
        },
    });

    const handleImageChange = ({ file }) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setClientImage(e.target.result);
        };
        reader.readAsDataURL(file);


        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setClientImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }

        if (!file) {
            message.error("Invalid file object.");
            return;
        }

        const allowedFormats = ["image/jpeg", "image/png"];
        const actualFile = file.originFileObj || file;

        if (!actualFile) {
            message.error("No valid file detected.");
            return;
        }

        if (!allowedFormats.includes(actualFile.type)) {
            message.error("Invalid file format. Only JPEG and PNG are allowed.");
            return;
        }

        try {
            // Create a preview of the image
            const imageUrl = URL.createObjectURL(actualFile);
            setClientImage(imageUrl);

            setErrors((prevErrors) => ({
                ...prevErrors,
                clientImage: "", // Clear error for clientImage
            }));

            // Upload the actual file
            uploadDocument({
                variables: {
                    s3folder: "COMPANY_LOGO",
                    document: actualFile, // Pass the raw file object here
                },
                context: {
                    useMultipart: true, // Ensure the Apollo Client supports multipart file uploads
                },
            });
        } catch (error) {
            console.error("Error handling the file:", error);
            message.error("File upload failed.");
        }
    };

    const { data, loading } = useQuery(Get_Lense_Account_Projects, {
        variables: { clientId: String(accountId) },
        skip: !accountId && !passedProjectsData,
    });

    const projectsData = passedProjectsData || data?.getLenseAccountProjects;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setTouched((prevTouched) => ({
            ...prevTouched,
            [name]: true,
        }));

    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));

        setTouched((prevTouched) => ({
            ...prevTouched,
            projectAccess: true,
            [name]: true,
        }));

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };

            // If neither darts nor clan is selected, don't require loginMethod
            if ((formData.darts || formData.clan) && !formData.loginMethod) {
                newErrors.loginMethod = "Please select a login method (Mobile or Email) when Darts or Clan is selected.";
            } else if (!formData.darts && !formData.clan && !formData.loginMethod) {
                delete newErrors.loginMethod; // Allow submitting if darts/clan are not selected
            } else {
                delete newErrors.loginMethod; // Clear the loginMethod error
            }

            // Ensure at least one of emailLogin, numberLogin, or selfRegistration is selected
            if (!formData.emailLogin && !formData.numberLogin && !formData.selfRegistration) {
                newErrors.projectAccess = "At least one project access option should be selected.";
            } else {
                delete newErrors.projectAccess;
            }

            return newErrors;
        });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = "Enter client's name.";
        }

        if (!urlRegex.test(formData.companyURL)) {
            newErrors.companyURL = 'Please enter a valid company URL.';
        }

        if (!formData.address) {
            newErrors.address = " Enter office address.";
        }

        if (!clientImage && !formData.mainLogoUrl) {
            newErrors.clientImage = "Upload image.";
        }

        if (!formData.address2) {
            newErrors.address2 = "Enter office address 2.";
        }
        if ((formData.darts || formData.clan) && !formData.numberLogin && !formData.emailLogin) {
            newErrors.loginMethod = "Select mobile or email-id.";
        }

        // Ensure at least one of `lense`, `darts`, or `clan` is selected
        if (!formData.lense && !formData.darts && !formData.clan) {
            newErrors.projectAccess = "At least one project access option should be selected.";
        }


        if (!formData.roleName) {
            newErrors.roleName = "Enter role name.";
        }

        if (!formData.pocName) {
            newErrors.pocName = "Enter POC name.";
        }

        if (!formData.pocNumber) {
            newErrors.pocNumber = "Enter contact number.";
        }

        if (!emailRegex.test(formData.pocEmail)) {
            newErrors.pocEmail = 'Please enter a email-id.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const editvalidateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = "Enter client's name.";
        }

        if (!urlRegex.test(formData.companyURL)) {
            newErrors.companyURL = 'Please enter a valid company URL.';
        }

        if (!formData.address) {
            newErrors.address = " Enter office address.";
        }

        if (!clientImage && !formData.mainLogoUrl) {
            newErrors.clientImage = "Upload image.";
        }

        if (!formData.address2) {
            newErrors.address2 = "Enter office address 2.";
        }

        // if ((formData.darts || formData.clan) && !formData.numberLogin && !formData.emailLogin) {
        //     newErrors.loginMethod = "Select mobile or email-id.";
        // }

        // if (!formData.emailLogin && !formData.numberLogin && !formData.lense) {
        //     newErrors.projectAccess = "At least one project access option should be selected.";
        // }

        // if (!formData.roleName) {
        //     newErrors.roleName = "Enter role name.";
        // }

        // if (!formData.pocName) {
        //     newErrors.pocName = "Enter POC name.";
        // }

        // if (!formData.pocNumber) {
        //     newErrors.pocNumber = "Enter contact number.";
        // }

        // if (!emailRegex.test(formData.pocEmail)) {
        //     newErrors.pocEmail = 'Please enter a email-id.';
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [formData]);

    const [updateClient] = useMutation(update_Account, {
        onCompleted: (data) => {
            if (data.updateAccount.success) {
                message.success("Client updated successfully!");
                navigate("/create");
            } else {
                message.error(data.updateAccount.message || "Failed to update client");
            }
        },
        onError: (error) => {
            message.error("Something went wrong: " + error.message);
        },
    });

    useEffect(() => {
        return () => {
            if (clientImage) {
                URL.revokeObjectURL(clientImage);
            }
        };
    }, [clientImage]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const newTouched = {
            name: true,
            companyURL: true,
            address: true,
            address2: true,
            projectAccess: true,
            loginMethod: true,
            clientImage: true,
            roleName: true,
            pocName: true,
            pocNumber: true,
            pocEmail: true,
        };
        setTouched(newTouched);
        // Validate form
        if (validateForm()) {
            // Prepare payload for API submission
            const variables = {
                name: formData.name,
                url: formData.companyURL,
                address: formData.address,
                address2: formData.address2,
                brandColor: formData.brandColor,
                roleName: formData.roleName,
                pocName: formData.pocName,
                pocNumber: formData.pocNumber,
                pocEmail: formData.pocEmail,
                lenseNeeded: formData.lense || false,
                dartsNeeded: formData.darts || false,
                clanNeeded: formData.clan || false,
                loginMethod: formData.loginMethod,
                smallLogo: uploadedFilename,
                mainLogo: uploadedFilename,
                numberLogin: formData.numberLogin || false,
                emailLogin: formData.emailLogin || false,
                selfRegistration: isToggled, // Always false for self-registration
            };

            // If either numberLogin or emailLogin is selected, include loginMethod based on the selection
            if (formData.numberLogin || formData.emailLogin) {
                variables.loginMethod = formData.numberLogin ? "mobile" : "email";
            }

            // Call the API with the variables object
            addAccount({
                variables,
                onCompleted: (response) => {
                    if (response.addAccount.success) {
                        setFormData({
                            name: "",
                            companyURL: "",
                            address: "",
                            address2: "",
                            brandColor: "",
                            roleName: "",
                            pocName: "",
                            pocEmail: "",
                            pocNumber: "",
                            contactNumber: "",
                            emailId: "",
                            lense: false,
                            darts: false,
                            clan: false,
                            loginMethod: "",
                            numberLogin: false,
                            emailLogin: false,
                            selfRegistration: false,
                        });

                        setTouched({});
                        setErrors({});
                        setClientImage(null);
                        message.success("Client added successfully!");
                        navigate("/dashboard");
                    } else {
                        // Handle backend validation messages
                        message.error(response.addAccount.message || "Failed to add client.");
                    }
                },
                onError: (error) => {
                    // Extract backend error message
                    const backendError = error?.message || "There was an error adding the client.";
                    message.error(backendError);
                },
            });
        } else {
            message.error("Please fix the errors before submitting.");
        }
    };

    const handleLoginMethodChange = (e) => {
        const value = e.target.value;

        setFormData((prevFormData) => {
            const newFormData = { ...prevFormData };

            if (value === "mobile") {
                newFormData.numberLogin = true;
                newFormData.emailLogin = false;
            } else if (value === "email") {
                newFormData.numberLogin = false;
                newFormData.emailLogin = true;
            }

            return newFormData;
        });

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.loginMethod;  // Remove the error for loginMethod
            return newErrors;
        });
    };

    useEffect(() => {
        setRole(rolesData?.getAllJobrole || []);
    }, [rolesData]);

    const existingRoles = rolesData?.getAllJobrole || [];
    const combinedRoles = [
        ...existingRoles,
        ...role.filter(newRole =>
            !existingRoles.some(existingRole =>
                existingRole?.name === newRole?.name
            )
        )
    ];

    const onFinish = (e) => {
        e.preventDefault();

        // Ensure brandColor is properly formatted
        let brandColor = formData.brandColor || "#FFAD01";
        brandColor = brandColor.startsWith("#") ? brandColor : `#${brandColor}`;

        // Set touched fields for validation
        const newTouched = {
            name: true,
            companyURL: true,
            address: true,
            address2: true,
            clientImage: true,

        };

        setTouched(newTouched);

        if (editvalidateForm()) {
            const mainLogoPath = uploadedFilename ? uploadedFilename : null;  // Send null if image is not updated
            const smallLogoPath = uploadedFilename ? uploadedFilename : null; // Send
            const variables = {
                accountId: parseInt(accountId),
                name: formData.name,
                url: formData.companyURL,
                address: formData.address,
                address2: formData.address2,
                brandColor: brandColor,
                mainLogo: mainLogoPath,
                smallLogo: smallLogoPath,
                emailLogin: false,
                numberLogin: false,
                selfRegistration: isToggled,
            };

            updateClient({

                variables: variables,
            })
                .then(() => {
                    setTouched({});
                    setErrors({});
                    setClientImage(null);
                    navigate("/dashboard");
                })
                .catch((error) => {
                    console.error("Error updating client:", error);
                    message.error("There was an error updating the client.");
                });
        } else {
            message.error("Please fix the errors before submitting.");
        }
    };

    useEffect(() => {
        return () => {
            if (clientImage) {
                URL.revokeObjectURL(clientImage);
            }
        };
    }, [clientImage]);

    const [deleteAccount, { deleteAccountloading, deleteAccounterror }] = useMutation(Delete_Account, {
        variables: { accountId },
        onCompleted: (response) => {
            if (response.deleteAccount.success) {
                message.success("Account deleted successfully!");
                setShowDeleteAccountModal(false);
                navigate("/dashboard");
                // Optionally, refresh data or redirect
            } else {
                message.error(response.deleteAccount.message);
            }
        },
        onError: (err) => {
            message.error("Failed to delete account: " + err.message);
        },
    });

    const handleDelete = () => {
        deleteAccount();
    };

    const [deleteJobRole, { deleteJobRoleloading, deleteJobRoleerror, refetch: refetchDeleteRoles }] = useMutation(Delete_Job_Role, {
        variables: {
            jobroleId: selectedRoleId
        },
        onCompleted: async (response) => {
            if (response.deleteJobrole.success) {
                message.success("Role deleted successfully!");
                setShowDeleteJobRoleModal(false);
                await refetchRoles();
            } else {
                message.error(response.deleteJobrole.message);
            }
        },
        onError: (err) => {
            message.error("Failed to delete role: " + err.message);
        },
    });

    const handleJobRoleDelete = () => {
        if (loading) return;
        deleteJobRole();
    };

    const handleDeleteClick = (roleId) => {
        setSelectedRoleId(parseInt(roleId)); //
        setShowDeleteJobRoleModal(true);
    };

    return (
        <Content style={{ margin: "16px", marginTop: "0px" }}>
            <div className="create-client-container">
                <style>{customStyles}</style>
                <div className="createClientHeading">
                    {accountId ? "Edit Client" : "Create Client"}
                </div>

                <div className="createClientHeading2"><span style={{ color: "#C12328" }}>*</span>Each Field Is Mandatory.</div>
                <Form.Item name="clientImage">
                    <div className="upload-container">
                        <Upload
                            name="clientImage"
                            showUploadList={false}
                            customRequest={handleImageChange}
                            accept="image/*"
                            beforeUpload={(file) => {
                                if (isPickingColor) {
                                    return false;
                                }
                                return true;
                            }}
                        >
                            <div className={`image-upload-circle  ${isPickingColor ? 'picker-active' : ''}`}
                                onClick={(e) => isPickingColor && e.stopPropagation()}>
                                {clientImage ? (
                                    <img
                                        ref={imageRef}
                                        src={clientImage}
                                        alt="Uploaded"
                                        className={`uploaded-image ${isPickingColor ? 'color-picking-cursor' : ''}`}
                                        onClick={handleImageClick}
                                        onMouseMove={handleMouseMove}
                                        onLoad={initializeCanvas}
                                    />
                                ) : formData.imageUrl ? (
                                    <img
                                        ref={imageRef}
                                        src={formData.imageUrl}
                                        alt="Existing Client"
                                        className={`uploaded-image ${isPickingColor ? 'color-picking-cursor' : ''}`}
                                        onClick={handleImageClick}
                                        onLoad={initializeCanvas}

                                    />
                                ) : formData.mainLogoUrl ? (
                                    <img
                                        ref={imageRef}
                                        src={formData.mainLogoUrl}
                                        alt="Existing Logo"
                                        className={`uploaded-image ${isPickingColor ? 'color-picking-cursor' : ''}`}
                                        onClick={handleImageClick}
                                        onLoad={initializeCanvas}

                                    />
                                ) : (
                                    <img
                                        src={CreateClientImage}
                                        alt="Upload Icon"
                                        className="upload-icon"
                                    />
                                )}
                            </div>


                            {isPickingColor && selectedColor && (
                                <>
                                    <div
                                        className="absolute left-0 right-0 top-1/2 border-t border-dashed border-gray-400 pointer-events-none"
                                        style={{ transform: `translateY(${position.y}px)` }}
                                    />
                                    <div
                                        className="absolute top-0 bottom-0 left-1/2 border-l border-dashed border-gray-400 pointer-events-none"
                                        style={{ transform: `translateX(${position.x}px)` }}
                                    />

                                    <div
                                        // className="absolute pointer-events-none bg-white border border-gray-300 shadow-lg p-2 rounded"
                                        style={{
                                            left: `${position.x + 20}px`,
                                            top: `${position.y + 20}px`,
                                        }}
                                    >
                                        <div style={{}}>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Upload>

                        {/* <p className="supported-file-text">
                            File supported: <br /> jpg, jpeg or png
                        </p> */}

                        {touched.clientImage && errors.clientImage && (
                            <div className="createClientErrors">{errors.clientImage}</div>
                        )}

                        {isPickingColor && (
                            <div
                                className="fixed pointer-events-none"
                                style={{
                                    left: '0px',
                                    top: '0px',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1000
                                }}
                                ref={el => {
                                    if (el) {
                                        document.addEventListener('mousemove', (e) => {
                                            el.style.left = `${e.pageX}px`;
                                            el.style.top = `${e.pageY}px`;
                                        });
                                    }
                                }}
                            >
                            </div>
                        )}

                        {(clientImage || formData.imageUrl || formData.mainLogoUrl) && (
                            <button
                                style={{
                                    background: "none",
                                    width: "9%",
                                    height: "0%",
                                    border: "none",
                                    marginTop: "1%"
                                }}
                                type="button"
                                onClick={startColorPicking}
                                className={`${isPickingColor ? '' : 'text-gray-500'}`}
                                title={isPickingColor ? "Color picker active" : "Pick color from image"}

                            >
                                <Pencil style={{ width: "30%" }} />
                                {isPickingColor && selectedColor && (
                                    <div>{selectedColor.hex.toUpperCase()}</div>
                                )}

                                {isPickingColor && selectedColor && (
                                    <div
                                        className="color-preview"
                                        style={{
                                            backgroundColor: selectedColor.rgb,
                                            border: '1px solid #ddd',
                                            width: '40px',
                                            height: '40px',
                                            marginTop: '10px',
                                            borderRadius: "80px",
                                            marginLeft: "25%"
                                        }}
                                    />
                                )}
                            </button>
                        )}
                    </div>
                </Form.Item>

                <Row className="row-container">

                    <Col sm={12} xs={12} md={20} lg={20} style={{ marginBottom: "1%" }}>
                        <div className="clientNameHeading">Client's Profile</div>
                    </Col>

                    <Col sm={12} xs={23} md={11} lg={11} style={{ marginBottom: "2%", marginRight: "3.6%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Client's Name</label>

                        <TextInputField
                            name="name"
                            value={formData.name}
                            placeholder="Enter Text Value"
                            feedbackMessage="Please input the client name."
                            validationRegex={validationRegexOnlyAlpha}
                            onChange={handleInputChange}
                        />
                        {touched.name && errors.name && (
                            <div className="createClientErrors">{errors.name}</div>
                        )}
                    </Col>

                    <Col sm={12} xs={23} md={10} lg={11} style={{ marginBottom: "2%", marginRight: "0.5%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Client's URL</label>

                        <TextInputField
                            name="companyURL"
                            value={formData.companyURL}
                            placeholder="Enter Text Value"
                            feedbackMessage="Please input the company URL."
                            onChange={handleInputChange}
                        />
                        {touched.companyURL && errors.companyURL && (
                            <div className="createClientErrors">{errors.companyURL}</div>
                        )}

                    </Col>

                    <Col sm={12} xs={23} md={11} lg={11} style={{ marginBottom: "2%", marginRight: "3.6%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Office Address</label>

                        <TextInputField
                            name="address"
                            value={formData.address}
                            placeholder="Enter Text Value"
                            feedbackMessage="Please input the address."
                            validationRegex={validationRegexAddress}
                            onChange={handleInputChange}
                        />

                        {touched.address && errors.address && (
                            <div className="createClientErrors">{errors.address}</div>
                        )}

                    </Col>

                    <Col sm={12} xs={23} md={10} lg={11} style={{ marginBottom: "2%", marginRight: "0.5%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Office Address 2</label>

                        <TextInputField
                            name="address2"
                            value={formData.address2}
                            placeholder="Enter Text Value"
                            feedbackMessage="Please input the address 2."
                            validationRegex={validationRegexAddress}
                            onChange={handleInputChange}
                        />
                        {touched.address2 && errors.address2 && (
                            <div className="createClientErrors">{errors.address2}</div>
                        )}
                    </Col>

                    <Col sm={12} xs={22} md={11} lg={11} className="brandColorInput">
                        <label className="brand-color-label">Brand Color</label>
                        <div className="brand-color-container">
                            <TextInputField
                                type="text"
                                value={normalizeColor(formData.brandColor)}
                                // value={
                                //     (typeof formData.brandColor === "string" &&
                                //         formData.brandColor.startsWith("#"))
                                //         ? formData.brandColor.toUpperCase()
                                //         : `#${(formData.brandColor || '').toUpperCase()}`
                                // }
                                placeholder="Brand Color"
                                onClick={handleColorClick}
                                // readOnly
                                className="brandcolorinputfield"
                            />

                            <span
                                className="color-display-circle"
                                style={{
                                    backgroundColor: normalizeColor(formData.brandColor)

                                }}
                                onClick={handleColorClick}

                            ></span>

                            {showPicker && (
                                <div className="color-picker-container">
                                    <ChromePicker
                                        className="custom-chrome-picker"
                                        color={normalizeColor(formData.brandColor)}
                                        onChange={handleColorChange}
                                    />
                                    <button
                                        onClick={() => setShowPicker(false)}
                                        className="close-picker-button"
                                    >
                                        Close
                                    </button>
                                </div>
                            )}

                            {showPicker && (
                                <div
                                    onClick={() => setShowPicker(false)}
                                    className="overlay"
                                ></div>
                            )}
                        </div>
                    </Col>
                </Row>
                {!accountId && (
                    <div className="row-container">
                        <Row>
                            <Col sm={12} xs={12} md={12} lg={6} style={{ marginBottom: "1%", marginRight: "-5%" }}>
                                <div className="clientNameHeading">Role</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={23} md={10} lg={11} style={{ marginBottom: "2%", marginRight: "3.6%" }}>
                                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Name</label>

                                <TextInputField
                                    name="roleName"
                                    value={formData.roleName}
                                    placeholder="Enter Text Value"
                                    feedbackMessage="Please input the role name."
                                    validationRegex={validationRegexOnlyAlpha}
                                    onChange={handleInputChange}
                                />
                                {touched.roleName && errors.roleName && (
                                    <div className="createClientErrors">{errors.roleName}</div>
                                )}
                            </Col>
                            <Col sm={12} xs={23} md={10} lg={11} style={{ marginBottom: "2%", marginRight: "0.5%" }}>
                                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>POC Name</label>

                                <TextInputField
                                    name="pocName"
                                    value={formData.pocName}
                                    placeholder="Enter Text Value"
                                    feedbackMessage="Please input the POC name."
                                    validationRegex={validationRegexOnlyAlpha}
                                    onChange={handleInputChange}
                                />
                                {touched.pocName && errors.pocName && (
                                    <div className="createClientErrors">{errors.pocName}</div>
                                )}
                            </Col>
                            <Col sm={12} xs={23} md={10} lg={11} style={{ marginBottom: "2%", marginRight: "3.6%" }}>
                                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Contact Number</label>

                                <TextInputField
                                    name="pocNumber"
                                    value={formData.pocNumber}
                                    placeholder="Enter Text Value"
                                    feedbackMessage="Please input the contact number."
                                    validationRegex={validationRegexAmount}
                                    onChange={handleInputChange}
                                />
                                {touched.pocNumber && errors.pocNumber && (
                                    <div className="createClientErrors">{errors.pocNumber}</div>
                                )}
                            </Col>
                            <Col sm={12} xs={23} md={10} lg={11} style={{ marginBottom: "2%", marginRight: "0.5%" }}>
                                <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Email ID</label>

                                <TextInputField
                                    name="pocEmail"
                                    value={formData.pocEmail}
                                    placeholder="Enter Text Value"
                                    feedbackMessage="Please input the email Id."
                                    // validationRegex={emailRegex}
                                    onChange={handleInputChange}
                                />
                                {touched.pocEmail && errors.pocEmail && (
                                    <div className="createClientErrors">{errors.pocEmail}</div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={12} md={12} lg={6} style={{ marginBottom: "1%", marginRight: "-5%" }}>
                                <div className="productAccessHeading">Product Access</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={10} md={6} lg={6} style={{ marginRight: "-3%", }}>
                                <Form.Item>
                                    <Checkbox
                                        checked={formData.lense}
                                        onChange={handleCheckboxChange}
                                        className="custom-checkbox"
                                        name="lense"
                                    >
                                        Lense
                                    </Checkbox>
                                </Form.Item>
                            </Col>

                            <Col sm={12} xs={10} md={6} lg={6} style={{ marginLeft: "-13%" }}>
                                <Form.Item>
                                    <Checkbox
                                        checked={formData.darts}
                                        name="darts"
                                        onChange={handleCheckboxChange}
                                        style={{ color: formData.emailLogin ? 'black' : '' }}
                                    >
                                        Darts
                                    </Checkbox>
                                </Form.Item>
                            </Col>

                            <Col sm={12} xs={10} md={6} lg={12} style={{ marginLeft: "-16%" }}>
                                <div>
                                    <Form.Item>
                                        <Checkbox
                                            checked={formData.clan}
                                            onChange={handleCheckboxChange}
                                            className="custom-checkbox"
                                            name="clan"
                                        >
                                            CLAN
                                        </Checkbox>
                                    </Form.Item>

                                </div>
                            </Col>

                        </Row>

                        {touched.projectAccess && errors.projectAccess && (
                            <div className="createClientErrors">{errors.projectAccess}</div>
                        )}

                        {(formData.darts || formData.clan) && (
                            <Row>
                                {/* <Col sm={12} xs={12} md={6} lg={6} style={{ marginBottom: "-2%", marginLeft: "0%", display: "flex", alignItems: "center" }}> */}
                                <label style={{ fontSize: "15px", color: "#1C1B1F", marginRight: "10px", marginTop: "0.4%" }}>
                                    Login Through:
                                </label>
                                <Form.Item
                                >
                                    <Radio.Group
                                        onChange={handleLoginMethodChange}
                                        value={formData.numberLogin ? "mobile" : formData.emailLogin ? "email" : ""}
                                    >
                                        <Radio value="mobile" className="custom-radio">Mobile</Radio>
                                        <Radio value="email" className="custom-radio">Email ID</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {/* </Col> */}
                            </Row>
                        )}
                        {touched.loginMethod && errors.loginMethod && (
                            <div className="createClientErrors">{errors.loginMethod}</div>
                        )}
                    </div>
                )}

                {accountId && (
                    <div className="row-container">
                        <div className="clientNameHeading">Role Management</div>
                        <div className="role-cards-container">
                            {combinedRoles.map((roleItem, index) => (
                                roleItem?.name && (
                                    <div key={`role-${index}`} className="role-card">
                                        <div className="role-header">
                                            <div >
                                                <span style={{ display: "flex", justifyContent: "space-between" }} onClick={() => handleDeleteClick(roleItem.id)}>
                                                    <div className="role-label">Role</div>
                                                    <img className="rolemanagementdeletebutton " src={DeleteButton} />
                                                </span>
                                                <div className="role-title">{roleItem.name}</div>
                                                <button className="rolemanagementeditandimage" onClick={() => handleEdit(roleItem)}>
                                                    <img className="rolemanagementeditbutton " src={roleManagementEditIcon} />
                                                    <p className="rolemanagementeditname" >Edit</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}

                            <img
                                src={AddRoleIcon}
                                alt="Uploaded"
                                className="AddRoleImage"
                                onClick={showModal}
                            />
                        </div>

                        {showdeleteJobRoleModal && selectedRoleId && (
                            <Modal
                                // title={selectedUser.userStatus === 1 ? "Confirm Deletion" : "Confirm Revocation"}
                                open={showdeleteJobRoleModal}
                                onCancel={() => {
                                    setShowDeleteJobRoleModal(false)
                                    setSelectedRoleId(null);
                                }}

                                footer={null}
                                centered
                                width={500}
                                closable={false}
                            >
                                <p>
                                    Do you want to delete this
                                    <span style={{ fontWeight: "bold" }}> Role?</span>
                                </p>
                                <div className="delete-modal-footer">
                                    <button
                                        className="delete-account"
                                        onClick={handleJobRoleDelete}
                                    >
                                        {deleteJobRoleloading ? 'Deleting...' : 'Delete'}
                                    </button>
                                    <button
                                        className="delete-cancel"
                                        onClick={() => setShowDeleteJobRoleModal(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Modal>
                        )}

                        <Modal
                            width={400}
                            // style={{ marginTop: "-8%" }}
                            visible={isModalVisible}
                            onCancel={handleCancel}
                            footer={[
                                <div key="footer" className="role-management-modal-footer">
                                    <button key="cancel" onClick={handleCancel} className="cancel-button">
                                        Cancel
                                    </button>
                                    <button key="create" onClick={addJobRoleHandleSubmit} className="create-button">
                                        {isEditMode ? (addRoleLoading ? "Updating..." : "Update") : (addRoleLoading ? "Creating..." : "Create")}
                                    </button>
                                </div>
                            ]}
                        >
                            <Form form={form} layout="vertical">

                                <Row>

                                    <Col sm={12} xs={10} md={11} lg={10} style={{ marginBottom: "2%", marginRight: "3.6%" }}>
                                        <Form.Item
                                            label="Role Name"
                                            name="role name"
                                            rules={[
                                                { required: true, message: 'Enter a role name!' },
                                                { pattern: validationRegexOnlyAlphaAddRole, message: 'Enter valid role name!' },
                                            ]}
                                        >
                                            <Input placeholder="Enter Role Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col sm={12} xs={10} md={11} lg={10} style={{ marginBottom: "2%" }}>
                                        <Form.Item
                                            label="POC Name"
                                            name="POC name"
                                            rules={[
                                                { required: true, message: 'Enter a POC Name!' },
                                                { pattern: validationRegexOnlyAlphaAddRole, message: 'Enter valid POC name' },
                                            ]}
                                        >
                                            <Input placeholder="Enter POC Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col sm={12} xs={10} md={11} lg={10} style={{ marginBottom: "2%", marginRight: "3.6%" }}>

                                        <Form.Item
                                            label="Contact No."
                                            name="contact no"
                                            rules={[
                                                { required: true, message: 'Enter a Contact Number!' },
                                                { pattern: validationRegexMobileNoAddRole, message: 'Enter valid mobile number!' },
                                            ]}
                                        >
                                            <Input placeholder="Enter Contact Number" />
                                        </Form.Item>
                                    </Col>
                                    <Col sm={12} xs={10} md={11} lg={10} style={{ marginBottom: "2%" }}>
                                        <Form.Item
                                            label="Email-ID"
                                            name="email"
                                            rules={[
                                                { required: true, message: 'Enter an Email-ID!' },
                                                { pattern: emailRegexAddRole, message: 'Enter valid Email-ID!' },
                                            ]}
                                        >
                                            <Input placeholder="Enter Email-ID" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div style={{ fontSize: "17px", fontWeight: "bold", marginTop: "6%" }}>Product&nbsp;Access</div>

                                <Form.Item
                                    name="productAccess"
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (!formData.lense && !formData.darts && !formData.clan) {
                                                    return Promise.reject('Please select at least one product access!');
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <Row>
                                        <Col sm={12} xs={10} md={11} lg={6}>
                                            <Checkbox
                                                checked={formData.lense}
                                                onChange={() => roleManagementHandleCheckboxChange('lense')}
                                                className="custom-checkbox"
                                                name="lense"
                                            >
                                                Lense
                                            </Checkbox>
                                        </Col>
                                        <Col sm={12} xs={10} md={11} lg={6}>

                                            <Checkbox
                                                checked={formData.darts}
                                                onChange={() => roleManagementHandleCheckboxChange('darts')}
                                                className="custom-checkbox"
                                                name="darts"
                                            >
                                                Darts
                                            </Checkbox>
                                        </Col>
                                        <Col sm={12} xs={10} md={11} lg={11}>
                                            <Checkbox
                                                checked={formData.clan}
                                                onChange={() => roleManagementHandleCheckboxChange('clan')}
                                                className="custom-checkbox"
                                                name="clan"
                                            >
                                                CLAN
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Form.Item>

                                {/* <Form.ErrorList className="createClientErrors" errors={form.getFieldError('productAccess')} /> */}

                                {(formData.darts || formData.clan) && (
                                    <Row>
                                        <label style={{ fontSize: "15px", color: "#1C1B1F", marginRight: "10px", marginTop: "0.4%" }}>
                                            Login Through:
                                        </label>
                                        <Form.Item
                                            name="loginMethod"
                                            rules={[{ required: true, message: 'Please select a login method!' }]}
                                        >
                                            <Radio.Group
                                                onChange={handleLoginMethodChange}
                                                value={formData.numberLogin ? "mobile" : formData.emailLogin ? "email" : ""}
                                            >
                                                <Radio value="mobile" className="custom-radio">Mobile</Radio>
                                                <Radio value="email" className="custom-radio">Email ID</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Row>
                                )}

                                {touched.loginMethod && errors.loginMethod && (
                                    <div className="createClientErrors">{errors.loginMethod}</div>
                                )}

                            </Form>
                        </Modal>
                    </div>
                )}

                <div className="self-registration-container">
                    <span className="self-registration-label">Self Registration</span>
                    <label className="toggle-switch">
                        <input
                            type="checkbox"
                            checked={isToggled}
                            onChange={handleToggle}
                        />
                        <span className="self-registration-slider"></span>
                    </label>
                </div>

                {accountId && (
                    <>
                        <div
                            className="row-container"
                            style={{
                                marginTop: "20px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div style={{ fontWeight: "600" }}>Delete Client Data</div>
                            <button className="deleteButton" onClick={() => setShowDeleteAccountModal(true)}>
                                <img src={DeleteButton} alt="Delete Icon" className="deleteIcon" />
                                Delete
                            </button>
                        </div>
                    </>
                )}
                {showdeleteAccountModal && (
                    <Modal
                        // title={selectedUser.userStatus === 1 ? "Confirm Deletion" : "Confirm Revocation"}
                        open={showdeleteAccountModal}
                        onCancel={() => setShowDeleteAccountModal(false)}
                        footer={null}
                        centered
                        width={500}
                        closable={false}
                    >
                        <p>
                            Do you want to delete this
                            <span style={{ fontWeight: "bold" }}> Account?</span>
                        </p>
                        <div className="delete-modal-footer">
                            <button
                                className="delete-account"
                                onClick={handleDelete}
                            >
                                Delete
                            </button>
                            <button
                                className="delete-cancel"
                                onClick={() => setShowDeleteAccountModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </Modal>
                )}
                {accountId ? (
                    <div className="accountId-create-page-buttons">
                        <button className="discard-button" onClick={handleDiscardClick}>
                            <img src={Lessthan} alt="Less Than" className="lessthan" />
                            Back
                        </button>
                        <button className="delete-button" onClick={onFinish}>
                            <img src={CreateIconPlus} alt="Less Than" className="CreateIcon" />
                            Save
                        </button>
                    </div>
                ) : null
                }

                {!accountId ? (
                    <div className="create-page-buttons">

                        <button className="discard-button" onClick={handleDiscardClick}>
                            <img src={Lessthan} alt="Less Than" className="lessthan" />
                            Discard
                        </button>
                        <button className="delete-button" onClick={handleSubmit}>
                            <img src={CreateIconPlus} alt="Less Than" className="CreateIcon" />
                            Create
                        </button>
                    </div>
                ) : null
                }

            </div >
        </Content >
    );
};

export default CreateClient;