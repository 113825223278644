import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Table, Button, Input, Popconfirm, Card, Modal, message } from "antd";
import { EditOutlined, DeleteOutlined, CheckOutlined, SearchOutlined } from "@ant-design/icons";
import { List_Gender_From_Role, Add_Update_Gender_Master } from "../services/userAPI"; // Adjust path
import { useLocation } from "react-router-dom";
import DeleteIcon from "../Images/user-management-delete-icon.png";

const GenderConfiguration = () => {
    const [searchTerm, setSearchTerm] = useState(""); // For search
    const [currentPage, setCurrentPage] = useState(1); // Pagination state
    const [pageSize, setPageSize] = useState(5); // Set page size to 5
    const [editingKey, setEditingKey] = useState(null); // For handling row edit
    const [editedName, setEditedName] = useState(""); // For storing edited name
    const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility
    const [newGenderName, setNewGenderName] = useState("");
    const [newGenderCode, setNewGenderCode] = useState(""); // For storing gender code input
    const nameInputRef = useRef(null); // Separate ref for gender name input
    const codeInputRef = useRef(null);
    const location = useLocation();
    const roleId = location.state?.roleId; // Role ID from location

    // Fetch the gender data using the GraphQL query
    const { loading, error, data, refetch } = useQuery(List_Gender_From_Role, {
        variables: { jobroleId: roleId },
    });

    // State for storing the fetched data
    const [fetchedData, setFetchedData] = useState([]);

    // Store the fetched gender data in state
    useEffect(() => {
        if (data) {
            setFetchedData(data.listGenderFromRole || []);
        }
    }, [data]);

    // Filter data based on the search term
    const filteredData = fetchedData.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Paginate the filtered data
    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page when search term changes
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prev) => prev - 1);
    };

    const handleEdit = (record) => {
        setEditingKey(record.key);
        setEditedName(record.name); // Set the name of the gender to edit
    };

    const handleSave = (key) => {
        const updatedData = paginatedData.map((item) =>
            item.key === key ? { ...item, name: editedName } : item
        );
        setEditingKey(null); // Reset the editing state
    };

    const showModal = () => {
        setIsModalVisible(true); // Show the modal
    };

    const handleCancel = () => {
        setIsModalVisible(false); // Close the modal
        setNewGenderName(""); // Reset the input field
        setNewGenderCode(""); // Reset the gender code input
    };

    const [addUpdateGender, { loading: addLoading }] = useMutation(Add_Update_Gender_Master);

    const handleAddGender = async () => {
        if (!newGenderName.trim() || !newGenderCode.trim()) {
            message.error("Please enter both gender name and code.");
            return;
        }

        try {
            const response = await addUpdateGender({
                variables: {
                    genderName: newGenderName,
                    genderCode: newGenderCode,
                    jobRoleId: roleId,
                    id: null,
                    // Send the roleId dynamically
                },
            });

            if (response.data.addUpdateGenderMaster.success) {
                message.success("Gender added successfully!");
                setIsModalVisible(false);
                setNewGenderName("");
                setNewGenderCode("");
                refetch(); // Refresh the data
            } else {
                message.error("Error: " + response.data.addUpdateGenderMaster.message);
            }
        } catch (error) {
            console.error("Error adding/updating gender:", error);
            message.error("Failed to add/update gender.");
        }
    };

    const handleDelete = async (id, name, code, roleId) => {
        try {
            const response = await addUpdateGender({
                variables: {
                    genderId: id,
                    genderName: name,
                    genderCode: code,
                    jobRoleId: roleId,
                    status: 0, // Soft delete by setting status to 0
                },
            });

            if (response.data.addUpdateGenderMaster.success) {
                message.success("Gender deleted successfully!");
                refetch(); // Refresh the data
            } else {
                message.error(response.data.addUpdateGenderMaster.message);
            }
        } catch (error) {
            console.error("Error deleting gender:", error);
            message.error("Failed to delete gender.");
        }
    };


    const columns = [
        {
            title: "Gender",
            dataIndex: "name",
            key: "gender",
            render: (text, record) => (
                editingKey === record.key ? (
                    <Input
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        onPressEnter={() => handleSave(record.key)}
                    />
                ) : (
                    text
                )
            ),
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    {editingKey === record.key ? (
                        <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={() => handleSave(record.key)}
                            style={{ marginRight: 8 }}
                        />
                    ) : (
                        <>
                            <Popconfirm
                                title="Are you sure you want to delete this?"
                                onConfirm={() => handleDelete(record.id, record.name, record.code, record.roleId)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <img src={DeleteIcon} alt="Delete" style={{ cursor: "pointer" }} />

                            </Popconfirm>
                        </>
                    )}
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (isModalVisible && nameInputRef.current) {
            setTimeout(() => {
                nameInputRef.current.focus(); // Focus only on the name input
            }, 100);
        }
    }, [isModalVisible]);

    return (
        <div>
            <Card className="search-card">
                <label style={{ fontFamily: "Inter", fontSize: "20px" }}>Gender</label>

                <div className="search-container">
                    <div className="search-group">
                        <label>Search</label>
                        <div className="search-input-wrapper">
                            <Input
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search gender"
                                suffix={<SearchOutlined />}
                            />
                        </div>
                    </div>

                    <div className="button-group" style={{ marginTop: "20px" }}>
                        <button className="add-user-btn" onClick={showModal}>
                            Add Gender
                        </button>
                    </div>
                </div>
                <br />
                <Table
                    columns={columns}
                    dataSource={paginatedData}
                    pagination={false}
                    loading={loading}
                />

                <div className="pagination-controls">
                    <span className="pagination-text">{`${(currentPage - 1) * pageSize + 1} - ${Math.min(currentPage * pageSize, filteredData.length)} of ${filteredData.length}`}</span>
                    <button
                        className="pagination-button"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        &lt;
                    </button>
                    <button
                        className="pagination-button"
                        onClick={handleNextPage}
                        disabled={currentPage * pageSize >= filteredData.length}
                    >
                        &gt;
                    </button>
                </div>
            </Card>

            <Modal
                title="Add New Gender"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <div>
                    <Input
                        ref={nameInputRef}
                        value={newGenderName}
                        onChange={(e) => setNewGenderName(e.target.value)}
                        placeholder="Enter gender name"
                        autoFocus={true}
                    />
                    <Input
                       ref={codeInputRef}
                        value={newGenderCode}
                        onChange={(e) => setNewGenderCode(e.target.value)}
                        placeholder="Enter gender code"
                        style={{ marginTop: 10 }}
                        autoFocus={true}

                    />
                    <div style={{ marginTop: "20px", textAlign: "right" }}>
                        <button className="masters-configuration-cancel-button" onClick={handleCancel} style={{ marginRight: 8 }}>
                            Cancel
                        </button>
                        <button
                            className="masters-configuration-add-button"
                            type="primary"
                            onClick={handleAddGender}
                            loading={addLoading}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default GenderConfiguration;
