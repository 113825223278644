import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import React, { useState } from "react";
import moment from "moment";
import "./calendarView.css";

const CalendarView = () => {
    // const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);
    const [currentDate, setCurrentDate] = useState(moment());
    // Dummy status data (Replace with API response if needed)


    const daysInMonth = Array.from(
        { length: currentDate.daysInMonth() },
        (_, i) => i + 1
    );
    const statusData = {
        signedInRecorded: 0,
        notSignedIn: 29,
        signedInNotRecorded: 2,
        notSignedInRecorded: 0,
        onLeave: 0,
    };

    const changeMonth = (direction) => {
        if (direction === "next") {
            setCurrentDate(currentDate.clone().add(1, "month"));
        } else if (direction === "prev") {
            setCurrentDate(currentDate.clone().subtract(1, "month"));
        }
    };

    return (
        <div className="calendar-container">
            {/* Header */}
            <h2 className="calendar-header">My Reports</h2>
            <div className="calendar-buttons">
                <button className="compliance-report">Compliance Report</button>
                <button className="performance-report">Performance Reports</button>
            </div>

            {/* Month Selector */}
            <div className="month-selector">
                <FaChevronLeft
                    className="arrow-icon"
                    onClick={() => changeMonth("prev")}
                />
                <h3 className="month-name">
                    {currentDate.format("MMMM YYYY")}
                </h3>
                <FaChevronRight
                    className="arrow-icon"
                    onClick={() => changeMonth("next")}
                />
            </div>

            {/* Calendar Grid */}
            <div className="calendar-grid">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
                    <span key={day} className="weekday">{day}</span>
                ))}
                {daysInMonth.map((day) => (
                    <div
                        key={day}
                        className="day-item"
                    >
                        {day < 10 ? `0${day}` : day}
                    </div>
                ))}
            </div>

            {/* Legend */}
            <div className="calendar-legend">
                <div className="legend-item">
                    <span className="status-dot green"></span>
                    Signed in & Recorded ({statusData.signedInRecorded}/31)
                </div>
                <div className="legend-item">
                    <span className="status-dot red"></span>
                    Not Signed In ({statusData.notSignedIn}/31)
                </div>
                <div className="legend-item">
                    <span className="status-dot orange"></span>
                    Signed In & Not Recorded ({statusData.signedInNotRecorded}/31)
                </div>
                <div className="legend-item">
                    <span className="status-dot blue"></span>
                    Not Signed In & Recorded ({statusData.notSignedInRecorded}/31)
                </div>
                <div className="legend-item">
                    <span className="status-dot yellow"></span>
                    On Leave ({statusData.onLeave}/31)
                </div>
            </div>

            {/* View Work Logs Button */}
            <button className="work-logs-button">View Work Logs</button>
        </div>
    );
};

export default CalendarView;
